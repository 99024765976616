import React from "react";
import Footer from "../../footer/footer";
import Header from "../../header/header";
import { Link } from "react-router-dom";
function index() {
  return (
    <div>
      <>
        <Header />
        <div id="wrapper-container" className="wrapper-container">
          <div className="content-pusher">
            <div id="main-content">
              <section className="content-area">
                <div className="top_heading_out">
                  <div
                    className="top_site_main"
                    style={{
                      backgroundImage: `url('../../../wp-content/uploads/2015/11/team-5.jpg')`,
                    }}
                  >
                    <span
                      className="overlay-top-header"
                      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
                    />{" "}
                    <div className="page-title-wrapper">
                      <div className="banner-wrapper container">
                        <h2 className="page-title">Events</h2>{" "}
                      </div>
                    </div>
                  </div>{" "}
                  <div className="breadcrumbs-wrapper">
                    <div className="container">
                      <ul className="breadcrumbs" id="breadcrumbs">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="/event">Event</Link>
                        </li>
                        <li>
                          Grand inauguration of DAV Sanford Public School in
                          Tati jharia
                        </li>
                      </ul>{" "}
                    </div>
                  </div>
                </div>
                <div className="container sidebar-right site-content">
                  <div className="row">
                    <main id="main" className="site-main col-sm-9 alignleft">
                      <article
                        id="tp_event-2953"
                        className="tp_single_event post-2953 tp_event type-tp_event status-publish has-post-thumbnail hentry"
                      >
                        <div className="summary entry-summary">
                          <div className="entry-title">
                            <h1>
                              Grand inauguration of DAV Sanford Public School in
                              Tati jharia{" "}
                            </h1>
                          </div>
                          <div className="tp-event-top">
                            <div className="entry-thumbnail">
                              <img
                                width={2560}
                                height={1709}
                                src="../../wp-content/uploads/2023/05/DSC_6432-scaled.jpg"
                                className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                alt=""
                                decoding="async"
                                fetchpriority="high"
                                srcSet="https://davsanford.com/wp-content/uploads/2023/05/DSC_6432-scaled.jpg 2560w, https://davsanford.com/wp-content/uploads/2023/05/DSC_6432-scaled-300x200.jpg 300w, https://davsanford.com/wp-content/uploads/2023/05/DSC_6432-scaled-1024x684.jpg 1024w, https://davsanford.com/wp-content/uploads/2023/05/DSC_6432-768x513.jpg 768w, https://davsanford.com/wp-content/uploads/2023/05/DSC_6432-1536x1025.jpg 1536w, https://davsanford.com/wp-content/uploads/2023/05/DSC_6432-2048x1367.jpg 2048w, https://davsanford.com/wp-content/uploads/2023/05/DSC_6432-600x401.jpg 600w"
                                sizes="(max-width: 2560px) 100vw, 2560px"
                              />{" "}
                            </div>
                            <div className="entry-countdown">
                              <p className="tp-event-notice error">
                                This event has expired
                              </p>
                            </div>
                          </div>{" "}
                          <div className="tp-event-content">
                            <div className="entry-content">
                              <h1>Inauguration Day</h1>
                              <div className="x78zum5 xdt5ytf xz62fqu x16ldp7u">
                                <div className="xu06os2 x1ok221b">
                                  <div className="xdj266r x11i5rnm xat24cr x1mh8g0r x1vvkbs x126k92a">
                                    <div dir="auto">
                                      NH-522 Tatizaria Chowk DAV Sanford Public
                                      School inaugurated by Mandu MLA’s wife
                                      Lalita Devi, Chief Santosh Mandal, MP
                                      Representative Kailaspati Singh, MLA
                                      Representative Upendra Pandey, Surendra
                                      Prasad Yadav, Chief Suresh Yadav, Kanti
                                      Devi and others jointly cut the lace on
                                      Friday. School founder director Deepak
                                      Kumar honored the guests with a bouquet
                                      and a shawl. On this occasion, Lalita Devi
                                      said that opening of schools equipped with
                                      resources in the area will lead to overall
                                      development of children here. School
                                      director Deepak Kumar said that DAV will
                                      work to enlighten the diverse talents of
                                      children in the Sanford Public School
                                      area. Apart from education, children will
                                      be trained in horse riding, swimming,
                                      spoken, computer, laboratory, cricket,
                                      football, and other sports.
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="x78zum5 xdt5ytf xz62fqu x16ldp7u">
                                <div>
                                  <p>
                                    <img
                                      decoding="async"
                                      className="wp-image-11109 alignleft"
                                      src="../../../davsanford.cdn-pi.com/wp-content/uploads/2015/11/WhatsApp-Image-2023-05-13-at-8.11.40-PM-300x200.jpg"
                                      alt=""
                                      width={234}
                                      height={156}
                                      srcSet="https://davsanford.com/wp-content/uploads/2015/11/WhatsApp-Image-2023-05-13-at-8.11.40-PM-300x200.jpeg 300w, https://davsanford.com/wp-content/uploads/2015/11/WhatsApp-Image-2023-05-13-at-8.11.40-PM-1024x684.jpeg 1024w, https://davsanford.com/wp-content/uploads/2015/11/WhatsApp-Image-2023-05-13-at-8.11.40-PM-768x513.jpeg 768w, https://davsanford.com/wp-content/uploads/2015/11/WhatsApp-Image-2023-05-13-at-8.11.40-PM-1536x1025.jpeg 1536w, https://davsanford.com/wp-content/uploads/2015/11/WhatsApp-Image-2023-05-13-at-8.11.40-PM-600x401.jpeg 600w, https://davsanford.com/wp-content/uploads/2015/11/WhatsApp-Image-2023-05-13-at-8.11.40-PM.jpeg 1600w"
                                      sizes="(max-width: 234px) 100vw, 234px"
                                    />
                                  </p>
                                  <div className="x78zum5 xdt5ytf xz62fqu x16ldp7u">
                                    <div className="xu06os2 x1ok221b">
                                      <p>
                                        <img
                                          decoding="async"
                                          className="alignleft wp-image-11094"
                                          src="../../../davsanford.cdn-pi.com/wp-content/uploads/2015/11/WhatsApp-Image-2023-05-13-at-4.38.36-PM1-300x200.jpg"
                                          alt=""
                                          width={234}
                                          height={156}
                                          srcSet="https://davsanford.com/wp-content/uploads/2015/11/WhatsApp-Image-2023-05-13-at-4.38.36-PM1-300x200.jpeg 300w, https://davsanford.com/wp-content/uploads/2015/11/WhatsApp-Image-2023-05-13-at-4.38.36-PM1-1024x684.jpeg 1024w, https://davsanford.com/wp-content/uploads/2015/11/WhatsApp-Image-2023-05-13-at-4.38.36-PM1-768x513.jpeg 768w, https://davsanford.com/wp-content/uploads/2015/11/WhatsApp-Image-2023-05-13-at-4.38.36-PM1-1536x1025.jpeg 1536w, https://davsanford.com/wp-content/uploads/2015/11/WhatsApp-Image-2023-05-13-at-4.38.36-PM1-600x401.jpeg 600w, https://davsanford.com/wp-content/uploads/2015/11/WhatsApp-Image-2023-05-13-at-4.38.36-PM1.jpeg 1600w"
                                          sizes="(max-width: 234px) 100vw, 234px"
                                        />
                                        <img
                                          decoding="async"
                                          loading="lazy"
                                          className="wp-image-11110 aligncenter"
                                          src="../../../davsanford.cdn-pi.com/wp-content/uploads/2015/11/WhatsApp-Image-2023-05-13-at-8.11.43-PM-300x200.jpg"
                                          alt=""
                                          width={239}
                                          height={159}
                                          srcSet="https://davsanford.com/wp-content/uploads/2015/11/WhatsApp-Image-2023-05-13-at-8.11.43-PM-300x200.jpeg 300w, https://davsanford.com/wp-content/uploads/2015/11/WhatsApp-Image-2023-05-13-at-8.11.43-PM-1024x684.jpeg 1024w, https://davsanford.com/wp-content/uploads/2015/11/WhatsApp-Image-2023-05-13-at-8.11.43-PM-768x513.jpeg 768w, https://davsanford.com/wp-content/uploads/2015/11/WhatsApp-Image-2023-05-13-at-8.11.43-PM-1536x1025.jpeg 1536w, https://davsanford.com/wp-content/uploads/2015/11/WhatsApp-Image-2023-05-13-at-8.11.43-PM-600x401.jpeg 600w, https://davsanford.com/wp-content/uploads/2015/11/WhatsApp-Image-2023-05-13-at-8.11.43-PM.jpeg 1600w"
                                          sizes="(max-width: 239px) 100vw, 239px"
                                        />
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div />
                            </div>{" "}
                            <div className="tp-event-info">
                              <div className="tp-info-box">
                                <p className="heading">
                                  <i className="thim-color fa fa-clock-o" />
                                  Start Time{" "}
                                </p>
                                <p>12:00 am</p>
                                <p>May 6, 2023</p>
                              </div>
                              <div className="tp-info-box">
                                <p className="heading">
                                  <i className="thim-color fa fa-flag" />
                                  Finish Time{" "}
                                </p>
                                <p>12:00 am</p>
                                <p>May 6, 2023</p>
                              </div>
                              <div className="tp-info-box">
                                <p className="heading">
                                  <i className="thim-color fa fas fa-map-marker" />
                                  Address{" "}
                                </p>
                                <p>TatiJharia, Hazaribagh</p>
                                <div className="entry-location" />{" "}
                              </div>
                            </div>
                          </div>
                          <div className="tp-event-single-share">
                            <ul className="thim-social-share">
                              <li className="heading">Share:</li>
                              <li>
                                <div className="facebook-social">
                                  <a
                                    target="_blank"
                                    className="facebook"
                                    href="https://www.facebook.com/sharer.php?u=https%3A%2F%2Fdavsanford.com%2Fevents%2Fbuild-education-website-using-wordpress%2F"
                                    title="Facebook"
                                  >
                                    <i className="fa fa-facebook" />
                                  </a>
                                </div>
                              </li>
                              <li>
                                <div className="twitter-social">
                                  <a
                                    target="_blank"
                                    className="twitter"
                                    href="https://twitter.com/share?url=https%3A%2F%2Fdavsanford.com%2Fevents%2Fbuild-education-website-using-wordpress%2F&text=Grand%20inauguration%20of%20DAV%20Sanford%20Public%20School%20in%20Tati%20jharia"
                                    title="Twitter"
                                  >
                                    <i className="fa fa-twitter" />
                                  </a>
                                </div>
                              </li>
                              <li>
                                <div className="pinterest-social">
                                  <a
                                    target="_blank"
                                    className="pinterest"
                                    href="../../../pinterest.com/pin/create/button/index01ab.html?url=https%3A%2F%2Fdavsanford.com%2Fevents%2Fbuild-education-website-using-wordpress%2F&description=A%20golden%20chapter%20in%20the%20field%20of%20education.&media=https%3A%2F%2Fdavsanford.com%2Fwp-content%2Fuploads%2F2023%2F05%2FDSC_6432-scaled.jpg"
                                    onclick="window.open(this.href); return false;"
                                    title="Pinterest"
                                  >
                                    <i className="fa fa-pinterest-p" />
                                  </a>
                                </div>
                              </li>
                            </ul>{" "}
                          </div>
                        </div>
                        {/* .summary */}
                      </article>
                      <div id="comments" className="comments-area">
                        <div className="comment-respond-area">
                          <div id="respond" className="comment-respond">
                            <h3
                              id="reply-title"
                              className="comment-reply-title"
                            >
                              Leave A Reply{" "}
                              <small>
                                <a
                                  rel="nofollow"
                                  id="cancel-comment-reply-link"
                                  href="index.html#respond"
                                  style={{ display: "none" }}
                                >
                                  Cancel reply
                                </a>
                              </small>
                            </h3>
                            <form
                              action="https://davsanford.com/wp-comments-post.php"
                              method="post"
                              id="commentform"
                              className="comment-form"
                              noValidate=""
                            >
                              <p className="comment-notes">
                                <span id="email-notes">
                                  Your email address will not be published.
                                </span>{" "}
                                <span className="required-field-message">
                                  Required fields are marked{" "}
                                  <span className="required">*</span>
                                </span>
                              </p>
                              <p className="comment-form-comment">
                                <textarea
                                  placeholder="Comment *"
                                  id="comment"
                                  name="comment"
                                  cols={45}
                                  rows={8}
                                  aria-required="true"
                                  defaultValue={""}
                                />
                              </p>
                              <p className="comment-form-author">
                                <input
                                  placeholder="Name *"
                                  id="author"
                                  name="author"
                                  type="text"
                                  defaultValue=""
                                  size={30}
                                  aria-required="true"
                                />
                              </p>
                              <p className="comment-form-email">
                                <input
                                  placeholder="Email *"
                                  id="email"
                                  name="email"
                                  type="text"
                                  defaultValue=""
                                  size={30}
                                  aria-required="true"
                                />
                              </p>
                              <p className="comment-form-url">
                                <input
                                  placeholder="Website *"
                                  id="url"
                                  name="url"
                                  type="text"
                                  defaultValue=""
                                  size={30}
                                  aria-required="true"
                                />
                              </p>
                              <p className="form-submit">
                                <input
                                  name="submit"
                                  type="submit"
                                  id="submit"
                                  className="submit"
                                  defaultValue="Post Comment"
                                />{" "}
                                <input
                                  type="hidden"
                                  name="comment_post_ID"
                                  defaultValue={2953}
                                  id="comment_post_ID"
                                />
                                <input
                                  type="hidden"
                                  name="comment_parent"
                                  id="comment_parent"
                                  defaultValue={0}
                                />
                              </p>
                            </form>{" "}
                          </div>
                          {/* #respond */}
                        </div>
                        <div className="clear" />
                      </div>
                      {/* #comments */}
                    </main>
                    <div
                      id="sidebar"
                      className="widget-area col-sm-3 sidebar-events sticky-sidebar"
                      role="complementary"
                    >
                      <div className="widget_book-event">
                        <h3 className="book-title">Buy Ticket</h3>
                        <div className="event_register_area">
                          <form
                            name="event_register"
                            className="event_register"
                            method="POST"
                          >
                            <ul className="info-event">
                              <li>
                                <div className="label">Total Slots</div>
                                <div className="value">0</div>
                              </li>
                              <li>
                                <div className="label">Booked Slots</div>
                                <div className="value">0</div>
                              </li>
                              <li className="event-cost">
                                <div className="label">Cost</div>
                                <div className="value">
                                  <span className="free">Free</span>
                                </div>
                              </li>
                              <li>
                                <div className="label">Quantity</div>
                                <div className="value">
                                  <input
                                    type="number"
                                    name="qty"
                                    defaultValue={1}
                                    min={1}
                                    max={0}
                                    id="event_register_qty"
                                  />
                                </div>
                              </li>
                            </ul>
                            <p className="event_auth_register_message_error">
                              You must set payment setting!{" "}
                            </p>
                            {/*Hide payment option when cost is 0*/}
                            <div className="event_register_foot">
                              <input
                                type="hidden"
                                name="event_id"
                                defaultValue={2953}
                              />
                              <input
                                type="hidden"
                                name="action"
                                defaultValue="event_auth_register"
                              />
                              <input
                                type="hidden"
                                id="event_auth_register_nonce"
                                name="event_auth_register_nonce"
                                defaultValue="a98f7ffbcb"
                              />
                              <input
                                type="hidden"
                                name="_wp_http_referer"
                                defaultValue="/events/build-education-website-using-wordpress/"
                              />{" "}
                              <button
                                type="submit"
                                disabled=""
                                className="event_button_disable"
                              >
                                Expired
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>{" "}
                    </div>
                    {/* #secondary */}
                  </div>
                </div>
              </section>
              <Footer />
              {/* #colophon */}
            </div>
            {/*end main-content*/}
          </div>
          {/* end content-pusher*/}
          <a href="#" id="back-to-top">
            <i className="fa fa-chevron-up" aria-hidden="true" />
          </a>
        </div>
        {/* end wrapper-container */}
        <div id="thim-popup-login">
          <div className="popup-login-wrapper has-shortcode">
            <div className="thim-login-container">
              [miniorange_social_login shape="longbuttonwithtext"
              theme="default" space="4" width="240" height="40"]
              <div className="thim-popup-inner">
                <div className="thim-login">
                  <h4 className="title">Login with your site account</h4>
                  <form
                    name="loginpopopform"
                    action="https://davsanford.com/wp-login.php"
                    method="post"
                    noValidate=""
                  >
                    <p className="login-username">
                      <input
                        type="text"
                        name="log"
                        placeholder="Username or email"
                        className="input required"
                        size={20}
                      />
                    </p>
                    <p className="login-password">
                      <input
                        type="password"
                        name="pwd"
                        placeholder="Password"
                        className="input required"
                        defaultValue=""
                        size={20}
                      />
                    </p>
                    <a
                      className="lost-pass-link"
                      href="../../account/indexc2b6.html?action=lostpassword"
                      title="Lost Password"
                    >
                      Lost your password?
                    </a>
                    <p className="forgetmenot login-remember">
                      <label htmlFor="popupRememberme">
                        <input
                          name="rememberme"
                          type="checkbox"
                          defaultValue="forever"
                          id="popupRememberme"
                        />{" "}
                        Remember Me{" "}
                      </label>
                    </p>
                    <p className="submit login-submit">
                      <input
                        type="submit"
                        name="wp-submit"
                        className="button button-primary button-large"
                        defaultValue="Login"
                      />
                      <input
                        type="hidden"
                        name="redirect_to"
                        defaultValue="https://davsanford.com/events/build-education-website-using-wordpress/"
                      />
                      <input type="hidden" name="testcookie" defaultValue={1} />
                      <input
                        type="hidden"
                        name="nonce"
                        defaultValue="bfd4b35eb0"
                      />
                      <input type="hidden" name="eduma_login_user" />
                    </p>
                  </form>
                  <p className="link-bottom">
                    Not a member yet?{" "}
                    <a
                      className="register"
                      href="../../account/index0ddc.html?action=register"
                    >
                      Register now
                    </a>
                  </p>{" "}
                </div>
                <div className="thim-register">
                  <h4 className="title">Register a new account</h4>
                  <form
                    className="auto_login"
                    name="registerformpopup"
                    action="https://davsanford.com/wp-login.php?action=register"
                    method="post"
                    noValidate="novalidate"
                  >
                    <input
                      type="hidden"
                      id="register_security"
                      name="register_security"
                      defaultValue="874cc93a21"
                    />
                    <input
                      type="hidden"
                      name="_wp_http_referer"
                      defaultValue="/events/build-education-website-using-wordpress/"
                    />
                    <p>
                      <input
                        placeholder="Username"
                        type="text"
                        name="user_login"
                        className="input required"
                      />
                    </p>
                    <p>
                      <input
                        placeholder="Email"
                        type="email"
                        name="user_email"
                        className="input required"
                      />
                    </p>
                    <p>
                      <input
                        placeholder="Password"
                        type="password"
                        name="password"
                        className="input required"
                      />
                    </p>
                    <p>
                      <input
                        placeholder="Repeat Password"
                        type="password"
                        name="repeat_password"
                        className="input required"
                      />
                    </p>
                    <p>
                      <input
                        type="checkbox"
                        className="required"
                        name="term"
                        id="termFormFieldPopup"
                      />
                      <label htmlFor="termFormField">
                        I accept the{" "}
                        <a href="#" target="_blank" rel="dofollow">
                          Terms of Service
                        </a>
                      </label>
                    </p>
                    <input
                      type="hidden"
                      name="redirect_to"
                      defaultValue="https://davsanford.com/events/build-education-website-using-wordpress/"
                    />
                    <input
                      type="hidden"
                      name="modify_user_notification"
                      defaultValue={1}
                    />
                    <input type="hidden" name="eduma_register_user" />
                    <p className="submit">
                      <input
                        type="submit"
                        name="wp-submit"
                        className="button button-primary button-large"
                        defaultValue="Sign up"
                      />
                    </p>
                  </form>
                  <p className="link-bottom">
                    Are you a member?{" "}
                    <a className="login" href="../../account/index.html">
                      Login now
                    </a>
                  </p>{" "}
                  <div className="popup-message" />
                </div>
              </div>
              <span className="close-popup">
                <i className="fa fa-times" aria-hidden="true" />
              </span>
              <div className="cssload-container">
                <div className="cssload-loading">
                  <i />
                  <i />
                  <i />
                  <i />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="gallery-slider-content" />
        {/* Call Now Button 1.4.1 (https://callnowbutton.com) [renderer:modern]*/}
        <a
          aria-label="Call Now Button"
          href="tel:8757327770"
          id="callnowbutton"
          className="call-now-button  cnb-zoom-100  cnb-zindex-10  cnb-single cnb-right cnb-displaymode cnb-displaymode-always"
          style={{
            backgroundImage: "url(data:image/svg+xml",
            backgroundColor: "#dd1313",
          }}
        >
          <span>Call Now Button</span>
        </a>
        <div
          id="qlwapp"
          className="qlwapp qlwapp-free qlwapp-bubble qlwapp-bottom-right qlwapp-all qlwapp-rounded"
        >
          <div className="qlwapp-container">
            <a
              className="qlwapp-toggle"
              data-action="open"
              data-phone={918757327770}
              data-message="I want to inquiry for admission."
              role="button"
              tabIndex={0}
              target="_blank"
            >
              <i className="qlwapp-icon qlwapp-whatsapp-icon" />
              <i className="qlwapp-close" data-action="close">
                ×
              </i>
            </a>
          </div>
        </div>
        <style
          id="elementor-post-8994"
          dangerouslySetInnerHTML={{
            __html:
              ".elementor-kit-8994{--e-global-color-primary:#DF4653;--e-global-color-secondary:#000000;--e-global-color-text:#7A7A7A;--e-global-color-accent:#898B89;--e-global-color-613c3264:#FFFFFF;--e-global-color-7d202ddd:#000;--e-global-color-2deca54e:#FFF;--e-global-color-40e5576:#D38F08;}.elementor-section.elementor-section-boxed > .elementor-container{max-width:1170px;}.e-con{--container-max-width:1170px;}{}h1.entry-title{display:var(--page-title-display);}@media(max-width:1024px){.elementor-section.elementor-section-boxed > .elementor-container{max-width:1024px;}.e-con{--container-max-width:1024px;}}@media(max-width:767px){.elementor-section.elementor-section-boxed > .elementor-container{max-width:767px;}.e-con{--container-max-width:767px;}}",
          }}
        />
        <link
          rel="stylesheet"
          id="fluentform-elementor-widget-css"
          href="../../wp-content/plugins/fluentform/assets/css/fluent-forms-elementor-widget58aa.css?ver=5.0.7"
          type="text/css"
          media="all"
        />
        <link
          rel="stylesheet"
          id="thim-font-icon-css"
          href="../../wp-content/themes/eduma/assets/css/thim-iconsf801.css?ver=6.3"
          type="text/css"
          media="all"
        />
        <link
          rel="stylesheet"
          id="flaticon-css"
          href="../../wp-content/themes/eduma/assets/css/flaticonf801.css?ver=6.3"
          type="text/css"
          media="all"
        />
        <link
          rel="stylesheet"
          id="font-pe-icon-7-css"
          href="../../wp-content/themes/eduma/assets/css/font-pe-icon-7f801.css?ver=6.3"
          type="text/css"
          media="all"
        />
        <link
          rel="stylesheet"
          id="ionicons-css"
          href="../../wp-content/themes/eduma/assets/css/ionicons.minf801.css?ver=6.3"
          type="text/css"
          media="all"
        />
        <link
          rel="stylesheet"
          id="elementor-icons-css"
          href="../../wp-content/plugins/elementor/assets/lib/eicons/css/elementor-icons.mind618.css?ver=5.21.0"
          type="text/css"
          media="all"
        />
        <link
          rel="stylesheet"
          id="swiper-css"
          href="../../wp-content/plugins/elementor/assets/lib/swiper/css/swiper.min48f5.css?ver=5.3.6"
          type="text/css"
          media="all"
        />
        <link
          rel="stylesheet"
          id="google-fonts-1-css"
          href="https://fonts.googleapis.com/css?family=Roboto+Slab%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic&display=swap&ver=6.3"
          type="text/css"
          media="all"
        />
        <link
          rel="stylesheet"
          id="rs-plugin-settings-css"
          href="../../wp-content/plugins/revslider/public/assets/css/rs6db9d.css?ver=6.6.7"
          type="text/css"
          media="all"
        />
        <style
          id="rs-plugin-settings-inline-css"
          type="text/css"
          dangerouslySetInnerHTML={{ __html: "\n#rs-demo-id {}\n" }}
        />
      </>
    </div>
  );
}

export default index;
