import React from "react";
import Footer from "../footer/footer";
import Header from "../header/header";
import { Link } from "react-router-dom";

function index() {
  return (
    <div>
      <div>
        <Header />
        <div id="wrapper-container" className="wrapper-container">
          <div className="content-pusher">
            <div id="main-content">
              <section className="content-area">
                <div className="top_heading_out">
                  <div
                    className="top_site_main"
                    style={{
                      backgroundImage: `url('../../wp-content/uploads/2023/05/trans.jpg')`,
                    }}
                  >
                    <span
                      className="overlay-top-header"
                      style={{ "background-color": "rgba(0,0,0,0.5)" }}
                    />{" "}
                    <div className="page-title-wrapper">
                      <div className="banner-wrapper container">
                        <h1 className="page-title">Syllabus</h1>{" "}
                      </div>
                    </div>
                  </div>{" "}
                  <div className="breadcrumbs-wrapper">
                    <div className="container">
                      <ul className="breadcrumbs" id="breadcrumbs">
                        <li>
                          {/* <a href="../index.html">Home</a> */}
                          <Link to="/">Home</Link>
                        </li>
                        <li>Syllabus</li>
                      </ul>{" "}
                    </div>
                  </div>
                </div>
                <div className="container site-content">
                  <div className="row">
                    <main id="main" className="site-main col-sm-12 full-width">
                      <article
                        id="post-11286"
                        className="post-11286 page type-page status-publish hentry"
                      >
                        <div className="entry-content"></div>
                        {/* .entry-content */}
                      </article>
                      {/* #post-## */}
                    </main>
                  </div>
                </div>
              </section>
              <Footer />

              {/* #colophon */}
            </div>
            {/*end main-content*/}
          </div>
          {/* end content-pusher*/}
        </div>
        {/* end wrapper-container */}

        <div className="gallery-slider-content" />
        {/* Call Now Button 1.4.1 (https://callnowbutton.com) [renderer:modern]*/}
      </div>
    </div>
  );
}

export default index;
