import React from "react";
import BusFee from "../Fee/BusFee";
import SchoolFee from "../Fee/SchoolFee";

function index() {
  return (
    <div>
      <div>
        <div className="mobile-menu-wrapper">
          <div className="mobile-menu-inner">
            <div className="icon-wrapper"></div>
            <nav className="mobile-menu-container mobile-effect"></nav>
          </div>
        </div>
        <div id="wrapper-container" className="wrapper-container">
          <div className="content-pusher">
            <div id="main-content">
              <section className="content-area">
                <div className="container site-content">
                  <div className="row">
                    <main id="main" className="site-main col-sm-12 full-width">
                      <article
                        id="post-11729"
                        className="post-11729 page type-page status-publish hentry"
                      >
                        <div className="entry-content">
                          <div
                            data-elementor-type="wp-page"
                            data-elementor-id={11729}
                            className="elementor elementor-11729"
                          >
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-09eb6bb elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="09eb6bb"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4116a8c"
                                  data-id="4116a8c"
                                  data-element_type="column"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-1452161 elementor-widget elementor-widget-heading"
                                      data-id={1452161}
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div
                                        className="elementor-widget-container"
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          marginBottom: 20,
                                        }}
                                      >
                                        <h1 className="elementor-heading-title elementor-size-default">
                                          D.A.V Sanford Public School
                                        </h1>{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-7d30da1 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="7d30da1"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5b26b02"
                                  data-id="5b26b02"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-bc8a772 elementor-widget elementor-widget-heading"
                                      data-id="bc8a772"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div
                                        className="elementor-widget-container"
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          marginBottom: 20,
                                        }}
                                      >
                                        <h3 className="elementor-heading-title elementor-size-default">
                                          FEE STRUCTUR
                                        </h3>{" "}
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-efd5a7a elementor-widget elementor-widget-image"
                                      data-id="efd5a7a"
                                      data-element_type="widget"
                                      data-widget_type="image.default"
                                    >
                                      <div className="elementor-widget-container">
                                        {/* <img
                                          decoding="async"
                                          fetchpriority="high"
                                          width={640}
                                          height={273}
                                          src="../wp-content/uploads/2023/05/fee-2.jpg"
                                          className="attachment-large size-large wp-image-11746"
                                          alt
                                          srcSet="https://davsanford.com/wp-content/uploads/2023/05/fee-2.jpg 1024w, https://davsanford.com/wp-content/uploads/2023/05/fee-2-300x128.jpg 300w, https://davsanford.com/wp-content/uploads/2023/05/fee-2-768x328.jpg 768w, https://davsanford.com/wp-content/uploads/2023/05/fee-2-600x256.jpg 600w"
                                          sizes="(max-width: 640px) 100vw, 640px"
                                        />{" "} */}
                                        <SchoolFee />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-871d86b"
                                  data-id="871d86b"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-74eef65 elementor-widget elementor-widget-heading"
                                      data-id="74eef65"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div
                                        className="elementor-widget-container"
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          marginBottom: 20,
                                        }}
                                      >
                                        <h3 className="elementor-heading-title elementor-size-default">
                                          BUS FARE
                                        </h3>{" "}
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-8f95cab elementor-widget elementor-widget-image"
                                      data-id="8f95cab"
                                      data-element_type="widget"
                                      data-widget_type="image.default"
                                    >
                                      <div className="elementor-widget-container">
                                        {/* <img
                                          decoding="async"
                                          width={640}
                                          height={273}
                                          src="../wp-content/uploads/2023/05/fees.jpg"
                                          className="attachment-large size-large wp-image-11736"
                                          alt
                                          srcSet="https://davsanford.com/wp-content/uploads/2023/05/fees.jpg 1024w, https://davsanford.com/wp-content/uploads/2023/05/fees-300x128.jpg 300w, https://davsanford.com/wp-content/uploads/2023/05/fees-768x328.jpg 768w, https://davsanford.com/wp-content/uploads/2023/05/fees-600x256.jpg 600w"
                                          sizes="(max-width: 640px) 100vw, 640px"
                                        />{" "} */}
                                        <BusFee />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-b96c865 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="b96c865"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4401c9c"
                                  data-id="4401c9c"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-feee6e4 elementor-widget elementor-widget-text-editor"
                                      data-id="feee6e4"
                                      data-element_type="widget"
                                      data-widget_type="text-editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <p>
                                          <strong>Note</strong>-: Under 1 km Bus
                                          Service Free
                                        </p>{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-025edd6 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="025edd6"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e965139"
                                  data-id="e965139"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-0700676 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                        {/* .entry-content */}
                      </article>
                      {/* #post-## */}
                    </main>
                  </div>
                </div>
              </section>

              {/* #colophon */}
            </div>
            {/*end main-content*/}
          </div>
          {/* end content-pusher*/}
        </div>
        {/* end wrapper-container */}
      </div>
    </div>
  );
}

export default index;
