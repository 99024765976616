import React from "react";
import { Link } from "react-router-dom";
import Footer from "../footer/footer";
import Header from "../header/header";

function AboutDAV() {
  return (
    <div>
      <>
        <div id="wrapper-container" className="wrapper-container">
          <div className="content-pusher">
            <Header />
            <div id="main-content">
              <section className="content-area">
                <div className="top_heading_out">
                  <div
                    className="top_site_main"
                    style={{
                      backgroundImage: `url('../../wp-content/uploads/2023/05/trans.jpg')`,
                    }}
                  >
                    <span
                      className="overlay-top-header"
                      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
                    />{" "}
                    <div className="page-title-wrapper">
                      <div className="banner-wrapper container">
                        <h1 className="page-title">About D.A.V Sanford</h1>{" "}
                      </div>
                    </div>
                  </div>{" "}
                  <div className="breadcrumbs-wrapper">
                    <div className="container">
                      <ul className="breadcrumbs" id="breadcrumbs">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>About D.A.V Sanford</li>
                      </ul>{" "}
                    </div>
                  </div>
                </div>
                <div className="container sidebar-right site-content">
                  <div className="row">
                    <main id="main" className="site-main col-sm-9 alignleft">
                      <article
                        id="post-11274"
                        className="post-11274 page type-page status-publish hentry"
                      >
                        <div className="entry-content">
                          <div
                            data-elementor-type="wp-page"
                            data-elementor-id={11274}
                            className="elementor elementor-11274"
                          >
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-cdb62e5 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="cdb62e5"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-7ff3432"
                                  data-id="7ff3432"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-05b5ed8 elementor-widget elementor-widget-image"
                                      data-id="05b5ed8"
                                      data-element_type="widget"
                                      data-widget_type="image.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <img
                                          decoding="async"
                                          fetchpriority="high"
                                          width={410}
                                          height={250}
                                          src="./wp-content/uploads/2023/05/43.jpg"
                                          className="attachment-large size-large wp-image-11340"
                                          alt=""
                                          srcSet="https://davsanford.com/wp-content/uploads/2023/05/43.jpg 410w, https://davsanford.com/wp-content/uploads/2023/05/43-300x183.jpg 300w"
                                          sizes="(max-width: 410px) 100vw, 410px"
                                        />{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-5970260"
                                  data-id={5970260}
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-35bf074 elementor-widget elementor-widget-text-editor"
                                      data-id="35bf074"
                                      data-element_type="widget"
                                      data-widget_type="text-editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <p>
                                          D.A.V Sanford Public School is a
                                          co-educational English medium Senior
                                          Secondary School which is affiliated
                                          to Central Board of Secondary
                                          Education (CBSE). Founded with the
                                          purpose to impart intellectual and
                                          moral values to the students, the
                                          school stands for its finest education
                                          platform. The school provides
                                          wholesome and quality education.
                                        </p>
                                        <p>
                                          We are committed to provide our
                                          students enriched experience to
                                          develop good habits, appropriate
                                          attitudes and competitive skills that
                                          will help them to have a stand in the
                                          world. We work with the aim of
                                          providing an all-round development of
                                          the child right from physical, social,
                                          emotional to intellectual one.
                                        </p>{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-031ed5b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="031ed5b"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4df519e"
                                  data-id="4df519e"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-684bdd5 elementor-widget elementor-widget-text-editor"
                                      data-id="684bdd5"
                                      data-element_type="widget"
                                      data-widget_type="text-editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <p>
                                          We focus on shared commitment to
                                          academic excellence, intellectual
                                          growth, ethical awareness and
                                          sportsmanship. The school upholds the
                                          Chairman’s commitment to excellence in
                                          all the fields. We aim to inculcate
                                          among students a love for learning and
                                          a burning desire to excel in every
                                          field. For that we aim to equip our
                                          students with intellectual and
                                          practical skills, necessary to meet
                                          the challenges in future. The values
                                          which are ingrained help to promote
                                          confidence, direction and critical
                                          thinking skills, leading to the
                                          development of well adjusted and
                                          integrated personalities. The school
                                          does not in any way, discriminate on
                                          the basis of race.
                                        </p>{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-625712f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="625712f"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f20ceba"
                                  data-id="f20ceba"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-22f5377 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                                      data-id="22f5377"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                        {/* .entry-content */}
                      </article>
                      {/* #post-## */}
                    </main>
                    <div
                      id="sidebar"
                      className="widget-area col-sm-3 sticky-sidebar"
                      role="complementary"
                    >
                      <aside
                        id="nav_menu-16"
                        className="widget widget_nav_menu"
                      >
                        <h4 className="widget-title">Important Link</h4>
                        <div className="menu-sidebar-about-container">
                          <ul id="menu-sidebar-about" className="menu">
                            <li
                              id="menu-item-11492"
                              className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-11274 current_page_item menu-item-11492"
                            >
                              <a href="index.html" aria-current="page">
                                About D.A.V Sanford
                              </a>
                            </li>
                            <li
                              id="menu-item-11488"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11488"
                            >
                              <a href="../principals-message/index.html">
                                Principal’s Message
                              </a>
                            </li>
                            <li
                              id="menu-item-11491"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11491"
                            >
                              <a href="../vision-mission/index.html">
                                Vision &amp; Mission
                              </a>
                            </li>
                            <li
                              id="menu-item-11489"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11489"
                            >
                              <a href="../smart-class/index.html">
                                Smart Class
                              </a>
                            </li>
                            <li
                              id="menu-item-11484"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11484"
                            >
                              <a href="../art-craft/index.html">
                                Art &amp; Craft
                              </a>
                            </li>
                            <li
                              id="menu-item-11485"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11485"
                            >
                              <a href="../guideliness/index.html">Guidelines</a>
                            </li>
                            <li
                              id="menu-item-11486"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11486"
                            >
                              <a href="../laboratory/index.html">Laboratory</a>
                            </li>
                            <li
                              id="menu-item-11487"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11487"
                            >
                              <a href="../library/index.html">Library</a>
                            </li>
                            <li
                              id="menu-item-11490"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11490"
                            >
                              <a href="../transportation/index.html">
                                Transportation
                              </a>
                            </li>
                          </ul>
                        </div>
                      </aside>
                      <aside
                        id="list-post-2"
                        className="widget widget_list-post"
                      >
                        <div className="thim-widget-list-post template-list-post">
                          <h3 className="widget-title">Latest Posts</h3>
                          <div className="sc-list-post thim-list-posts sidebar">
                            <div className="item-post has_thumb post-3696 post type-post status-publish format-standard has-post-thumbnail hentry category-student-corner tag-wordpress">
                              <div className="article-image image">
                                <a href="../why-you-should-read-every-day/index.html">
                                  <img
                                    width={150}
                                    height={150}
                                    src="./wp-content/uploads/2023/05/5-step-150x150.jpg"
                                    className="attachment-thumbnail size-thumbnail wp-post-image"
                                    alt=""
                                    decoding="async"
                                    loading="lazy"
                                    srcSet="https://davsanford.com/wp-content/uploads/2023/05/5-step-150x150.jpg 150w, https://davsanford.com/wp-content/uploads/2023/05/5-step-300x300.jpg 300w, https://davsanford.com/wp-content/uploads/2023/05/5-step-100x100.jpg 100w, https://davsanford.com/wp-content/uploads/2023/05/5-step-350x350.jpg 350w, https://davsanford.com/wp-content/uploads/2023/05/5-step-420x420.jpg 420w"
                                    sizes="(max-width: 150px) 100vw, 150px"
                                  />
                                </a>
                              </div>
                              <div className="article-title-wrapper">
                                <h5 className="title">
                                  <a
                                    href="../why-you-should-read-every-day/index.html"
                                    className="article-title"
                                  >
                                    5 Steps Essential To Be Taken For The
                                    Holistic Growth of Children in 2023
                                  </a>
                                </h5>
                                <div className="article-date">
                                  <span className="day">14</span>
                                  <span className="month">May</span>
                                  <span className="year">2023</span>
                                </div>
                              </div>
                            </div>
                            <div className="item-post has_thumb post-11202 post type-post status-publish format-standard has-post-thumbnail hentry category-student-corner">
                              <div className="article-image image">
                                <a href="../ways-to-improve-the-social-skills-of-your-children-during-the-school-holidays/index.html">
                                  <img
                                    width={150}
                                    height={150}
                                    src="./wp-content/uploads/2023/05/holiday-150x150.jpg"
                                    className="attachment-thumbnail size-thumbnail wp-post-image"
                                    alt=""
                                    decoding="async"
                                    loading="lazy"
                                    srcSet="https://davsanford.com/wp-content/uploads/2023/05/holiday-150x150.jpg 150w, https://davsanford.com/wp-content/uploads/2023/05/holiday-300x300.jpg 300w, https://davsanford.com/wp-content/uploads/2023/05/holiday-100x100.jpg 100w, https://davsanford.com/wp-content/uploads/2023/05/holiday-350x350.jpg 350w, https://davsanford.com/wp-content/uploads/2023/05/holiday-420x420.jpg 420w"
                                    sizes="(max-width: 150px) 100vw, 150px"
                                  />
                                </a>
                              </div>
                              <div className="article-title-wrapper">
                                <h5 className="title">
                                  <a
                                    href="../ways-to-improve-the-social-skills-of-your-children-during-the-school-holidays/index.html"
                                    className="article-title"
                                  >
                                    Ways To Improve the Social Skills Of Your
                                    Children During The School Holidays
                                  </a>
                                </h5>
                                <div className="article-date">
                                  <span className="day">14</span>
                                  <span className="month">May</span>
                                  <span className="year">2023</span>
                                </div>
                              </div>
                            </div>
                            <div className="item-post has_thumb post-11988 post type-post status-publish format-standard has-post-thumbnail hentry category-school-life">
                              <div className="article-image image">
                                <a href="../why-choose-cbse-board-for-your-child/index.html">
                                  <img
                                    width={150}
                                    height={150}
                                    src="./wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-150x150.jpeg"
                                    className="attachment-thumbnail size-thumbnail wp-post-image"
                                    alt=""
                                    decoding="async"
                                    loading="lazy"
                                    srcSet="https://davsanford.com/wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-150x150.jpeg 150w, https://davsanford.com/wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-300x300.jpeg 300w, https://davsanford.com/wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-100x100.jpeg 100w, https://davsanford.com/wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-350x350.jpeg 350w"
                                    sizes="(max-width: 150px) 100vw, 150px"
                                  />
                                </a>
                              </div>
                              <div className="article-title-wrapper">
                                <h5 className="title">
                                  <a href="#" className="article-title">
                                    Why choose CBSE Board for your child?
                                  </a>
                                </h5>
                                <div className="article-date">
                                  <span className="day">07</span>
                                  <span className="month">Jun</span>
                                  <span className="year">2023</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </aside>
                    </div>
                    {/* #secondary */}
                  </div>
                </div>
              </section>
              <Footer />
              {/* #colophon */}
            </div>
            {/*end main-content*/}
          </div>
          {/* end content-pusher*/}
          <a href="#" id="back-to-top">
            <i className="fa fa-chevron-up" aria-hidden="true" />
          </a>
        </div>
        {/* end wrapper-container */}
        <div id="thim-popup-login">
          <div className="popup-login-wrapper has-shortcode">
            <div className="thim-login-container">
              [miniorange_social_login shape="longbuttonwithtext"
              theme="default" space="4" width="240" height="40"]
              <div className="thim-popup-inner">
                <div className="thim-login">
                  <h4 className="title">Login with your site account</h4>
                  <form
                    name="loginpopopform"
                    action="https://davsanford.com/wp-login.php"
                    method="post"
                    noValidate=""
                  >
                    <p className="login-username">
                      <input
                        type="text"
                        name="log"
                        placeholder="Username or email"
                        className="input required"
                        size={20}
                      />
                    </p>
                    <p className="login-password">
                      <input
                        type="password"
                        name="pwd"
                        placeholder="Password"
                        className="input required"
                        defaultValue=""
                        size={20}
                      />
                    </p>
                    <a
                      className="lost-pass-link"
                      href="../account/indexc2b6.html?action=lostpassword"
                      title="Lost Password"
                    >
                      Lost your password?
                    </a>
                    <p className="forgetmenot login-remember">
                      <label htmlFor="popupRememberme">
                        <input
                          name="rememberme"
                          type="checkbox"
                          defaultValue="forever"
                          id="popupRememberme"
                        />{" "}
                        Remember Me{" "}
                      </label>
                    </p>
                    <p className="submit login-submit">
                      <input
                        type="submit"
                        name="wp-submit"
                        className="button button-primary button-large"
                        defaultValue="Login"
                      />
                      <input
                        type="hidden"
                        name="redirect_to"
                        defaultValue="https://davsanford.com/about-d-a-v-sanford/"
                      />
                      <input type="hidden" name="testcookie" defaultValue={1} />
                      <input
                        type="hidden"
                        name="nonce"
                        defaultValue="44801733e8"
                      />
                      <input type="hidden" name="eduma_login_user" />
                    </p>
                  </form>
                  <p className="link-bottom">
                    Not a member yet?{" "}
                    <a
                      className="register"
                      href="../account/index0ddc.html?action=register"
                    >
                      Register now
                    </a>
                  </p>{" "}
                </div>
                <div className="thim-register">
                  <h4 className="title">Register a new account</h4>
                  <form
                    className="auto_login"
                    name="registerformpopup"
                    action="https://davsanford.com/wp-login.php?action=register"
                    method="post"
                    noValidate="novalidate"
                  >
                    <input
                      type="hidden"
                      id="register_security"
                      name="register_security"
                      defaultValue="49d1595196"
                    />
                    <input
                      type="hidden"
                      name="_wp_http_referer"
                      defaultValue="/about-d-a-v-sanford/"
                    />
                    <p>
                      <input
                        placeholder="Username"
                        type="text"
                        name="user_login"
                        className="input required"
                      />
                    </p>
                    <p>
                      <input
                        placeholder="Email"
                        type="email"
                        name="user_email"
                        className="input required"
                      />
                    </p>
                    <p>
                      <input
                        placeholder="Password"
                        type="password"
                        name="password"
                        className="input required"
                      />
                    </p>
                    <p>
                      <input
                        placeholder="Repeat Password"
                        type="password"
                        name="repeat_password"
                        className="input required"
                      />
                    </p>
                    <p>
                      <input
                        type="checkbox"
                        className="required"
                        name="term"
                        id="termFormFieldPopup"
                      />
                      <label htmlFor="termFormField">
                        I accept the{" "}
                        <a href="#" target="_blank" rel="dofollow">
                          Terms of Service
                        </a>
                      </label>
                    </p>
                    <input
                      type="hidden"
                      name="redirect_to"
                      defaultValue="https://davsanford.com/about-d-a-v-sanford/"
                    />
                    <input
                      type="hidden"
                      name="modify_user_notification"
                      defaultValue={1}
                    />
                    <input type="hidden" name="eduma_register_user" />
                    <p className="submit">
                      <input
                        type="submit"
                        name="wp-submit"
                        className="button button-primary button-large"
                        defaultValue="Sign up"
                      />
                    </p>
                  </form>
                  <p className="link-bottom">
                    Are you a member?{" "}
                    <a className="login" href="../account/index.html">
                      Login now
                    </a>
                  </p>{" "}
                  <div className="popup-message" />
                </div>
              </div>
              <span className="close-popup">
                <i className="fa fa-times" aria-hidden="true" />
              </span>
              <div className="cssload-container">
                <div className="cssload-loading">
                  <i />
                  <i />
                  <i />
                  <i />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="gallery-slider-content" />
        {/* Call Now Button 1.4.1 (https://callnowbutton.com) [renderer:modern]*/}
      </>
    </div>
  );
}

export default AboutDAV;
