import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import FacebookIcon from "@mui/icons-material/Facebook";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";

export default function MaterialUIComponent() {
  const phoneNumber = "8757-327-770"; // Replace with your phone number

  const handleCallClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };
  return (
    <Box
      className="sticky-bar hidden-sm hidden-xs hidden-xxs"
      sx={{
        boxSizing: "border-box",
        display: "block",
        bottom: "25%",
        right: "-125px",
        position: "fixed",
        zIndex: 1040,
        width: "184px",
        backgroundColor: "orange",
      }}
    >
      <List
        sx={{
          boxSizing: "border-box",
          margin: 0,
          padding: 0,
          listStyle: "none",
          marginTop: 0,
          marginBottom: 0,
          backgroundColor: "orange",
        }}
      >
        <ListItem
          className="call"
          sx={{
            boxSizing: "border-box",
            background: "#4D4C7D",
            borderRadius: "4px 0px 0px 4px",
            margin: "2px",
            transition: "all 0.2s ease-in 0s",
            padding: "2px",
            height: "48px",
          }}
        >
          <ListItemButton
            component="a"
            onClick={handleCallClick}
            sx={{
              boxSizing: "border-box",

              color: "rgb(255, 255, 255)",
              backgroundColor: "orange",
              fontSize: "12px",
              lineHeight: "14px",
              fontWeight: 600,
              letterSpacing: "0.5px",
              textDecoration: "none",
            }}
          >
            <ListItemIcon>
              <CallIcon sx={{ height: 32, width: 32 }} />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>

        <ListItem
          className="d-one"
          sx={{
            boxSizing: "border-box",
            background: "#4D4C7D",
            borderRadius: "4px 0px 0px 4px",
            margin: "2px",
            transition: " all 0.2s ease-in 0s",
            padding: "2px",
            height: "48px",
          }}
        >
          <ListItemButton
            component="a"
            href="https://www.facebook.com/davsanfordpublicschool"
            target="_blank"
            sx={{
              boxSizing: "border-box",
              backgroundColor: "orange",
              color: "rgb(255, 255, 255)",
              fontSize: "12px",
              lineHeight: "14px",
              fontWeight: 600,
              letterSpacing: "0.5px",
              textDecoration: "none",
            }}
          >
            <ListItemIcon>
              <FacebookIcon sx={{ height: 32, width: 32 }} />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        <ListItem
          className="d-one"
          sx={{
            boxSizing: "border-box",
            background: "#4D4C7D",
            borderRadius: "4px 0px 0px 4px",
            margin: "2px",
            transition: " all 0.2s ease-in 0s",
            padding: "2px",
            height: "48px",
          }}
        >
          <ListItemButton
            component="a"
            href="https://www.instagram.com/davsanford777/"
            target="_blank"
            sx={{
              boxSizing: "border-box",
              backgroundColor: "orange",
              color: "rgb(255, 255, 255)",
              fontSize: "12px",
              lineHeight: "14px",
              fontWeight: 600,
              letterSpacing: "0.5px",
              textDecoration: "none",
            }}
          >
            <ListItemIcon>
              <InstagramIcon sx={{ height: 32, width: 32 }} />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        <ListItem
          className="ltown"
          sx={{
            boxSizing: "border-box",
            background: "#4D4C7D",
            borderRadius: "4px 0px 0px 4px",
            margin: "2px",
            transition: "all 0.2s ease-in 0s",
            padding: "2px",
            height: "48px",
          }}
        >
          <ListItemButton
            component={Link} // Use Link component for navigation
            to="/map" // Specify the path for the new page
            sx={{
              boxSizing: "border-box",
              backgroundColor: "orange",
              color: "rgb(255, 255, 255)",
              fontSize: "12px",
              lineHeight: "14px",
              fontWeight: 600,
              letterSpacing: "0.5px",
              textDecoration: "none",
            }}
          >
            <ListItemIcon>
              <LocationOnIcon sx={{ height: 32, width: 32 }} />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
      </List>

      <style
        dangerouslySetInnerHTML={{
          __html: `
html {
  box-sizing: border-box;
  text-size-adjust: 100%;
  font-size: 10px;
  -webkit-tap-highlight-color: transparent;
  overflow-x: hidden;
}

body {
  box-sizing: border-box;
  margin: 0px;
  font-size: 14px;
  line-height: 1.5;
  color: rgb(102, 102, 102);
  font-weight: 400;
  background: rgb(250, 250, 250);
  background-color: rgb(250, 250, 250);
  font-family: montserrat, sans-serif;
  overflow-x: hidden;
}
`,
        }}
      />
    </Box>
  );
}
