import React from "react";
import Footer from "../footer/footer";
import Header from "../header/header";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Demo from "../Demo";

import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { styled } from "@mui/material";

const useStyles = styled((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: theme.spacing(3),
  },
  card: {
    maxWidth: 200,
    margin: theme.spacing(2),
  },
  image: {
    width: "100%",
    height: 200,
    objectFit: "cover",
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
}));

function About() {
  const classes = useStyles();
  const [data, setData] = useState([]);

  useEffect(() => {
    Award();
  }, []);

  const Award = async () => {
    axios
      .get(
        "https://lyci0i0cnf.execute-api.ap-south-1.amazonaws.com/sanford/sanford_team_all_data"
      )
      .then((response) => {
        // Handle the response and store the data
        setData(response.data["body-json"]["body"]["Items"]);
        console.log(response.data);
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error:", error);
      });
  };
  return (
    <div>
      {" "}
      <>
        {/* Mobile Menu*/}

        <div id="wrapper-container" className="wrapper-container">
          <div className="content-pusher">
            <Header />
            <div id="main-content">
              <section className="content-area">
                <div className="top_heading_out">
                  <div
                    className="top_site_main"
                    // style={{
                    //   color: "#ffffff",
                    //   backgroundImage:
                    //     "url(./wp-content/uploads/2022/04/topheading-blog.jpeg)",
                    // }}
                    style={{
                      backgroundImage: `url('../../wp-content/uploads/2023/05/Activities-1.jpg')`,
                    }}
                  >
                    <span
                      className="overlay-top-header"
                      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
                    />{" "}
                    <div className="page-title-wrapper">
                      <div className="banner-wrapper container">
                        <h1 className="page-title">About Us</h1>{" "}
                      </div>
                    </div>
                  </div>{" "}
                  <div className="breadcrumbs-wrapper">
                    <div className="container">
                      <ul className="breadcrumbs" id="breadcrumbs">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>About Us</li>
                      </ul>{" "}
                    </div>
                  </div>
                </div>
                <div className="container site-content">
                  <div className="row">
                    <main id="main" className="site-main col-sm-12 full-width">
                      <article
                        id="post-2901"
                        className="post-2901 page type-page status-publish hentry"
                      >
                        <div className="entry-content">
                          <div
                            data-elementor-type="wp-post"
                            data-elementor-id={2901}
                            className="elementor elementor-2901"
                          >
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-dbdef59 elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="dbdef59"
                              data-element_type="section"
                              data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7a78582"
                                  data-id="7a78582"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <section
                                      className="elementor-section elementor-inner-section elementor-element elementor-element-f5607a6 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                      data-id="f5607a6"
                                      data-element_type="section"
                                    >
                                      <div className="elementor-container elementor-column-gap-default">
                                        <div
                                          className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-06ca28b"
                                          data-id="06ca28b"
                                          data-element_type="column"
                                        >
                                          <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                              className="elementor-element elementor-element-48985ed elementor-widget thim-ekits-heading elementor-widget-thim-heading"
                                              data-id="48985ed"
                                              data-element_type="widget"
                                              data-widget_type="thim-heading.default"
                                            >
                                              <div className="elementor-widget-container">
                                                <div className="sc_heading ">
                                                  <h3 className="title">
                                                    About D.A.V Sanford Public
                                                    School
                                                  </h3>
                                                  <span className="line" />
                                                </div>{" "}
                                              </div>
                                            </div>
                                            <Demo />
                                            <div
                                              className="elementor-element elementor-element-be33482 elementor-widget elementor-widget-text-editor"
                                              data-id="be33482"
                                              data-element_type="widget"
                                              data-widget_type="text-editor.default"
                                            >
                                              <div className="elementor-widget-container">
                                                <p style={{ fontSize: 15 }}>
                                                  D.A.V Sanford Public School is
                                                  a co-educational English
                                                  medium Senior Secondary School
                                                  which is affiliated to Central
                                                  Board of Secondary Education
                                                  (CBSE). Founded with the
                                                  purpose to impart intellectual
                                                  and moral values to the
                                                  students, the school stands
                                                  for its finest education
                                                  platform. The school provides
                                                  wholesome and quality
                                                  education.
                                                </p>
                                                <p style={{ fontSize: 15 }}>
                                                  We are committed to provide
                                                  our students enriched
                                                  experience to develop good
                                                  habits, appropriate attitudes
                                                  and competitive skills that
                                                  will help them to have a stand
                                                  in the world. We work with the
                                                  aim of providing an all-round
                                                  development of the child right
                                                  from physical, social,
                                                  emotional to intellectual one.
                                                </p>{" "}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-9169989"
                                          data-id={9169989}
                                          data-element_type="column"
                                        >
                                          <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                              className="elementor-element elementor-element-e05f6ef elementor-widget elementor-widget-image"
                                              data-id="e05f6ef"
                                              data-element_type="widget"
                                              data-widget_type="image.default"
                                            >
                                              <div className="elementor-widget-container">
                                                <img
                                                  decoding="async"
                                                  fetchpriority="high"
                                                  width={800}
                                                  height={600}
                                                  src="./wp-content/uploads/2023/05/gallery-5.jpg"
                                                  className="attachment-full size-full wp-image-11370"
                                                  alt=""
                                                  srcSet="https://davsanford.com/wp-content/uploads/2023/05/gallery-5.jpg 800w, https://davsanford.com/wp-content/uploads/2023/05/gallery-5-300x225.jpg 300w, https://davsanford.com/wp-content/uploads/2023/05/gallery-5-768x576.jpg 768w, https://davsanford.com/wp-content/uploads/2023/05/gallery-5-400x300.jpg 400w, https://davsanford.com/wp-content/uploads/2023/05/gallery-5-600x450.jpg 600w"
                                                  sizes="(max-width: 800px) 100vw, 800px"
                                                />{" "}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>

                                    <div
                                      className="elementor-element elementor-element-2ba63d9 elementor-widget elementor-widget-text-editor"
                                      data-id="2ba63d9"
                                      data-element_type="widget"
                                      data-widget_type="text-editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <p style={{ fontSize: 15 }}>
                                          We focus on shared commitment to
                                          academic excellence, intellectual
                                          growth, ethical awareness and
                                          sportsmanship. The school upholds the
                                          Chairman’s commitment to excellence in
                                          all the fields. We aim to inculcate
                                          among students a love for learning and
                                          a burning desire to excel in every
                                          field. For that we aim to equip our
                                          students with intellectual and
                                          practical skills, necessary to meet
                                          the challenges in future. The values
                                          which are ingrained help to promote
                                          confidence, direction and critical
                                          thinking skills, leading to the
                                          development of well adjusted and
                                          integrated personalities. The school
                                          does not in any way, discriminate on
                                          the basis of race
                                        </p>{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-ab70d98 elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="ab70d98"
                              data-element_type="section"
                              data-settings='{"stretch_section":"section-stretched"}'
                            >
                              <div className="elementor-background-overlay" />
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ba6aeb6"
                                  data-id="ba6aeb6"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-4b0d10b thim-ekits-heading-text-center elementor-widget thim-ekits-heading elementor-widget-thim-heading"
                                      data-id="4b0d10b"
                                      data-element_type="widget"
                                      data-widget_type="thim-heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="sc_heading text-center">
                                          <h2 className="title">
                                            Meet Our Team
                                          </h2>
                                          <div className="sub-heading">
                                            <p style={{ fontSize: 15 }}>
                                              Plugins your themes with even more
                                              features.
                                            </p>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>

                                    <Container className={classes.root}>
                                      <Grid container spacing={2}>
                                        {data.map((item, index) => (
                                          <Grid
                                            item
                                            key={index}
                                            xs={12}
                                            sm={6}
                                            md={4}
                                          >
                                            <Card className={classes.card}>
                                              <img
                                                src={item.imageUrl}
                                                alt={item.name}
                                                style={{
                                                  width: 400,
                                                  height: 200,
                                                }}
                                              />
                                              <CardContent>
                                                <div
                                                  className="thim-ekit-team__member-name"
                                                  style={{
                                                    fontSize: 18,
                                                    fontFamily: "Roboto",
                                                    color: "green",
                                                  }}
                                                >
                                                  {item.name}
                                                </div>
                                                <div
                                                  className="thim-ekit-team__member-description"
                                                  style={{
                                                    fontSize: 15,
                                                    color: "grey",
                                                  }}
                                                >
                                                  {item.message}
                                                </div>
                                              </CardContent>
                                              {/* <CardActions>
                                                <IconButton
                                                  href="#"
                                                  target="_blank"
                                                  rel="nofollow"
                                                >
                                                  <FacebookIcon />
                                                </IconButton>
                                                <IconButton
                                                  href="#"
                                                  target="_blank"
                                                  rel="nofollow"
                                                >
                                                  <TwitterIcon />
                                                </IconButton>
                                                <IconButton
                                                  href="#"
                                                  target="_blank"
                                                  rel="nofollow"
                                                >
                                                  <YouTubeIcon />
                                                </IconButton>
                                                <IconButton
                                                  href="#"
                                                  target="_blank"
                                                  rel="nofollow"
                                                >
                                                  <LinkedInIcon />
                                                </IconButton>
                                              </CardActions> */}
                                            </Card>
                                          </Grid>
                                        ))}
                                      </Grid>
                                    </Container>
                                    <div
                                      className="elementor-element elementor-element-8f4212d elementor-align-center elementor-widget elementor-widget-button"
                                      data-id="8f4212d"
                                      data-element_type="widget"
                                      data-widget_type="button.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-button-wrapper">
                                          {/* <a
                                            className="elementor-button elementor-button-link elementor-size-sm"
                                            href="#"
                                          >
                                            <span className="elementor-button-content-wrapper">
                                              <span className="elementor-button-text">
                                                View our team
                                              </span>
                                            </span>
                                          </a> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                        {/* .entry-content */}
                      </article>
                      {/* #post-## */}
                    </main>
                  </div>
                </div>
              </section>
              <Footer />
              {/* #colophon */}
            </div>
            {/*end main-content*/}
          </div>
          {/* end content-pusher*/}
        </div>
        {/* end wrapper-container */}
      </>
    </div>
  );
}

export default About;
