import Header from "../header/header";
import Footer from "../footer/footer";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";

function Index() {
  const [happeningData, setHappeningData] = useState([]);
  const [upcomingData, setUpcomingData] = useState([]);
  const [expiredData, setExpiredData] = useState([]);
  const [activeTab, setActiveTab] = useState("happening");

  useEffect(() => {
    Award();
    UPAward();
    CMAward();
  }, []);

  const Award = async () => {
    try {
      const response = await axios.post(
        "https://lyci0i0cnf.execute-api.ap-south-1.amazonaws.com/sanford/sanford_events_all_data",
        { event_status: "happening" }
      );
      setHappeningData(response.data["body-json"]["body"]["Items"]);
      // console.log("happmomg", response.data["body-json"]["body"]["Items"]);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const CMAward = async () => {
    try {
      const response = await axios.post(
        "https://lyci0i0cnf.execute-api.ap-south-1.amazonaws.com/sanford/sanford_events_all_data",
        { event_status: "upcoming" }
      );
      setUpcomingData(response.data["body-json"]["body"]["Items"]);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const UPAward = async () => {
    try {
      const response = await axios.post(
        "https://lyci0i0cnf.execute-api.ap-south-1.amazonaws.com/sanford/sanford_events_all_data",
        { event_status: "expired" }
      );
      setExpiredData(response.data["body-json"]["body"]["Items"]);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <Header />
      <div id="wrapper-container" className="wrapper-container">
        <div className="content-pusher">
          <div id="main-content">
            <section className="content-area">
              <div className="top_heading_out">
                <div
                  className="top_site_main"
                  style={{
                    backgroundImage: `url('../../wp-content/uploads/2023/05/trans.jpg')`,
                  }}
                >
                  <span
                    className="overlay-top-header"
                    style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
                  />
                  <div className="page-title-wrapper">
                    <div className="banner-wrapper container">
                      <h1 className="page-title">Events</h1>
                    </div>
                  </div>
                </div>
                <div className="breadcrumbs-wrapper">
                  <div className="container">
                    <ul className="breadcrumbs" id="breadcrumbs">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>Events</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="container site-content">
                <div className="row">
                  <main id="main" className="site-main col-sm-12 full-width">
                    <ul className="nav nav-tabs">
                      <li
                        role="presentation"
                        className={activeTab === "happening" ? "active" : ""}
                      >
                        <a
                          href="#tab-happening"
                          onClick={() => handleTabClick("happening")}
                        >
                          Happening
                        </a>
                      </li>
                      <li
                        role="presentation"
                        className={activeTab === "upcoming" ? "active" : ""}
                      >
                        <a
                          href="#tab-upcoming"
                          onClick={() => handleTabClick("upcoming")}
                        >
                          Upcoming
                        </a>
                      </li>
                      <li
                        role="presentation"
                        className={activeTab === "expired" ? "active" : ""}
                      >
                        <a
                          href="#tab-expired"
                          onClick={() => handleTabClick("expired")}
                        >
                          Expired
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content thim-list-event">
                      <div
                        role="tabpanel"
                        className={`tab-pane fade ${
                          activeTab === "happening" ? "active in" : ""
                        }`}
                        id="tab-happening"
                      >
                        {/* Render data for Happening tab */}
                        {happeningData.map((event) => (
                          <div key={event.id} className="item-event">
                            <h2>{event.event_name}</h2>
                            <p>Date: {event.event_date}</p>
                            <p>Date: {event.event_time}</p>
                            <p>Location: {event.place}</p>
                            <p>Description: {event.message}</p>
                            {/* Add image for Happening event */}
                            <img
                              src={event.imageUrl}
                              alt={event.title}
                              style={{ height: 400, width: 400 }}
                            />
                          </div>
                        ))}
                      </div>
                      <div
                        role="tabpanel"
                        className={`tab-pane fade ${
                          activeTab === "upcoming" ? "active in" : ""
                        }`}
                        id="tab-upcoming"
                      >
                        {/* Render data for Upcoming tab */}
                        {upcomingData.map((event) => (
                          <div key={event.id} className="item-event">
                            <h2>{event.event_name}</h2>
                            <p>Date: {event.event_date}</p>
                            <p>Date: {event.event_time}</p>
                            <p>Location: {event.place}</p>
                            <p>Description: {event.message}</p>
                            {/* Add image for Upcoming event */}
                            <img
                              src={event.imageUrl}
                              alt={event.title}
                              style={{ height: 400, width: 400 }}
                            />
                          </div>
                        ))}
                      </div>
                      <div
                        role="tabpanel"
                        className={`tab-pane fade ${
                          activeTab === "expired" ? "active in" : ""
                        }`}
                        id="tab-expired"
                      >
                        {/* Render data for Expired tab */}
                        {expiredData.map((event) => (
                          <div key={event.id} className="item-event">
                            <h2>{event.event_name}</h2>
                            <p>Date: {event.event_date}</p>
                            <p>Date: {event.event_time}</p>
                            <p>Location: {event.place}</p>
                            <p>Description: {event.message}</p>
                            {/* Add image for Expired event */}
                            <img
                              src={event.imageUrl}
                              alt={event.title}
                              style={{ height: 400, width: 400 }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
