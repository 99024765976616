import React from "react";
import styled from "styled-components";
import { Box, Typography, Container, Grid } from "@mui/material";

const Section = styled.section`
  padding: 40px 0;
`;

const Heading = styled(Typography)`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
`;

const SubHeading = styled(Typography)`
  font-size: 1.25rem;
  color: #666;
`;

const CardImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const CardTitle = styled(Typography)`
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 20px;
  font-family: Roboto;
`;

const CardDescription = styled(Typography)`
  font-size: 1.5rem;
  color: #666;
  margin-top: 10px;
`;

const Card = styled(Box)`
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.03);
  }
`;

const CampusLife = () => {
  const campusLifeData = [
    {
      title: "Activities",
      description:
        "To develop a holistic personality with your child we’ve incorporated a number of scholastic activities into the curriculum by providing ample opportunity for the children to express themselves.",
      imageSrc: "../wp-content/uploads/2023/05/Activities-1.jpg",
      alt: "Activities",
    },
    {
      title: "Life Skills",
      description:
        "In today’s ever demanding global situation where knowledge and career skills have to become secondary elements of education, functional skills are skills that are of utmost importance for day to day living.",
      imageSrc: "../wp-content/uploads/2023/05/life-skill.jpg",
      alt: "Life Skills",
    },
    {
      title: "Experiential Learning",
      description:
        "Experiential learning is about altering perspectives and changing mind-sets by taking the learner outside their comfort zone and forcing them to actively engage and reflect on a subject matter.",
      imageSrc: "../wp-content/uploads/2023/05/experimental-learning.jpg",
      alt: "Experiential Learning",
    },
  ];

  return (
    <Section>
      <Container>
        <Heading variant="h2" align="center" fontFamily={"Roboto"}>
          Campus Life
        </Heading>
        <SubHeading fontSize={18} align="center" marginBottom={5}>
          Building a vibrant community of creative and accomplished people
        </SubHeading>
        <Grid container spacing={3} justifyContent="center">
          {campusLifeData.map((data, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card elevation={3}>
                <CardImage src={data.imageSrc} alt={data.alt} />
                <CardTitle variant="h3" sx={{ fontFamily: "Roboto" }}>
                  {data.title}
                </CardTitle>
                <CardDescription sx={{ fontSize: 18 }}>
                  {data.description}
                </CardDescription>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
};

export default CampusLife;
