// import React, { useState, useEffect } from "react";
// import {
//   Container,
//   Typography,
//   Box,
//   styled,
//   Modal,
//   IconButton,
//   Paper,
// } from "@mui/material";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import CloseIcon from "@mui/icons-material/Close";
// import axios from "axios";

// const videoUrls = [
//   "https://www.youtube.com/embed/sN5sBqlczxk",
//   "https://www.youtube.com/embed/sN5sBqlczxk",
//   "https://www.youtube.com/embed/sN5sBqlczxk",
//   "https://www.youtube.com/embed/sN5sBqlczxk",
//   "https://www.youtube.com/embed/sN5sBqlczxk",
//   "https://www.youtube.com/embed/sN5sBqlczxk",
//   "https://www.youtube.com/embed/sN5sBqlczxk",
//   "https://www.youtube.com/embed/sN5sBqlczxk",
//   // Add more video URLs here
// ];

// const useStyles = styled((theme) => ({
//   container: {
//     padding: 0,
//     [theme.breakpoints.up("lg")]: {
//       maxWidth: 600,
//       margin: "0 auto",
//     },
//   },
//   header: {
//     fontWeight: 600,
//     fontSize: "24px",
//     padding: "10px",
//     color: theme.palette.primary.main,
//     textAlign: "center",
//     lineHeight: "1.2em",
//     fontFamily: '"Open Sans"',
//   },
//   slider: {
//     marginTop: theme.spacing(2),
//     marginBottom: theme.spacing(2),
//     textAlign: "center",
//     width: 600, // Set the width to 600
//     height: 600, // Set the height to 600
//   },
//   sliderImage: {
//     width: "100%",
//     height: "auto",
//     borderRadius: "10px",
//     cursor: "pointer", // Add cursor pointer to indicate clickable
//   },
//   modalContent: {
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     maxWidth: "80%",
//     maxHeight: "80%",
//     outline: "none",
//   },
//   closeButton: {
//     position: "absolute",
//     top: "10px",
//     right: "10px",
//     cursor: "pointer",
//   },
//   videoContainer: {
//     position: "relative",
//     cursor: "pointer",
//   },
//   iframe: {
//     width: "600px", // Set the width to 600
//     height: "600px", // Set the height to 600
//   },
// }));

// export default function VideoSlider() {
//   const classes = useStyles();
//   const [selectedVideo, setSelectedVideo] = useState(null);
//   const [video, setVideo] = useState([]);

//   useEffect(() => {
//     fatchData();
//   }, []);
//   const fatchData = async () => {
//     try {
//       const response = await axios.get(
//         "https://lyci0i0cnf.execute-api.ap-south-1.amazonaws.com/sanford/sanford_videos_all_data"
//       );
//       setVideo(response.data["body-json"]["body"]["Items"]);
//       console.log("dataofsandfordGallery", response.data["body-json"]["body"]);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     centerMode: true,
//     centerPadding: "0px",
//     autoplay: true,
//     autoplaySpeed: 2000,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//     ],
//   };

//   const openVideoModal = (videoUrl) => {
//     setSelectedVideo(videoUrl);
//   };

//   const closeVideoModal = () => {
//     setSelectedVideo(null);
//   };

//   return (
//     <Container className={classes.container} maxWidth="lg">
//       <Box
//         sx={{ display: "flex", textAlign: "center", justifyContent: "center" }}
//       >
//         <Typography
//           sx={{
//             color: "green",
//             fontFamily: "Roboto",
//             fontSize: 30,
//           }}
//         >
//           VIDEO
//         </Typography>
//       </Box>

//   <Box className={classes.slider}>
//     <Slider {...settings}>
//       {videoUrls.map((videoUrl, index) => (
//         <div key={index} onClick={() => openVideoModal(videoUrl)}>
//           <div className={classes.videoContainer}>
//             <iframe
//               title={`Video ${index}`}
//               loading="lazy"
//               className={classes.iframe}
//               id="thim-video"
//               width="100%"
//               height="100%"
//               src={videoUrl}
//               allowFullScreen
//               style={{ border: "0" }}
//             />
//           </div>
//         </div>
//       ))}
//     </Slider>
//   </Box>

//       {/* Video Modal */}
//       <Modal open={!!selectedVideo} onClose={closeVideoModal}>
//         <Paper className={classes.modalContent}>
//           <div
//             style={{
//               position: "relative",
//               display: "flex",
//               alignContent: "center",
//               justifyContent: "center",
//             }}
//           >
//             {selectedVideo && (
//               <>
//                 <div className={classes.videoContainer}>
//                   <iframe
//                     title="Selected Video"
//                     loading="lazy"
//                     className={classes.iframe}
//                     id="thim-video"
//                     width="100%"
//                     height="100%"
//                     src={selectedVideo}
//                     allowFullScreen
//                     style={{ border: "0" }}
//                   />
//                 </div>
//                 <IconButton
//                   className={classes.closeButton}
//                   onClick={closeVideoModal}
//                   style={{
//                     position: "absolute",
//                     top: "10px",
//                     right: "10px",
//                     backgroundColor: "yellow", // Add a background color for better visibility
//                   }}
//                 >
//                   <CloseIcon />
//                 </IconButton>
//               </>
//             )}
//           </div>
//         </Paper>
//       </Modal>
//     </Container>
//   );
// }

import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  styled,
  Modal,
  IconButton,
  Paper,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import YouTube from "react-youtube";

const videoUrls = [
  "https://www.youtube.com/embed/sN5sBqlczxk",
  "https://www.youtube.com/embed/sN5sBqlczxk",
  "https://www.youtube.com/embed/sN5sBqlczxk",
  "https://www.youtube.com/embed/sN5sBqlczxk",
  "https://www.youtube.com/embed/sN5sBqlczxk",
  "https://www.youtube.com/embed/sN5sBqlczxk",
  "https://www.youtube.com/embed/sN5sBqlczxk",
  "https://www.youtube.com/embed/5KkHLDkPcq8?si=nW8I_UUiky-vAaOl",
  "https://youtube.com/embed/bQmODwmmZJ4?si=XBfevpH8XfbNPyKN", //https://youtu.be/bQmODwmmZJ4?si=XBfevpH8XfbNPyKN
  // Add more video URLs here
];

const useStyles = styled((theme) => ({
  // ... (your styles)
}));

export default function VideoSlider() {
  const classes = useStyles();
  const [selectedImage, setSelectedImage] = useState(null);
  const [images, setImage] = useState([]);
  const [videoData, setVideoData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://lyci0i0cnf.execute-api.ap-south-1.amazonaws.com/sanford/sanford_videos_all_data"
      );
      setVideoData(response.data["body-json"].body.Items);
      console.log("video data", response.data["body-json"].body.Items);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Display two images at a time
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    autoplay: true,
    autoplaySpeed: 3000, // Autoplay speed in milliseconds
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1, // Change the number of images displayed on smaller screens
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1, // Display one image at a time on even smaller screens
        },
      },
    ],
  };

  return (
    <Container className={classes.container} maxWidth="lg">
      <Box
        sx={{ display: "flex", textAlign: "center", justifyContent: "center" }}
      >
        <Typography
          sx={{
            color: "green",
            fontFamily: "Roboto",
            fontSize: 25,
          }}
        >
          Videos
        </Typography>
      </Box>

      <Box className={classes.slider}>
        <Slider {...settings}>
          {videoData.map((video, index) => (
            <div key={index}>
              <div className={classes.videoContainer}>
                <Paper
                  className={classes.imageCard}
                  sx={{ maxWidth: "100%", height: 400 }}
                >
                  <YouTube
                    videoId={video.youtube_url}
                    opts={{ width: "100%", height: "315" }}
                  />
                </Paper>
              </div>
            </div>
          ))}
        </Slider>
      </Box>
    </Container>
  );
}
