import React from "react";
import Header from "../header/header";
import { Link } from "react-router-dom";
import Footer from "../footer/footer";

function index() {
  return (
    <div>
      <div>
        <Header />
        <div id="wrapper-container" className="wrapper-container">
          <div className="content-pusher">
            <div id="main-content">
              <section className="content-area">
                <div className="top_heading_out">
                  <div
                    className="top_site_main"
                    style={{
                      color: "#ffffff",
                      backgroundImage: `url('../../wp-content/uploads/2023/05/trans.jpg')`,
                    }}
                  >
                    <span
                      className="overlay-top-header"
                      style={{ "background-color": "rgba(0,0,0,0.5)" }}
                    />{" "}
                    <div className="page-title-wrapper">
                      <div className="banner-wrapper container">
                        <h1 className="page-title">Smart Class</h1>{" "}
                      </div>
                    </div>
                  </div>{" "}
                  <div className="breadcrumbs-wrapper">
                    <div className="container">
                      <ul className="breadcrumbs" id="breadcrumbs">
                        <li>
                          <Link to="/">Home </Link>
                        </li>
                        <li>Smart Class</li>
                      </ul>{" "}
                    </div>
                  </div>
                </div>
                <div className="container site-content">
                  <div className="row">
                    <main id="main" className="site-main col-sm-12 full-width">
                      <article
                        id="post-11428"
                        className="post-11428 page type-page status-publish hentry"
                      >
                        <div className="entry-content">
                          <div
                            data-elementor-type="wp-page"
                            data-elementor-id={11428}
                            className="elementor elementor-11428"
                          >
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-36a87de elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="36a87de"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-cb979bc"
                                  data-id="cb979bc"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-379e4ef elementor-widget thim-ekits-heading elementor-widget-thim-heading"
                                      data-id="379e4ef"
                                      data-element_type="widget"
                                      data-widget_type="thim-heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="sc_heading ">
                                          <h3 className="title">Smart Class</h3>
                                          <span className="line" />
                                        </div>{" "}
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-d5a0641 elementor-widget elementor-widget-text-editor"
                                      data-id="d5a0641"
                                      data-element_type="widget"
                                      data-widget_type="text-editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <p>
                                          Smart Class : as an add-on tool is
                                          designed to help teachers in meeting
                                          with new challenges and developing
                                          students abilities and performance. It
                                          helps the teachers to access
                                          multimedia content and information
                                          ,that can be used for teaching
                                          students more effectively. It helps
                                          the teachers in expressing their views
                                          and ensures teacher that every student
                                          is understanding and learning.
                                        </p>{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-4e66117"
                                  data-id="4e66117"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-83505cd elementor-widget elementor-widget-thim-single-images"
                                      data-id="83505cd"
                                      data-element_type="widget"
                                      data-widget_type="thim-single-images.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="single-image template-base">
                                          <img
                                            fetchpriority="high"
                                            width={600}
                                            height={300}
                                            src="../wp-content/uploads/2023/05/smart.jpg"
                                            className="attachment-full size-full"
                                            alt
                                            decoding="async"
                                            srcSet="https://davsanford.com/wp-content/uploads/2023/05/smart.jpg 600w, https://davsanford.com/wp-content/uploads/2023/05/smart-300x150.jpg 300w"
                                            sizes="(max-width: 600px) 100vw, 600px"
                                          />
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-33f3424 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="33f3424"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-43839cf"
                                  data-id="43839cf"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-29b0135 elementor-widget elementor-widget-text-editor"
                                      data-id="29b0135"
                                      data-element_type="widget"
                                      data-widget_type="text-editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <p>
                                          Smart Class helps the students in
                                          understanding the concepts. A well
                                          designed module allows a student to
                                          visualise the concept much better than
                                          static images All our schools are
                                          equipped with state of the art
                                          solutions from our smart class service
                                          providers .
                                        </p>{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-b35c278 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="b35c278"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-fff6dfb"
                                  data-id="fff6dfb"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-2646d74 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                                      data-id="2646d74"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                        {/* .entry-content */}
                      </article>
                      {/* #post-## */}
                    </main>
                  </div>
                </div>
              </section>
              <Footer />

              {/* #colophon */}
            </div>
            {/*end main-content*/}
          </div>
          {/* end content-pusher*/}
        </div>
        {/* end wrapper-container */}
      </div>
    </div>
  );
}

export default index;
