import React from "react";
import Footer from "../footer/footer";
import { Link } from "react-router-dom";
import Header from "../header/header";

function index() {
  return (
    <div>
      <div>
        <Header />
        <div id="wrapper-container" className="wrapper-container">
          <div className="content-pusher">
            <div id="main-content">
              <section className="content-area">
                <div className="top_heading_out">
                  <div
                    className="top_site_main"
                    style={{
                      color: "#ffffff",
                      backgroundImage: `url('../../wp-content/uploads/2023/05/trans.jpg')`,
                    }}
                  >
                    <span
                      className="overlay-top-header"
                      style={{ "background-color": "rgba(0,0,0,0.5)" }}
                    />{" "}
                    <div className="page-title-wrapper">
                      <div className="banner-wrapper container">
                        <h1 className="page-title">Horse Riding</h1>{" "}
                      </div>
                    </div>
                  </div>{" "}
                  <div className="breadcrumbs-wrapper">
                    <div className="container">
                      <ul className="breadcrumbs" id="breadcrumbs">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>Horse Riding</li>
                      </ul>{" "}
                    </div>
                  </div>
                </div>
                <div className="container site-content">
                  <div className="row">
                    <main id="main" className="site-main col-sm-12 full-width">
                      <article
                        id="post-11790"
                        className="post-11790 page type-page status-publish hentry"
                      >
                        <div className="entry-content">
                          <div
                            data-elementor-type="wp-page"
                            data-elementor-id={11790}
                            className="elementor elementor-11790"
                          >
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-9a989f8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="9a989f8"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2f1eb98"
                                  data-id="2f1eb98"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-2c83c79 elementor-widget elementor-widget-heading"
                                      data-id="2c83c79"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h2 className="elementor-heading-title elementor-size-default">
                                          Horse Riding
                                        </h2>{" "}
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-1e4670c elementor-widget elementor-widget-text-editor"
                                      data-id="1e4670c"
                                      data-element_type="widget"
                                      data-widget_type="text-editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <p>
                                          Horse riding is an excellent answer in
                                          choosing a sport. Today anybody can be
                                          a horse rider, whether you like to
                                          ride for fun or of a competitive
                                          nature. There is somthing for everyone
                                          at{" "}
                                          <strong>
                                            D.A.V Sanford Public school
                                          </strong>
                                          . We teach you how to get started
                                          here.
                                        </p>
                                        <div className="vc_empty_space">
                                          &nbsp;
                                        </div>
                                        <div className="wpb_text_column wpb_content_element wpb_animate_when_almost_visible wpb_left-to-right left-to-right vc_custom_1448960193208 wpb_start_animation animated">
                                          <div className="wpb_wrapper">
                                            <p>
                                              We offer horse training, riding
                                              lessons, farrier service and
                                              consultation as well as
                                              therapeutic horseback riding
                                              lessons.
                                            </p>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-f14291a elementor-widget elementor-widget-image"
                                      data-id="f14291a"
                                      data-element_type="widget"
                                      data-widget_type="image.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <img
                                          decoding="async"
                                          fetchpriority="high"
                                          width={640}
                                          height={539}
                                          src="../wp-content/uploads/2023/05/tipu-sultan-horse-riding-club-bala-nagar-hyderabad-equestrian-centres-t0j4fteo2d.jpg"
                                          className="attachment-large size-large wp-image-11795"
                                          alt
                                          srcSet="https://davsanford.com/wp-content/uploads/2023/05/tipu-sultan-horse-riding-club-bala-nagar-hyderabad-equestrian-centres-t0j4fteo2d.jpg 670w, https://davsanford.com/wp-content/uploads/2023/05/tipu-sultan-horse-riding-club-bala-nagar-hyderabad-equestrian-centres-t0j4fteo2d-300x253.jpg 300w, https://davsanford.com/wp-content/uploads/2023/05/tipu-sultan-horse-riding-club-bala-nagar-hyderabad-equestrian-centres-t0j4fteo2d-600x505.jpg 600w"
                                          sizes="(max-width: 640px) 100vw, 640px"
                                        />{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-e550c39 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="e550c39"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-46cd9a4"
                                  data-id="46cd9a4"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-d49e929 elementor-widget elementor-widget-text-editor"
                                      data-id="d49e929"
                                      data-element_type="widget"
                                      data-widget_type="text-editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <p>
                                          Horseback riding is a physically
                                          demanding sport which helps riders
                                          develop their core strength, full body
                                          balance, gait and posture. In addition
                                          to this, horse riding helps one
                                          develop a great sense of discipline,
                                          empathy, compassion, self-control, and
                                          dedication, all while also helping
                                          with improving overall confidence and
                                          problem-solving skills. More than
                                          anything, horse riding is fun.
                                        </p>{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-6928019 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id={6928019}
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f914aa4"
                                  data-id="f914aa4"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-62f5b05 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                                      data-id="62f5b05"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                        {/* .entry-content */}
                      </article>
                      {/* #post-## */}
                    </main>
                  </div>
                </div>
              </section>
              <Footer />

              {/* #colophon */}
            </div>
            {/*end main-content*/}
          </div>
          {/* end content-pusher*/}
        </div>
        {/* end wrapper-container */}
      </div>
    </div>
  );
}

export default index;
