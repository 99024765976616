import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  styled,
} from "@mui/material";
import { Link } from "react-router-dom";
import Header from "../header/header";
import Footer from "../footer/footer";
import Demo from "../Demo";
import axios from "axios";

const GreenHoverButton = styled(Button)({
  "&:hover": {
    backgroundColor: "green", // Change this to your desired hover color
  },
});

const FacilityPage = () => {
  const [images, setImage] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://lyci0i0cnf.execute-api.ap-south-1.amazonaws.com/sanford/sanford_facility_all_data"
      );
      setImage(response.data["body-json"]["body"]["Items"]);
      console.log("dataofsandfordGallery", response.data["body-json"]["body"]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const truncateDescription = (description, maxLength) => {
    if (description.length > maxLength) {
      return description.substring(0, maxLength) + " ...";
    }
    return description;
  };

  const [expandedDescriptions, setExpandedDescriptions] = useState({});

  const toggleDescription = (facilityId) => {
    setExpandedDescriptions((prevState) => ({
      ...prevState,
      [facilityId]: !prevState[facilityId],
    }));
  };

  return (
    <>
      <Header />
      <Container sx={{ marginTop: 20, marginBottom: 5 }}>
        <Typography
          variant="h3"
          fontFamily={"Roboto"}
          component="div"
          align="center"
          gutterBottom
        >
          School Facilities
        </Typography>
        <Grid container spacing={2}>
          {images.map((facility, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ width: 350 }}>
                <img
                  src={facility.imageUrl}
                  alt={facility.title}
                  style={{ width: 350, height: 200 }}
                />
                <CardContent>
                  <Typography
                    variant="h4"
                    fontFamily={"Roboto"}
                    component="div"
                  >
                    {facility.title}
                  </Typography>
                  {facility.description.length > 200 ? (
                    <>
                      <Typography
                        fontSize={15}
                        color="text.secondary"
                        sx={{
                          maxHeight: expandedDescriptions[facility.id]
                            ? "none"
                            : 60, // Adjust the maximum height as needed
                          overflow: "hidden",
                        }}
                      >
                        {truncateDescription(facility.description, 200)}
                      </Typography>
                      <GreenHoverButton
                        variant="text"
                        sx={{ color: "white", marginTop: 2 }}
                        onClick={() => toggleDescription(facility.id)}
                      >
                        {expandedDescriptions[facility.id]
                          ? "Read Less"
                          : "Read More"}
                      </GreenHoverButton>
                    </>
                  ) : (
                    <Typography fontSize={15} color="text.secondary">
                      {facility.description}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Demo />
      </Container>
      <Footer />
    </>
  );
};

export default FacilityPage;
