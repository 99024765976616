import React from "react";
import Header from "../header/header";

import Footer from "../footer/footer";
import { Link } from "react-router-dom";
import EnquireForm from "./EnqueryForm";
import Demo from "../Demo";

function index() {
  return (
    <div>
      <>
        <Header />

        <div id="wrapper-container" className="wrapper-container">
          <div className="content-pusher">
            <div id="main-content">
              <section className="content-area">
                <div className="top_heading_out">
                  <div
                    className="top_site_main"
                    style={{
                      backgroundImage: `url('../../wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-350x350.jpeg')`,
                    }}
                  >
                    <span
                      className="overlay-top-header"
                      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
                    />{" "}
                    <div className="page-title-wrapper">
                      <div className="banner-wrapper container">
                        <h1 className="page-title">Enquiry</h1>{" "}
                      </div>
                    </div>
                  </div>{" "}
                  <div className="breadcrumbs-wrapper">
                    <div className="container">
                      <ul className="breadcrumbs" id="breadcrumbs">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>Enquiry</li>
                      </ul>{" "}
                    </div>
                  </div>
                </div>
                <Demo />
                <div className="container site-content">
                  <div className="row">
                    <main id="main" className="site-main col-sm-12 full-width">
                      <article
                        id="post-11262"
                        className="post-11262 page type-page status-publish hentry"
                      >
                        <div className="entry-content">
                          <div
                            data-elementor-type="wp-page"
                            data-elementor-id={11262}
                            className="elementor elementor-11262"
                          >
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-6e5571d9 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="6e5571d9"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6d64a926"
                                  data-id="6d64a926"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-35f1c6a elementor-widget thim-ekits-heading elementor-widget-thim-heading"
                                      data-id="35f1c6a"
                                      data-element_type="widget"
                                      data-widget_type="thim-heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="sc_heading ">
                                          <h3 className="title">
                                            Enquiry Form
                                          </h3>
                                        </div>{" "}
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-103e9f66 elementor-widget elementor-widget-text-editor"
                                      data-id="103e9f66"
                                      data-element_type="widget"
                                      data-widget_type="text-editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <EnquireForm />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                        {/* .entry-content */}
                      </article>
                      {/* #post-## */}
                    </main>
                  </div>
                </div>
              </section>

              {/* #colophon */}
            </div>
            {/*end main-content*/}
          </div>
        </div>

        <Footer />
      </>
    </div>
  );
}

export default index;
