import React from "react";
import Header from "../header/header";
import { Link } from "react-router-dom";
import Footer from "../footer/footer";
import BusImages from "./images/schoolbus.jpg";
function index() {
  return (
    <div>
      <div>
        <Header />
        <div id="wrapper-container" className="wrapper-container">
          <div className="content-pusher">
            <div id="main-content">
              <section className="content-area">
                <div className="top_heading_out">
                  <div
                    className="top_site_main"
                    style={{
                      backgroundImage: `url('../../../wp-content/uploads/2015/11/team-5.jpg')`,
                    }}
                  >
                    <span
                      className="overlay-top-header"
                      style={{ "background-color": "rgba(0,0,0,0.5)" }}
                    />{" "}
                    <div className="page-title-wrapper">
                      <div className="banner-wrapper container">
                        <h1 className="page-title">Transportation</h1>{" "}
                      </div>
                    </div>
                  </div>{" "}
                  <div className="breadcrumbs-wrapper">
                    <div className="container">
                      <ul className="breadcrumbs" id="breadcrumbs">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>Transportation</li>
                      </ul>{" "}
                    </div>
                  </div>
                </div>
                <div className="container site-content">
                  <div className="row">
                    <main id="main" className="site-main col-sm-12 full-width">
                      <article
                        id="post-11315"
                        className="post-11315 page type-page status-publish hentry"
                      >
                        <div className="entry-content">
                          <div
                            data-elementor-type="wp-page"
                            data-elementor-id={11315}
                            className="elementor elementor-11315"
                          >
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-04c09ba elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="04c09ba"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-da0964c"
                                  data-id="da0964c"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-0f3df84 elementor-widget elementor-widget-heading"
                                      data-id="0f3df84"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h2 className="elementor-heading-title elementor-size-default">
                                          Transportation
                                        </h2>{" "}
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-2ebc326 elementor-widget elementor-widget-text-editor"
                                      data-id="2ebc326"
                                      data-element_type="widget"
                                      data-widget_type="text-editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <p>
                                          Well furnished buses ply to cater for
                                          the transportation needs of all the
                                          students. All the transportation is
                                          managed by institutes concerned to
                                          take utmost care of comfort and safety
                                          of students
                                        </p>{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-8a923ea"
                                  data-id="8a923ea"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-ab42b1a elementor-widget elementor-widget-image"
                                      data-id="ab42b1a"
                                      data-element_type="widget"
                                      data-widget_type="image.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <img
                                          decoding="async"
                                          fetchpriority="high"
                                          width={640}
                                          height={427}
                                          src={BusImages}
                                          className="attachment-large size-large wp-image-11471"
                                          alt
                                          sizes="(max-width: 640px) 100vw, 640px"
                                        />{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-6cedca8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="6cedca8"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-75ef5cd"
                                  data-id="75ef5cd"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-b0fc0c0 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                                      data-id="b0fc0c0"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                        {/* .entry-content */}
                      </article>
                      {/* #post-## */}
                    </main>
                  </div>
                </div>
              </section>
              <Footer />

              {/* #colophon */}
            </div>
            {/*end main-content*/}
          </div>
          {/* end content-pusher*/}
        </div>
        {/* end wrapper-container */}
      </div>
    </div>
  );
}

export default index;
