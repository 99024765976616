import React from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import { Link } from "react-router-dom";

import ContactForm from "./ContactForm";
import Demo from "../Demo";

function index() {
  return (
    <div>
      {" "}
      <>
        <Header />

        <div id="wrapper-container" className="wrapper-container">
          <div className="content-pusher">
            <div id="main-content">
              <section className="content-area">
                <div className="top_heading_out">
                  <div
                    className="top_site_main"
                    style={{
                      color: "#ffffff",
                      backgroundImage:
                        "url(../../davsanford.cdn-pi.com/wp-content/uploads/2022/04/topheading-blog.jpeg)",
                    }}
                  >
                    <span
                      className="overlay-top-header"
                      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
                    />{" "}
                    <div className="page-title-wrapper">
                      <div className="banner-wrapper container">
                        <h1 className="page-title">Contact</h1>{" "}
                      </div>
                    </div>
                  </div>{" "}
                  <div className="breadcrumbs-wrapper">
                    <div className="container">
                      <ul className="breadcrumbs" id="breadcrumbs">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>Contact</li>
                      </ul>{" "}
                    </div>
                  </div>
                </div>

                <div className="container site-content">
                  <ContactForm />
                  <div className="row">
                    <main id="main" className="site-main col-sm-12 full-width">
                      <article
                        id="post-4526"
                        className="post-4526 page type-page status-publish hentry"
                      >
                        <div className="entry-content">
                          <div
                            data-elementor-type="wp-post"
                            data-elementor-id={4526}
                            className="elementor elementor-4526"
                          >
                            <Demo />
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-3e13ad1 elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="3e13ad1"
                              data-element_type="section"
                              data-settings='{"stretch_section":"section-stretched"}'
                            >
                              <div className="elementor-container elementor-column-gap-custom">
                                <div
                                  className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-f25b691"
                                  data-id="f25b691"
                                  data-element_type="column"
                                  data-settings='{"background_background":"classic"}'
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-c6f58bd iconbox-text-center elementor-widget elementor-widget-thim-icon-box"
                                      data-id="c6f58bd"
                                      data-element_type="widget"
                                      data-widget_type="thim-icon-box.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="thim-widget-icon-box">
                                          <div className="wrapper-box-icon text-center ">
                                            <div className="smicon-box iconbox-top">
                                              <div className="boxes-icon">
                                                <span className="inner-icon">
                                                  <span className="icon">
                                                    <i
                                                      aria-hidden="true"
                                                      className="tk tk-pin1"
                                                    />
                                                  </span>
                                                </span>
                                              </div>
                                              <div className="content-inner">
                                                <div className="sc-heading">
                                                  <p className="heading__primary">
                                                    Address way
                                                  </p>
                                                </div>
                                                <div className="desc-icon-box">
                                                  <div className="desc-content">
                                                    Tati Jhariya, Hazaribagh,
                                                    Jharkhand <br />
                                                    825313
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-7581677"
                                  data-id={7581677}
                                  data-element_type="column"
                                  data-settings='{"background_background":"classic"}'
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-a66d183 iconbox-text-center elementor-widget elementor-widget-thim-icon-box"
                                      data-id="a66d183"
                                      data-element_type="widget"
                                      data-widget_type="thim-icon-box.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="thim-widget-icon-box">
                                          <div className="wrapper-box-icon text-center ">
                                            <div className="smicon-box iconbox-top">
                                              <div className="boxes-icon">
                                                <span className="inner-icon">
                                                  <span className="icon">
                                                    <i
                                                      aria-hidden="true"
                                                      className="tk tk-teacher"
                                                    />
                                                  </span>
                                                </span>
                                              </div>
                                              <div className="content-inner">
                                                <div className="sc-heading">
                                                  <p className="heading__primary">
                                                    Contact info
                                                  </p>
                                                </div>
                                                <div className="desc-icon-box">
                                                  <div className="desc-content">
                                                    Mobile: (+91) - 8757-327-770{" "}
                                                    <br />
                                                    EMail:
                                                    davsanford777@gmail.com
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-77905de"
                                  data-id="77905de"
                                  data-element_type="column"
                                  data-settings='{"background_background":"classic"}'
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-578317a iconbox-text-center elementor-widget elementor-widget-thim-icon-box"
                                      data-id="578317a"
                                      data-element_type="widget"
                                      data-widget_type="thim-icon-box.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="thim-widget-icon-box">
                                          <div className="wrapper-box-icon text-center ">
                                            <div className="smicon-box iconbox-top">
                                              <div className="boxes-icon">
                                                <span className="inner-icon">
                                                  <span className="icon">
                                                    <i
                                                      aria-hidden="true"
                                                      className="tk tk-clock1"
                                                    />
                                                  </span>
                                                </span>
                                              </div>
                                              <div className="content-inner">
                                                <div className="sc-heading">
                                                  <p className="heading__primary">
                                                    Work timer
                                                  </p>
                                                </div>
                                                <div className="desc-icon-box">
                                                  <div className="desc-content">
                                                    Monday - Friday: 08:00 -
                                                    14:00 <br />
                                                    Saturday: 08:00 - 12:00
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>

                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-9e3e8fb elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="9e3e8fb"
                              data-element_type="section"
                              data-settings='{"stretch_section":"section-stretched"}'
                            >
                              <div className="elementor-container elementor-column-gap-no">
                                <div
                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9e34713"
                                  data-id="9e34713"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-052e901 elementor-widget elementor-widget-google_maps"
                                      data-id="052e901"
                                      data-element_type="widget"
                                      data-widget_type="google_maps.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-custom-embed">
                                          <iframe
                                            loading="lazy"
                                            src="https://maps.google.com/maps?q=D.A.V%20sanford%20public%20school%2C%20Tati%20Jhariya%2C%20Jharkhand%20825313&t=m&z=12&output=embed&iwloc=near"
                                            title="D.A.V sanford public school, Tati Jhariya, Jharkhand 825313"
                                            aria-label="D.A.V sanford public school, Tati Jhariya, Jharkhand 825313"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                        {/* .entry-content */}
                      </article>
                      {/* #post-## */}
                    </main>
                  </div>
                </div>
              </section>

              <Footer />

              {/* #colophon */}
            </div>
            {/*end main-content*/}
          </div>
          {/* end content-pusher*/}
          <a href="#" id="back-to-top">
            <i className="fa fa-chevron-up" aria-hidden="true" />
          </a>
        </div>
        {/* end wrapper-container */}
      </>
    </div>
  );
}

export default index;
