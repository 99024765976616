import { Navigate, useRoutes } from "react-router-dom";

import HomePage from "./pages/index";
import AboutPage from "./pages/about/about";
import AboutDAV from "./pages/about-d-a-v-sanford/index";
import Acadmic from "./pages/academics/acadmic";
import Facility from "./pages/facility/index";
import Contact from "./pages/contact/index";
import Enquery from "./pages/enquiry/index";
import Careear from "./pages/careers/index";
import Gallery from "./pages/gallery/index";
import Blog from "./pages/blog/index";
import Event from "./pages/events/build-education-website-using-wordpress/index";
import EventAll from "./pages/events/index";
import Principl from "./pages/principals-message/index";
import Mission from "./pages/vision-mission/index";
import Caourse from "./pages/syllabus/index";
import Transportation from "./pages/transportation/index";
import Artcarft from "./pages/art-craft/index";
import News from "./pages/why-you-should-read-every-day/index";
import Ways from "./pages/ways-to-improve-the-social-skills-of-your-children-during-the-school-holidays/index";
import Categry from "./pages/category/student-corner/index";
import WhyCBSE from "./pages/why-choose-cbse-board-for-your-child/index";
import HorseRiding from "./pages/horse-riding/index";
import Sport from "./pages/sport/index";

import Admission from "./pages/admission/index";
import SchoolLife from "./pages/category/school-life/index";
import Swimming from "./pages/swimming-class-2/m/swimming-class-2/academicsDetail";
import Smart from "./pages/smart-class/index";
import Computer from "./pages/computer-lab/index";

import Map from "./pages/map";

export default function Router() {
  const routes = useRoutes([
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/about",
      element: <AboutPage />,
    },
    {
      path: "/aboutSandford",
      element: <AboutDAV />,
    },
    {
      path: "/acadmic",
      element: <Acadmic />,
    },
    {
      path: "/map",
      element: <Map />,
    },
    {
      path: "/index",
      element: <Facility />,
    },
    {
      path: "/admision",
      element: <Admission />,
    },
    {
      path: "/Contact",
      element: <Contact />,
    },
    {
      path: "/Enq",
      element: <Enquery />,
    },
    {
      path: "/Car",
      element: <Careear />,
    },
    {
      path: "/Gallery",
      element: <Gallery />,
    },
    {
      path: "/Latest",
      element: <Blog />,
    },
    {
      path: "/event",
      element: <Event />,
    },
    {
      path: "/EventAll",
      element: <EventAll />,
    },
    {
      path: "/Principal",
      element: <Principl />,
    },
    {
      path: "/mission",
      element: <Mission />,
    },
    {
      path: "/Caourse",
      element: <Caourse />,
    },
    {
      path: "/transport",
      element: <Transportation />,
    },
    {
      path: "/art",
      element: <Artcarft />,
    },
    {
      path: "/WhyYou",
      element: <News />,
    },
    {
      path: "/waysTo",
      element: <Ways />,
    },
    {
      path: "/stdcorn",
      element: <Categry />,
    },
    {
      path: "/WhyCbse",
      element: <WhyCBSE />,
    },
    {
      path: "/SchoolLife",
      element: <SchoolLife />,
    },
    {
      path: "/HorseRiding",
      element: <HorseRiding />,
    },
    {
      path: "/Swimming",
      element: <Swimming />,
    },
    {
      path: "/Smart",
      element: <Smart />,
    },

    {
      path: "/Sport",
      element: <Sport />,
    },

    {
      path: "/Computer",
      element: <Computer />,
    },
    // {
    //   path: "/Swimming",
    //   element: <Swimming />,
    // },
  ]);

  return routes;
}
