import React from "react";
import Header from "../header/header";
import Footer from "../footer/footer";

function index() {
  return (
    <div>
      <>
        <Header />
        <div id="wrapper-container" className="wrapper-container">
          <div className="content-pusher">
            <div id="main-content">
              <section className="content-area">
                <div className="top_heading_out">
                  <div
                    className="top_site_main"
                    style={{
                      color: "#ffffff",
                      backgroundImage:
                        "url(../../davsanford.cdn-pi.com/wp-content/uploads/2022/04/topheading-blog.jpeg)",
                    }}
                  >
                    <span
                      className="overlay-top-header"
                      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
                    />{" "}
                    <div className="page-title-wrapper">
                      <div className="banner-wrapper container">
                        <h1 className="page-title">Computer Lab</h1>{" "}
                      </div>
                    </div>
                  </div>{" "}
                  <div className="breadcrumbs-wrapper">
                    <div className="container">
                      <ul className="breadcrumbs" id="breadcrumbs">
                        <li>
                          <a href="../index.html">Home</a>
                        </li>
                        <li>Computer Lab</li>
                      </ul>{" "}
                    </div>
                  </div>
                </div>
                <div className="container site-content">
                  <div className="row">
                    <main id="main" className="site-main col-sm-12 full-width">
                      <article
                        id="post-11307"
                        className="post-11307 page type-page status-publish hentry"
                      >
                        <div className="entry-content">
                          <div
                            data-elementor-type="wp-page"
                            data-elementor-id={11307}
                            className="elementor elementor-11307"
                          >
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-4c1161b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="4c1161b"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-8154b3e"
                                  data-id="8154b3e"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-4383844 elementor-widget elementor-widget-heading"
                                      data-id={4383844}
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h2 className="elementor-heading-title elementor-size-default">
                                          Computer Lab
                                        </h2>{" "}
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-f0a1b12 elementor-widget elementor-widget-text-editor"
                                      data-id="f0a1b12"
                                      data-element_type="widget"
                                      data-widget_type="text-editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <p>
                                          Computer education is an integral part
                                          of modern education system. Our School
                                          also emphasize on computer education
                                          in accordance with C.B.S.E curriculum.
                                          We have well equipped computer lab at
                                          our school. All the computers have
                                          Windows based systems with office
                                          suites and programming languages
                                          installed on to them.
                                        </p>
                                        <p>
                                          All the computers are with UPS backup
                                          which in turn are backed up by
                                          inverters and Gensets for
                                          uninterrupted power. The school
                                          computer laboratory is also connected
                                          with high speed broad band connection
                                          for internet surfing under teacher
                                          surveillance .The school boasts of
                                          sophisticated multimedia computer lab
                                          one each primary and senior segments,
                                          to expose the child to rudiments of
                                          computer environment at a young age.
                                          This center provides invaluable skills
                                          and knowledge essential to the world
                                          of information technology.
                                        </p>{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-b957f32"
                                  data-id="b957f32"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-114e882 elementor-widget elementor-widget-image"
                                      data-id="114e882"
                                      data-element_type="widget"
                                      data-widget_type="image.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <img
                                          decoding="async"
                                          fetchpriority="high"
                                          width={640}
                                          height={480}
                                          src="../wp-content/uploads/2023/05/gallery-7.jpg"
                                          className="attachment-large size-large wp-image-11372"
                                          alt=""
                                          srcSet="https://davsanford.com/wp-content/uploads/2023/05/gallery-7.jpg 800w, https://davsanford.com/wp-content/uploads/2023/05/gallery-7-300x225.jpg 300w, https://davsanford.com/wp-content/uploads/2023/05/gallery-7-768x576.jpg 768w, https://davsanford.com/wp-content/uploads/2023/05/gallery-7-400x300.jpg 400w, https://davsanford.com/wp-content/uploads/2023/05/gallery-7-600x450.jpg 600w"
                                          sizes="(max-width: 640px) 100vw, 640px"
                                        />{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-44c500b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="44c500b"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-19d46af"
                                  data-id="19d46af"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-7299bfb elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                                      data-id="7299bfb"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                        {/* .entry-content */}
                      </article>
                      {/* #post-## */}
                    </main>
                  </div>
                </div>
              </section>
              <Footer />

              {/* #colophon */}
            </div>
            {/*end main-content*/}
          </div>
          {/* end content-pusher*/}
        </div>
        {/* end wrapper-container */}

        <div className="gallery-slider-content" />
        {/* Call Now Button 1.4.1 (https://callnowbutton.com) [renderer:modern]*/}
      </>
    </div>
  );
}

export default index;
