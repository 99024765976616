import React, { useState } from "react";
import { Box, Typography, Button, TextField, Grid } from "@mui/material";
import axios from "axios";
import { styled } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HeroImg from "../../wp-content/uploads/2023/05/com.jpg";

const CustomBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  backgroundColor: "white",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
}));

const CotactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setNumber] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const values = JSON.stringify({
      name: name,
      email: email,
      message: message,
      phone: phone,
    });

    try {
      const response = await axios.post(
        "https://lyci0i0cnf.execute-api.ap-south-1.amazonaws.com/sanford/sanford_contact_us",
        values
      );

      if (response.data.statusCode === 200) {
        toast.success(
          response.data.body.message || "Enquiry submitted successfully!"
        );
      } else {
        toast.error("Error submitting form. Please try again later.");
      }
    } catch (error) {
      toast.error("Error submitting form. Please try again later.");
    }

    setName("");
    setEmail("");
    setNumber("");
    setMessage("");
  };

  return (
    <Box>
      <CustomBox>
        <CustomBox sx={{ flex: "1", marginTop: 5, padding: 5 }}>
          <Grid container direction="column" sx={{ height: "100%" }}>
            <Grid item xs={12} md={4}>
              <Box sx={{ p: 2 }}>
                <CustomBox className="flex  justify-center  ">
                  <Typography
                    variant="h4"
                    align="center"
                    mb={2}
                    fontFamily={"Roboto"}
                    fontWeight={600}
                  >
                    Contact Us
                  </Typography>
                </CustomBox>
                <CustomBox className="flex  justify-center  ">
                  <Typography fontSize={15}>
                    Get in touch today to step into your dream
                  </Typography>
                </CustomBox>
                <form onSubmit={handleSubmit}>
                  <Grid direction="column">
                    <Grid item xs={12} md={8} fontSize={15}>
                      <TextField
                        fullWidth
                        label="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        margin="normal"
                        required
                        InputProps={{
                          sx: { fontSize: 15 }, // Set font size to 15 for input
                        }}
                        InputLabelProps={{
                          sx: { fontSize: 15 }, // Set font size to 15 for label
                        }}
                      />
                      <TextField
                        fullWidth
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        margin="normal"
                        required
                        type="email"
                        InputProps={{
                          sx: { fontSize: 15 }, // Set font size to 15 for input
                        }}
                        InputLabelProps={{
                          sx: { fontSize: 15 }, // Set font size to 15 for label
                        }}
                      />
                      <TextField
                        fullWidth
                        label="Phone Number"
                        value={phone}
                        onChange={(e) => setNumber(e.target.value)}
                        margin="normal"
                        required
                        type="number"
                        InputProps={{
                          sx: { fontSize: 15 }, // Set font size to 15 for input
                        }}
                        InputLabelProps={{
                          sx: { fontSize: 15 }, // Set font size to 15 for label
                        }}
                      />

                      <TextField
                        fullWidth
                        label="Message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        margin="normal"
                        required
                        multiline
                        rows={4}
                        InputProps={{
                          sx: { fontSize: 15 }, // Set font size to 15 for input
                        }}
                        InputLabelProps={{
                          sx: { fontSize: 15 }, // Set font size to 15 for label
                        }}
                      />
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{ fontFamily: 15 }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
          </Grid>
        </CustomBox>
      </CustomBox>
      <ToastContainer
        style={{ left: "50%", right: "auto", transform: "translateX(-50%)" }}
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={true}
      />
    </Box>
  );
};

export default CotactForm;
