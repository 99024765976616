import React from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import { Link } from "react-router-dom";

function index() {
  return (
    <div>
      <div>
        <Header />
        <div id="wrapper-container" className="wrapper-container">
          <div className="content-pusher">
            <div id="main-content">
              <section className="content-area">
                <div className="top_heading_out">
                  <div
                    className="top_site_main"
                    style={{
                      color: "#ffffff",
                      backgroundImage: `url('../../wp-content/uploads/2023/05/trans.jpg')`,
                    }}
                  >
                    <span
                      className="overlay-top-header"
                      style={{ "background-color": "rgba(0,0,0,0.5)" }}
                    />{" "}
                    <div className="page-title-wrapper">
                      <div className="banner-wrapper container">
                        <h2 className="page-title">School life</h2>{" "}
                      </div>
                    </div>
                  </div>{" "}
                  <div className="breadcrumbs-wrapper">
                    <div className="container">
                      <ul className="breadcrumbs" id="breadcrumbs">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="/Latest">Blog</Link>
                        </li>
                        <li>
                          {/* <a href="../category/school-life/index.html">
                            School life
                          </a> */}
                          <Link to="/SchoolLife"> School life</Link>
                        </li>
                        <li>Why choose CBSE Board for your child?</li>
                      </ul>{" "}
                    </div>
                  </div>
                </div>
                <div className="container sidebar-right site-content">
                  <div className="row">
                    <main id="main" className="site-main col-sm-9 alignleft">
                      <div className="page-content">
                        <article
                          id="post-11988"
                          className="post-11988 post type-post status-publish format-standard has-post-thumbnail hentry category-school-life"
                        >
                          <div className="page-content-inner">
                            <div className="post-formats-wrapper">
                              <img
                                width={1024}
                                height={683}
                                src="../wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1.jpg"
                                className="attachment-full size-full wp-post-image"
                                alt
                                decoding="async"
                                fetchpriority="high"
                                srcSet="https://davsanford.com/wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1.jpeg 1024w, https://davsanford.com/wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-300x200.jpeg 300w, https://davsanford.com/wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-768x512.jpeg 768w, https://davsanford.com/wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-600x400.jpeg 600w"
                                sizes="(max-width: 1024px) 100vw, 1024px"
                              />
                            </div>{" "}
                            <header className="entry-header">
                              <h1 className="entry-title">
                                Why choose CBSE Board for your child?
                              </h1>{" "}
                              <ul className="entry-meta">
                                <li className="author">
                                  <span>Posted by</span>
                                  <span className="vcard author author_name">
                                    <a href="../author/sitemanager/index.html">
                                      sitemanager
                                    </a>
                                  </span>{" "}
                                </li>
                                <li className="entry-category">
                                  <span>Categories</span>{" "}
                                  <a
                                    href="../category/school-life/index.html"
                                    rel="category tag"
                                  >
                                    School life
                                  </a>{" "}
                                </li>
                                <li className="entry-date">
                                  <span>Date</span>
                                  <span className="value"> June 7, 2023</span>
                                </li>
                                <li className="comment-total">
                                  <span>Comments</span>
                                  <a href="index.html#respond">
                                    0 comment
                                  </a>{" "}
                                </li>
                              </ul>
                            </header>
                            {/* .entry-header */}
                            <div className="entry-content">
                              <p>
                                The world is evolving. Nowadays, compared to
                                earlier times, there is too much competition all
                                around us. In such a scenario choosing the
                                finest school and the best education board for
                                our children is a major concern for parents.
                                Schools are the institutions that lay the
                                foundation work; hence it is crucial for parents
                                to select the best school for their child with
                                the best education board.&nbsp;
                              </p>
                              <p>
                                Several boards are most associated with schools
                                in Hazaribagh such as ICSE, IB, IGCSE, State
                                Board and the&nbsp;
                                <strong>
                                  Central Board of Secondary Education
                                </strong>
                                &nbsp;(<strong>CBSE)</strong>. There are many
                                schools with a variety of curricula to select
                                from. It is but natural in this circumstance to
                                feel bewildered about the many board curricula
                                offered in India.&nbsp;
                              </p>
                              <p>
                                As a parent, you must be wondering seriously
                                which Board curriculum would be ideal for your
                                child in the current environment. Well in this
                                space, I would like to share some essential
                                information about the benefits of sending your
                                child to a&nbsp;<strong>CBSE&nbsp;</strong>
                                school.
                              </p>
                              <p>
                                <strong>CBSE</strong>&nbsp;is an Indian
                                national-level education board for private and
                                public schools. It is the most popular school
                                board, with a maximum number of{" "}
                                <a
                                  rel="noreferrer noopener"
                                  href="https://www.prudenceschools.com/cbse-school-delhi-ncr.php"
                                  target="_blank"
                                >
                                  CBSE Schools in{" "}
                                </a>
                                Hazaribagh being associated with it.
                              </p>
                              <p>
                                <strong>Some benefits of CBSE:</strong>
                              </p>
                              <p>
                                <strong>
                                  The CBSE curriculum is dynamic and constantly
                                  evolving.
                                </strong>
                                &nbsp;The curriculum is the most child friendly
                                and is designed to provide extensive subject
                                matter knowledge to students. It has been
                                changing with time, progressing to the present.
                                A curriculum needs ongoing monitoring and
                                evaluation to be successful. The educational
                                activities and services provided by the CBSE
                                curriculum have been evolving to fit the demands
                                of a contemporary and vibrant community. Over
                                the past few years, the CBSE board has undergone
                                a considerable adjustment to its prescribed
                                teaching methodology. Instead of one-way
                                education, there is a lot of emphasis on
                                learning through experience and exploration.
                              </p>
                              <p>
                                <strong>
                                  The CBSE upholds the legacy of a high standard
                                  of education for the children
                                </strong>
                                – CBSE very regularly launches special
                                programmes often to aid students to develop the
                                knowledge and skills they have already learnt.
                                STEAM-based learning, which focuses on Science,
                                Technology, Engineering, Arts and Mathematics,
                                is one example. The interdisciplinary education
                                approach helps the students to put their
                                knowledge, concepts, and ideas into practice.
                                Other value-added programmes, also give pupils
                                the practical, logical, and conceptual
                                understanding that is crucial today.
                              </p>
                              <p>
                                <strong>Prepared for Competitive Exams:</strong>
                                &nbsp;The curriculum is structured in such a way
                                that pupils are automatically prepared for
                                national-level competitive tests like the NTSE,
                                KVPY, NSO, NEET, IIT JEE and such entrance
                                exams. CBSE syllabus is basically linked with
                                the requirements for medical and engineering
                                entrance exams. There are significant
                                similarities between the core NEET subject
                                areas, namely Chemistry, Physics, and Biology
                                (Zoology &amp; Botany), and what is taught in
                                CBSE schools. CBSE has an advantage over other
                                boards because of this. Parents are also
                                increasingly selecting CBSE school boards for
                                this reason.&nbsp;
                              </p>
                              <p>
                                <strong>
                                  CBSE schools in India and NCERT books.&nbsp;
                                </strong>
                                NCERT books are quite helpful at several class
                                levels in addition to the grade 10 and 12 board
                                exams. NCERT books are published after extensive
                                research by experts in each subject. They offer
                                in-depth knowledge of each subject, topic, and
                                concept to students and let the students learn
                                effectively. NCERT books are essential for all
                                entrance exams because they contain all the
                                fundamental ideas.&nbsp;The new NCERT books also
                                have QR codes for each chapter which is a huge
                                advantage to the learners.
                              </p>
                              <p>
                                <strong>
                                  Flexibility for admission to the CBSE board.
                                </strong>
                                &nbsp;– Parents like to enrol their children in
                                CBSE schools because they offer flexibility,
                                particularly for parents who constantly move
                                from one region to another with transferable
                                jobs. An additional advantage is that each year,
                                the CBSE is affiliating a growing number of
                                schools. It is a big relief for such parents and
                                students when admissions can be done without
                                much hassle. Though of course, certain essential
                                documents are to be produced at the time of
                                admission to avoid students randomly changing
                                schools.
                              </p>
                              <p>&nbsp;</p>
                            </div>
                            <div className="entry-tag-share">
                              <div className="row">
                                <div className="col-sm-6"></div>
                                <div className="col-sm-6">
                                  <ul className="thim-social-share">
                                    <li className="heading">Share:</li>
                                    <li>
                                      <div className="facebook-social">
                                        <a
                                          target="_blank"
                                          className="facebook"
                                          href="https://www.facebook.com/sharer.php?u=https%3A%2F%2Fdavsanford.com%2Fwhy-choose-cbse-board-for-your-child%2F"
                                          title="Facebook"
                                        >
                                          <i className="fa fa-facebook" />
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="twitter-social">
                                        <a
                                          target="_blank"
                                          className="twitter"
                                          href="https://twitter.com/share?url=https%3A%2F%2Fdavsanford.com%2Fwhy-choose-cbse-board-for-your-child%2F&text=Why%20choose%20CBSE%20Board%20for%20your%20child%3F"
                                          title="Twitter"
                                        >
                                          <i className="fa fa-twitter" />
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="pinterest-social">
                                        <a
                                          target="_blank"
                                          className="pinterest"
                                          href="../../pinterest.com/pin/create/button/index5650.html?url=https%3A%2F%2Fdavsanford.com%2Fwhy-choose-cbse-board-for-your-child%2F&description=The%20world%20is%20evolving.%20Nowadays%2C%20compared%20to%20earlier%20times%2C%20there%20is%20too%20much%20competition%20all%20around%20us.%20In%20such%20a%20scenario%20choosing%20the%20finest%20school%20and%20the%20best%20education%20board%20for%20our%20children%20is%20a%20major%20concern%20for%20parents.%20Schools%20%26hellip%3B%20&media=https%3A%2F%2Fdavsanford.com%2Fwp-content%2Fuploads%2F2023%2F06%2FWhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1.jpeg"
                                          onclick="window.open(this.href); return false;"
                                          title="Pinterest"
                                        >
                                          <i className="fa fa-pinterest-p" />
                                        </a>
                                      </div>
                                    </li>
                                  </ul>{" "}
                                </div>
                              </div>
                            </div>
                            <div className="thim-about-author">
                              <div className="author-wrapper">
                                <div className="author-avatar">
                                  <a href="../author/sitemanager/index.html">
                                    <img
                                      alt="User Avatar"
                                      src="https://secure.gravatar.com/avatar/63fa2f7d8bdbd85ae29ad55a9c770266?s=96&d=mm&r=g"
                                      height={110}
                                      width={110}
                                    />{" "}
                                  </a>
                                </div>
                                <div className="author-bio">
                                  <div className="author-top">
                                    <a
                                      className="name"
                                      href="../author/sitemanager/index.html"
                                    >
                                      sitemanager{" "}
                                    </a>
                                  </div>
                                  <div className="author-description"></div>
                                </div>
                              </div>
                            </div>
                            <div className="entry-navigation-post">
                              <div className="prev-post">
                                <p className="heading">Previous post</p>
                                <h5 className="title">
                                  <a href="../ways-to-improve-the-social-skills-of-your-children-during-the-school-holidays/index.html">
                                    Ways To Improve the Social Skills Of Your
                                    Children During The School Holidays
                                  </a>
                                </h5>
                                <div className="date">June 7, 2023 </div>
                              </div>
                            </div>
                          </div>
                        </article>
                        <div id="comments" className="comments-area">
                          <div className="comment-respond-area">
                            <div id="respond" className="comment-respond">
                              <h3
                                id="reply-title"
                                className="comment-reply-title"
                              >
                                Leave A Reply{" "}
                                <small>
                                  <a
                                    rel="nofollow"
                                    id="cancel-comment-reply-link"
                                    href="index.html#respond"
                                    style={{ display: "none" }}
                                  >
                                    Cancel reply
                                  </a>
                                </small>
                              </h3>
                              <form
                                action="https://davsanford.com/wp-comments-post.php"
                                method="post"
                                id="commentform"
                                className="comment-form"
                                noValidate
                              >
                                <p className="comment-notes">
                                  <span id="email-notes">
                                    Your email address will not be published.
                                  </span>{" "}
                                  <span className="required-field-message">
                                    Required fields are marked{" "}
                                    <span className="required">*</span>
                                  </span>
                                </p>
                                <p className="comment-form-comment">
                                  <textarea
                                    placeholder="Comment *"
                                    id="comment"
                                    name="comment"
                                    cols={45}
                                    rows={8}
                                    aria-required="true"
                                    defaultValue={""}
                                  />
                                </p>
                                <p className="comment-form-author">
                                  <input
                                    placeholder="Name *"
                                    id="author"
                                    name="author"
                                    type="text"
                                    defaultValue
                                    size={30}
                                    aria-required="true"
                                  />
                                </p>
                                <p className="comment-form-email">
                                  <input
                                    placeholder="Email *"
                                    id="email"
                                    name="email"
                                    type="text"
                                    defaultValue
                                    size={30}
                                    aria-required="true"
                                  />
                                </p>
                                <p className="comment-form-url">
                                  <input
                                    placeholder="Website *"
                                    id="url"
                                    name="url"
                                    type="text"
                                    defaultValue
                                    size={30}
                                    aria-required="true"
                                  />
                                </p>
                                <p className="form-submit">
                                  <input
                                    name="submit"
                                    type="submit"
                                    id="submit"
                                    className="submit"
                                    defaultValue="Post Comment"
                                  />{" "}
                                  <input
                                    type="hidden"
                                    name="comment_post_ID"
                                    defaultValue={11988}
                                    id="comment_post_ID"
                                  />
                                  <input
                                    type="hidden"
                                    name="comment_parent"
                                    id="comment_parent"
                                    defaultValue={0}
                                  />
                                </p>
                              </form>{" "}
                            </div>
                            {/* #respond */}
                          </div>
                          <div className="clear" />
                        </div>
                        {/* #comments */}
                      </div>
                    </main>
                    <div
                      id="sidebar"
                      className="widget-area col-sm-3 sticky-sidebar"
                      role="complementary"
                    >
                      <aside
                        id="nav_menu-16"
                        className="widget widget_nav_menu"
                      >
                        <h4 className="widget-title">Important Link</h4>
                        <div className="menu-sidebar-about-container">
                          <ul id="menu-sidebar-about" className="menu">
                            <li
                              id="menu-item-11492"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11492"
                            >
                              <a href="../about-d-a-v-sanford/index.html">
                                About D.A.V Sanford
                              </a>
                            </li>
                            <li
                              id="menu-item-11488"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11488"
                            >
                              <a href="../principals-message/index.html">
                                Principal’s Message
                              </a>
                            </li>
                            <li
                              id="menu-item-11491"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11491"
                            >
                              <a href="../vision-mission/index.html">
                                Vision &amp; Mission
                              </a>
                            </li>
                            <li
                              id="menu-item-11489"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11489"
                            >
                              <a href="../smart-class/index.html">
                                Smart Class
                              </a>
                            </li>
                            <li
                              id="menu-item-11484"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11484"
                            >
                              <a href="../art-craft/index.html">
                                Art &amp; Craft
                              </a>
                            </li>
                            <li
                              id="menu-item-11485"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11485"
                            >
                              <a href="../guideliness/index.html">Guidelines</a>
                            </li>
                            <li
                              id="menu-item-11486"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11486"
                            >
                              <a href="../laboratory/index.html">Laboratory</a>
                            </li>
                            <li
                              id="menu-item-11487"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11487"
                            >
                              <a href="../library/index.html">Library</a>
                            </li>
                            <li
                              id="menu-item-11490"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11490"
                            >
                              <a href="../transportation/index.html">
                                Transportation
                              </a>
                            </li>
                          </ul>
                        </div>
                      </aside>
                      <aside
                        id="list-post-2"
                        className="widget widget_list-post"
                      >
                        <div className="thim-widget-list-post template-list-post">
                          <h3 className="widget-title">Latest Posts</h3>
                          <div className="sc-list-post thim-list-posts sidebar">
                            <div className="item-post has_thumb post-11202 post type-post status-publish format-standard has-post-thumbnail hentry category-student-corner">
                              <div className="article-image image">
                                <a href="../ways-to-improve-the-social-skills-of-your-children-during-the-school-holidays/index.html">
                                  <img
                                    width={150}
                                    height={150}
                                    src="../wp-content/uploads/2023/05/holiday-150x150.jpg"
                                    className="attachment-thumbnail size-thumbnail wp-post-image"
                                    alt
                                    decoding="async"
                                    loading="lazy"
                                    srcSet="https://davsanford.com/wp-content/uploads/2023/05/holiday-150x150.jpg 150w, https://davsanford.com/wp-content/uploads/2023/05/holiday-300x300.jpg 300w, https://davsanford.com/wp-content/uploads/2023/05/holiday-100x100.jpg 100w, https://davsanford.com/wp-content/uploads/2023/05/holiday-350x350.jpg 350w, https://davsanford.com/wp-content/uploads/2023/05/holiday-420x420.jpg 420w"
                                    sizes="(max-width: 150px) 100vw, 150px"
                                  />
                                </a>
                              </div>
                              <div className="article-title-wrapper">
                                <h5 className="title">
                                  <a
                                    href="../ways-to-improve-the-social-skills-of-your-children-during-the-school-holidays/index.html"
                                    className="article-title"
                                  >
                                    Ways To Improve the Social Skills Of Your
                                    Children During The School Holidays
                                  </a>
                                </h5>
                                <div className="article-date">
                                  <span className="day">14</span>
                                  <span className="month">May</span>
                                  <span className="year">2023</span>
                                </div>
                              </div>
                            </div>
                            <div className="item-post has_thumb post-11988 post type-post status-publish format-standard has-post-thumbnail hentry category-school-life">
                              <div className="article-image image">
                                <a href="index.html">
                                  <img
                                    width={150}
                                    height={150}
                                    src="../wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-150x150.jpeg"
                                    className="attachment-thumbnail size-thumbnail wp-post-image"
                                    alt
                                    decoding="async"
                                    loading="lazy"
                                    srcSet="https://davsanford.com/wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-150x150.jpeg 150w, https://davsanford.com/wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-300x300.jpeg 300w, https://davsanford.com/wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-100x100.jpeg 100w, https://davsanford.com/wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-350x350.jpeg 350w"
                                    sizes="(max-width: 150px) 100vw, 150px"
                                  />
                                </a>
                              </div>
                              <div className="article-title-wrapper">
                                <h5 className="title">
                                  <a
                                    href="index.html"
                                    className="article-title"
                                  >
                                    Why choose CBSE Board for your child?
                                  </a>
                                </h5>
                                <div className="article-date">
                                  <span className="day">07</span>
                                  <span className="month">Jun</span>
                                  <span className="year">2023</span>
                                </div>
                              </div>
                            </div>
                            <div className="item-post has_thumb post-3696 post type-post status-publish format-standard has-post-thumbnail hentry category-student-corner tag-wordpress">
                              <div className="article-image image">
                                <a href="../why-you-should-read-every-day/index.html">
                                  <img
                                    width={150}
                                    height={150}
                                    src="../wp-content/uploads/2023/05/5-step-150x150.jpg"
                                    className="attachment-thumbnail size-thumbnail wp-post-image"
                                    alt
                                    decoding="async"
                                    loading="lazy"
                                    srcSet="https://davsanford.com/wp-content/uploads/2023/05/5-step-150x150.jpg 150w, https://davsanford.com/wp-content/uploads/2023/05/5-step-300x300.jpg 300w, https://davsanford.com/wp-content/uploads/2023/05/5-step-100x100.jpg 100w, https://davsanford.com/wp-content/uploads/2023/05/5-step-350x350.jpg 350w, https://davsanford.com/wp-content/uploads/2023/05/5-step-420x420.jpg 420w"
                                    sizes="(max-width: 150px) 100vw, 150px"
                                  />
                                </a>
                              </div>
                              <div className="article-title-wrapper">
                                <h5 className="title">
                                  <a
                                    href="../why-you-should-read-every-day/index.html"
                                    className="article-title"
                                  >
                                    5 Steps Essential To Be Taken For The
                                    Holistic Growth of Children in 2023
                                  </a>
                                </h5>
                                <div className="article-date">
                                  <span className="day">14</span>
                                  <span className="month">May</span>
                                  <span className="year">2023</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </aside>
                    </div>
                    {/* #secondary */}
                  </div>
                </div>
              </section>
              <Footer />

              {/* #colophon */}
            </div>
            {/*end main-content*/}
          </div>
          {/* end content-pusher*/}
        </div>
        {/* end wrapper-container */}
      </div>
    </div>
  );
}

export default index;
