import React, { useState } from "react";
import { Box, Typography, TextField, Button, Container } from "@mui/material";
import axios from "axios";
import FormImg from "../../wp-content/uploads/2023/05/Why-School-education-crucial-for-child-development.jpg";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function CareerForm() {
  const [formData, setFormData] = useState({
    pdf: null,
    name: "",
    email: "",
    phone: "",
    apply: "",
    address: "",
    message: "",
    years_of_experience: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(
        "https://lyci0i0cnf.execute-api.ap-south-1.amazonaws.com/sanford/sanford_carrier",
        formData
      );

      toast.success("Form submitted successfully!");
    } catch (error) {
      // Show error toast
      toast.error("Error submitting form. Please try again later.");
    }

    // Reset form fields
    setFormData({
      pdf: null,
      name: "",
      email: "",
      phone: "",
      apply: "",
      address: "",
      message: "",
      years_of_experience: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target?.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevData) => ({
          ...prevData,
          pdf: {
            name: file.name,
            base64: reader.result.split(",")[1], // Extract base64 data
          },
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          marginTop: { xs: 0, sm: 10 },
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "50%" },
            padding: "5px",
            paddingRight: "5px",
            paddingLeft: "5px",
          }}
        >
          <img
            src={FormImg}
            alt="Left Side"
            style={{
              width: "100%",
              height: "auto", // Ensures the image is responsive
              margin: "20px auto",
              marginTop: 200,
            }}
          />
        </Box>
        <Box
          className="col-md-7 content-box hero-content"
          sx={{
            position: "relative",
            width: { xs: "100%", sm: "50%" },
            minHeight: "1px",
            flex: "0 0 58.3333%",
            maxWidth: "58.3333%",
            padding: "5px",
            paddingRight: "5px",
            paddingLeft: "5px",
            paddingTop: "0em",
            marginTop: "0em",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              marginTop: "0px",
              marginBottom: "0.5rem",
              fontWeight: 700,
              textAlign: { xs: "center", sm: "center" },
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
              fontSize: { xs: "28px", sm: "32px" },
              lineHeight: { xs: "1.2", sm: "1.3" },
              fontFamily: "Roboto",
            }}
          >
            Apply Now
          </Typography>
          <br />
          <br />
          <Container maxWidth="md">
            <form onSubmit={handleSubmit}>
              <Typography
                variant="subtitle1"
                sx={{
                  marginBottom: "0.5rem",
                  display: "inline-block",
                  fontSize: 15,
                }}
              >
                Full Name
                <span
                  style={{
                    fontWeight: 400,
                    textTransform: "uppercase",
                    letterSpacing: "0.05em",
                    color: "red",
                  }}
                >
                  *
                </span>
              </Typography>
              <TextField
                id="name"
                name="name"
                type="text"
                required
                placeholder="Your name.."
                fullWidth
                value={formData.name}
                onChange={handleChange}
                InputProps={{
                  style: { fontSize: 15 }, // Font size of input
                }}
                InputLabelProps={{
                  style: { fontSize: 15 }, // Font size of placeholder
                }}
              />
              <Typography
                variant="subtitle1"
                sx={{
                  marginBottom: "0.5rem",
                  display: "inline-block",
                  fontSize: 15,
                }}
              >
                Enter Your Phone Number
                <span
                  style={{
                    fontWeight: 400,
                    textTransform: "uppercase",
                    letterSpacing: "0.05em",
                    color: "red",
                  }}
                >
                  *
                </span>
              </Typography>
              <TextField
                id="phone"
                name="phone"
                type="tel"
                pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                required
                placeholder="0123456789"
                fullWidth
                value={formData.phone}
                onChange={handleChange}
                InputProps={{
                  style: { fontSize: 15 },
                }}
                InputLabelProps={{
                  style: { fontSize: 15 },
                }}
              />
              <Typography
                variant="subtitle1"
                sx={{
                  marginBottom: "0.5rem",
                  display: "inline-block",
                  fontSize: 15,
                }}
              >
                E-mail
                <span
                  style={{
                    fontWeight: 400,
                    textTransform: "uppercase",
                    letterSpacing: "0.05em",
                    color: "red",
                  }}
                >
                  *
                </span>
              </Typography>
              <TextField
                id="email"
                name="email"
                type="email"
                required
                placeholder="Your e-mail..."
                fullWidth
                value={formData.email}
                onChange={handleChange}
                InputProps={{
                  style: { fontSize: 15 },
                }}
                InputLabelProps={{
                  style: { fontSize: 15 },
                }}
              />
              <Typography
                variant="subtitle1"
                sx={{
                  marginBottom: "0.5rem",
                  display: "inline-block",
                  fontSize: 15,
                }}
              >
                Address
                <span
                  style={{
                    fontWeight: 400,
                    textTransform: "uppercase",
                    letterSpacing: "0.05em",
                    color: "red",
                  }}
                >
                  *
                </span>
              </Typography>
              <TextField
                id="address"
                name="address"
                type="text"
                required
                placeholder="Your Address..."
                fullWidth
                value={formData.address}
                onChange={handleChange}
                InputProps={{
                  style: { fontSize: 15 },
                }}
                InputLabelProps={{
                  style: { fontSize: 15 },
                }}
              />
              <Typography
                variant="subtitle1"
                sx={{
                  marginBottom: "0.5rem",
                  display: "inline-block",
                  fontSize: 15,
                }}
              >
                Applying for position
                <span
                  style={{
                    fontWeight: 400,
                    textTransform: "uppercase",
                    letterSpacing: "0.05em",
                    color: "red",
                  }}
                >
                  *
                </span>
              </Typography>
              <TextField
                id="apply"
                name="apply"
                select
                required
                fullWidth
                SelectProps={{ native: true }}
                value={formData.apply}
                onChange={handleChange}
                InputProps={{
                  style: { fontSize: 15 },
                }}
                InputLabelProps={{
                  style: { fontSize: 15 },
                }}
              >
                <option value=" ">Select any one</option>
                <option value="PRE-PRIMARY(TEACHING)">
                  PRE-PRIMARY(TEACHING)
                </option>
                <option value="PGT(TEACHING)">PGT(TEACHING)</option>
                <option value="TGT(TEACHING)">TGT(TEACHING)</option>
                <option value="DANCER(TEACHING)">DANCER(TEACHING)</option>
              </TextField>

              <Typography
                variant="subtitle1"
                sx={{
                  marginBottom: "0.5rem",
                  display: "inline-block",
                  fontSize: 15,
                }}
              >
                Work Experience:
                <span
                  style={{
                    fontWeight: 400,
                    textTransform: "uppercase",
                    letterSpacing: "0.05em",
                    color: "red",
                  }}
                >
                  *
                </span>
              </Typography>
              <TextField
                id="years_of_experience"
                name="years_of_experience"
                type="years_of_experience"
                required
                placeholder="Describe your work experience"
                fullWidth
                value={formData.years_of_experience}
                onChange={handleChange}
                InputProps={{
                  style: { fontSize: 15 },
                }}
                InputLabelProps={{
                  style: { fontSize: 15 },
                }}
              />
              <div>
                <input
                  id="pdf"
                  name="pdf"
                  type="file"
                  required
                  accept="application/pdf"
                  onChange={handleFileChange}
                  style={{
                    margin: "0px",
                    fontFamily: "inherit",
                    fontSize: "inherit",
                    lineHeight: "inherit",
                    overflow: "visible",
                  }}
                />
              </div>

              <Typography
                variant="body2"
                sx={{
                  marginTop: "0px",
                  letterSpacing: "0.05em",
                  padding: "0px",
                  marginBottom: "0px",
                  color: "rgb(1, 123, 199)",
                  fontSize: "0.9em",
                  fontWeight: 400,
                }}
              >
                *Upload PDF file of your resume.
              </Typography>
              <br />
              <Typography
                variant="subtitle1"
                sx={{
                  marginBottom: "0.5rem",
                  display: "inline-block",
                  fontSize: 15,
                }}
              >
                Give a brief description about yourself.
              </Typography>
              <TextField
                id="message"
                name="message"
                placeholder="Description"
                multiline
                rows={4}
                fullWidth
                value={formData.message}
                onChange={handleChange}
                InputProps={{
                  style: { fontSize: 15 },
                }}
                InputLabelProps={{
                  style: { fontSize: 15 },
                }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ cursor: "pointer" }}
              >
                Submit
              </Button>
            </form>
          </Container>
        </Box>
      </Box>
      <ToastContainer
        style={{ left: "50%", right: "auto", transform: "translateX(-50%)" }}
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={true}
      />
    </>
  );
}
