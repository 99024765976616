import React from "react";

import { Link } from "react-router-dom";
import Header from "./header/header";

function index() {
  return (
    <div>
      {" "}
      <>
        <Header />
        <div id="wrapper-container" className="wrapper-container">
          <div className="content-pusher">
            <div id="main-content">
              <section className="content-area">
                <div className="top_heading_out">
                  <div className="breadcrumbs-wrapper">
                    <div className="container">
                      <ul className="breadcrumbs" id="breadcrumbs">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>Contact</li>
                      </ul>{" "}
                    </div>
                  </div>
                </div>

                <div className="container site-content">
                  <div className="row">
                    <main id="main" className="site-main col-sm-12 full-width">
                      <article
                        id="post-4526"
                        className="post-4526 page type-page status-publish hentry"
                      >
                        <div className="entry-content">
                          <div
                            data-elementor-type="wp-post"
                            data-elementor-id={4526}
                            className="elementor elementor-4526"
                          >
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-9e3e8fb elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="9e3e8fb"
                              data-element_type="section"
                              data-settings='{"stretch_section":"section-stretched"}'
                            >
                              <div className="elementor-container elementor-column-gap-no">
                                <div
                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9e34713"
                                  data-id="9e34713"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-052e901 elementor-widget elementor-widget-google_maps"
                                      data-id="052e901"
                                      data-element_type="widget"
                                      data-widget_type="google_maps.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-custom-embed">
                                          <iframe
                                            loading="lazy"
                                            src="https://maps.google.com/maps?q=D.A.V%20sanford%20public%20school%2C%20Tati%20Jhariya%2C%20Jharkhand%20825313&t=m&z=12&output=embed&iwloc=near"
                                            title="D.A.V sanford public school, Tati Jhariya, Jharkhand 825313"
                                            aria-label="D.A.V sanford public school, Tati Jhariya, Jharkhand 825313"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                        {/* .entry-content */}
                      </article>
                      {/* #post-## */}
                    </main>
                  </div>
                </div>
              </section>

              {/* #colophon */}
            </div>
            {/*end main-content*/}
          </div>
          {/* end content-pusher*/}
        </div>
        {/* end wrapper-container */}
      </>
    </div>
  );
}

export default index;
