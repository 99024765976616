import React from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import { Link } from "react-router-dom";

function index() {
  return (
    <div>
      <div>
        <Header />
        <div id="wrapper-container" className="wrapper-container">
          <div className="content-pusher">
            <div id="main-content">
              <section className="content-area">
                <div className="top_heading_out">
                  <div
                    className="top_site_main"
                    style={{
                      backgroundImage: `url('../../wp-content/uploads/2023/05/trans.jpg')`,
                    }}
                  >
                    <span
                      className="overlay-top-header"
                      style={{ "background-color": "rgba(0,0,0,0.5)" }}
                    />{" "}
                    <div className="page-title-wrapper">
                      <div className="banner-wrapper container">
                        <h1 className="page-title">Principal’s Message</h1>{" "}
                      </div>
                    </div>
                  </div>{" "}
                  <div className="breadcrumbs-wrapper">
                    <div className="container">
                      <ul className="breadcrumbs" id="breadcrumbs">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>Principal’s Message</li>
                      </ul>{" "}
                    </div>
                  </div>
                </div>
                <div className="container site-content">
                  <div className="row">
                    <main id="main" className="site-main col-sm-12 full-width">
                      <article
                        id="post-11278"
                        className="post-11278 page type-page status-publish hentry"
                      >
                        <div className="entry-content">
                          <div
                            data-elementor-type="wp-page"
                            data-elementor-id={11278}
                            className="elementor elementor-11278"
                          >
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-2efbc77 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="2efbc77"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5a80c05b"
                                  data-id="5a80c05b"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-f0116cd elementor-widget elementor-widget-heading"
                                      data-id="f0116cd"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h2 className="elementor-heading-title elementor-size-default">
                                          Principal's Message
                                        </h2>{" "}
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-6557e19e elementor-widget elementor-widget-text-editor"
                                      data-id="6557e19e"
                                      data-element_type="widget"
                                      data-widget_type="text-editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        {/* wp:paragraph */}
                                        <p>
                                          “We at DAV Sanford Public School Tati
                                          Jharia, Hazaribagh aim to provide a
                                          Liberal, happy and diverse environment
                                          for the students that not only
                                          nurtures their creativity and enthuses
                                          finer values like empathy, integrity,
                                          patriotism and honesty but also helps
                                          them to realise their true potential
                                          and eventually they evolve as global
                                          leaders with character..”
                                        </p>
                                        {/* /wp:paragraph */}
                                        {/* wp:paragraph */}
                                        <p>
                                          Our School provide best platform to
                                          let free the young minds, to feed in
                                          new ideas in the techsavvy world.
                                        </p>
                                        {/* /wp:paragraph */}
                                        {/* wp:paragraph */}
                                        <p>
                                          Dear Students, in the years to come
                                          you will emerge as the youngsters who
                                          will be creative, innovative,
                                          enthused, righteous and passionate.
                                          With all these strengths you will
                                          compete well and succeed for sure.
                                          With our unflappable value system, you
                                          will make the great contribution to
                                          the society and you will command a lot
                                          of respect in your life.
                                        </p>
                                        {/* /wp:paragraph */}
                                        {/* wp:paragraph */}
                                        <p>
                                          Besides our cutting-edge teaching
                                          facilities and curriculum, we are also
                                          very proud of our dedicated and caring
                                          faculty members. The strong culture of
                                          caring at our school ensure that every
                                          student is given opportunities to
                                          explore his/her interests beyond the
                                          academic specialization, with the
                                          guidance of faculty
                                        </p>
                                        {/* /wp:paragraph */}
                                        {/* wp:paragraph */}
                                        <p>” Everything you imagine is real”</p>
                                        {/* /wp:paragraph */}
                                        {/* wp:paragraph */}
                                        <p>
                                          “Wishing you all the ability to
                                          imagine that can help you succeed”
                                        </p>
                                        {/* /wp:paragraph */}
                                        {/* wp:paragraph */}
                                        <p>
                                          I invite you to be a part of a journey
                                          that will be transformational.
                                        </p>
                                        {/* /wp:paragraph */}
                                        {/* wp:paragraph */}
                                        <p />
                                        {/* /wp:paragraph */}{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-87b9a30 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="87b9a30"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1e513f2"
                                  data-id="1e513f2"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-9d234e0 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                                      data-id="9d234e0"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                        {/* .entry-content */}
                      </article>
                      {/* #post-## */}
                    </main>
                  </div>
                </div>
              </section>
              <Footer />
              {/* #colophon */}
            </div>
            {/*end main-content*/}
          </div>
          {/* end content-pusher*/}
          <a href="#" id="back-to-top">
            <i className="fa fa-chevron-up" aria-hidden="true" />
          </a>
        </div>
        {/* end wrapper-container */}
      </div>
    </div>
  );
}

export default index;
