import React, { useEffect, useRef, useState } from "react";
import Header from "./header/header";
import Principal from "../wp-content/uploads/2023/05/WhatsApp-Image-2023-05-13-at-4.38.35-PM.jpg";
import Footer from "./footer/footer";
import HeroView from "./HeroView";
import custome from "./custome.css";

import { Box, Typography, styled } from "@mui/material";
import CampusLife from "./CampusLife";
import Demo from "./Demo";

import { Link } from "react-router-dom";
import App from "./App/App";

// import "./YourStyles.css"; // Import your CSS styles if needed

import axios from "axios";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import SportsHandballIcon from "@mui/icons-material/SportsHandball";
import AllNoticefile from "./AllNoticefile";

const useStyles = styled((theme) => ({
  section: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(4),
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  image: {
    maxWidth: "100%",
    height: "auto",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  subHeading: {
    marginBottom: theme.spacing(2),
  },
  line: {
    width: "100%",
    height: "2px",
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(2),
  },
}));

function HomePage() {
  const [happeningData, setHappeningData] = useState([]);

  useEffect(() => {
    UpComingData();
  }, []);

  const UpComingData = async () => {
    try {
      const response = await axios.post(
        "https://lyci0i0cnf.execute-api.ap-south-1.amazonaws.com/sanford/sanford_events_all_data",
        { event_status: "upcoming" }
      );
      setHappeningData(response.data["body-json"]["body"]["Items"]);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [expanded, setExpanded] = useState(false); // State to handle expanded view

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const classes = useStyles();
  const [data, setData] = useState("");
  const [Fdata, setFData] = useState([]);
  const [Ndata, setNData] = useState([]);

  useEffect(() => {
    Award();
    Facility();
    News();
  }, []);

  const Award = async () => {
    axios
      .get(
        "https://lyci0i0cnf.execute-api.ap-south-1.amazonaws.com/sanford/sanford_principal_all_data"
      )
      .then((response) => {
        // Handle the response and store the data
        setData(response.data["body-json"]["body"]["Item"]);
        console.log(response.data);
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error:", error);
      });
  };
  const Facility = async () => {
    axios
      .get(
        "https://lyci0i0cnf.execute-api.ap-south-1.amazonaws.com/sanford/sanford_facility_all_data"
      )
      .then((response) => {
        // Handle the response and store the data
        setFData(response.data["body-json"]["body"]["Items"]);
        console.log(response.data);
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error:", error);
      });
  };
  const News = async () => {
    axios
      .get(
        "https://lyci0i0cnf.execute-api.ap-south-1.amazonaws.com/sanford/sanford_news_all_data"
      )
      .then((response) => {
        // Handle the response and store the data
        setNData(response.data["body-json"]["body"]["Items"]);
        console.log("newsData", response.data["body-json"]["body"]["Items"]);
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error:", error);
      });
  };
  const [expandedNews, setExpandedNews] = useState([]);

  const toggleDescription = (id) => {
    if (expandedNews.includes(id)) {
      setExpandedNews(expandedNews.filter((item) => item !== id));
    } else {
      setExpandedNews([...expandedNews, id]);
    }
  };
  return (
    <div>
      <>
        <Header />
        <div id="wrapper-container" className="wrapper-container">
          <div className="content-pusher">
            <div id="main-content">
              <div
                data-elementor-type="wp-post"
                data-elementor-id={4525}
                className="elementor elementor-4525"
              >
                <section className="home-section">
                  <div className="section-content">
                    <HeroView />
                  </div>
                </section>
                <br />
                <br />
                <br />

                <section className={classes.section}>
                  <Container maxWidth="lg">
                    <Grid container spacing={3} className={classes.container}>
                      <Grid item xs={12} sm={6}>
                        <Card sx={{ width: 400, height: 400 }}>
                          <img
                            src={data.imageUrl}
                            alt=""
                            className={classes.image}
                            style={{
                              width: 400,
                              height: 400,
                            }}
                          />
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box className={classes.paper}>
                          <Typography
                            variant="h4"
                            component="div"
                            sx={{
                              display: "flex",
                              justifyContent: "center", // Center horizontally
                              alignItems: "center", // Center vertically
                              textAlign: "center", // Center text within Typography
                              fontWeight: 550,
                              fontFamily: "Roboto",
                              marginBottom: 2,
                              fontSize: 30,
                              color: "black",
                            }}
                          >
                            Director’s Message
                          </Typography>
                          <Typography
                            fontSize={18}
                            className={classes.subHeading}
                          >
                            {data.message}
                          </Typography>
                          <div className={classes.line}></div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Container>
                </section>
                <Demo />

                <section
                  className="elementor-section elementor-top-section elementor-element elementor-element-d200ed4 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="d200ed4"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-no">
                    <div
                      className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-5dcce16"
                      data-id="5dcce16"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-43b94c0 thim-ekits-heading-text-center elementor-widget thim-ekits-heading elementor-widget-thim-heading"
                          data-id="43b94c0"
                          data-element_type="widget"
                          data-widget_type="thim-heading.default"
                        >
                          <div className="elementor-widget-container">
                            <Typography
                              variant="h4"
                              component="div"
                              sx={{
                                display: "flex",
                                justifyContent: "center", // Center horizontally
                                alignItems: "center", // Center vertically
                                textAlign: "center", // Center text within Typography
                                fontWeight: 550,
                                fontFamily: "Roboto",
                                marginTop: 5,
                                fontSize: 30,
                                color: "black",
                              }}
                            >
                              Distinctive Features
                            </Typography>
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-0c6432f elementor-widget elementor-widget-text-editor"
                          data-id="0c6432f"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div className="elementor-widget-container">
                            <div
                              className="distindesc fdesc fbigfont"
                              style={{ textAlign: "left", fontSize: 18 }}
                            >
                              The curriculum of D.A.V Public School is unique in
                              nature through the primitive years of learning
                              which beautifully blends with the C.B.S.E.
                              Curriculum as the child reaches the Senior
                              classes. It focuses on the student. Learning is
                              made fun and effective through various innovative
                              methodologies designed to engage the student
                              actively.
                            </div>
                            <div style={{ textAlign: "left", fontSize: 15 }}>
                              With a very favourable Student – Teacher Ratio of
                              25:1 and excellent programmes, students can expect
                              learning to be both enjoyable and meaningful at
                              D.A.V Sanford.
                            </div>
                            <ul>
                              <li style={{ textAlign: "left", fontSize: 15 }}>
                                Computer Lab
                              </li>
                              <li style={{ textAlign: "left", fontSize: 15 }}>
                                Fully AC Classroom
                              </li>
                              <li style={{ textAlign: "left", fontSize: 15 }}>
                                Music, Dance, Yoga Classes
                              </li>
                              <li style={{ textAlign: "left", fontSize: 15 }}>
                                Big Play Ground With Garden
                              </li>
                              <li style={{ textAlign: "left", fontSize: 15 }}>
                                Well Trained Experienced Teacher
                              </li>
                              <li style={{ textAlign: "left", fontSize: 15 }}>
                                Activity Based Learning
                              </li>
                              <li style={{ textAlign: "left", fontSize: 15 }}>
                                Based on CBSE, Delhi
                              </li>
                              <li style={{ textAlign: "left", fontSize: 15 }}>
                                Fully CCTV Secure Campus
                              </li>
                              <li style={{ textAlign: "left", fontSize: 15 }}>
                                Best English Conversiontion Environment
                              </li>
                            </ul>
                            <p>&nbsp;</p>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-2f6fe15"
                      data-id="2f6fe15"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-edecd64 elementor-widget elementor-widget-thim-carousel-post"
                          data-id="edecd64"
                          data-element_type="widget"
                          data-widget_type="thim-carousel-post.default"
                        >
                          <div className="elementor-widget-container">
                            <div
                              className="thim-owl-carousel-post thim-carousel-wrapper layout-2"
                              data-visible={1}
                              data-pagination={1}
                              data-navigation={1}
                              data-autoplay={0}
                              data-navigation-text={2}
                            >
                              <Box
                                className="item"
                                sx={{
                                  marginLeft: ["0", "185px"], // Set the margin to 0 on mobile view
                                }}
                              >
                                <div className="image">
                                  <a href="#">
                                    <img
                                      decoding="async"
                                      loading="lazy"
                                      src="./wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-300x300.jpeg"
                                      alt="Why choose CBSE Board for your child?"
                                      title="WhatsApp-Image-2023-04-11-at-16.43.12-1-1024×683"
                                      width={300}
                                      height={300}
                                    />{" "}
                                  </a>
                                </div>
                                <div className="content">
                                  <h4
                                    className="title"
                                    style={{ fontFamily: "Roboto" }}
                                  >
                                    <a href="#" style={{ fontSize: 18 }}>
                                      Why choose CBSE Board for your child?
                                    </a>
                                  </h4>
                                  <div className="desc">
                                    <p style={{ fontSize: 18 }}>
                                      The world is evolving. Nowadays, compared
                                      to earlier times,...
                                    </p>{" "}
                                  </div>
                                </div>
                              </Box>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  className="elementor-section elementor-top-section elementor-element elementor-element-6157b34 thim-row-mobile-no-fullwidth elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="6157b34"
                  data-element_type="section"
                  data-settings='{"background_background":"classic"}'
                >
                  <div className="elementor-container elementor-column-gap-default">
                    <div
                      className="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-a8f0dd2"
                      data-id="a8f0dd2"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="thim-ekits-event-style elementor-element elementor-element-3ac770e cumtom-list-event elementor-widget elementor-widget-thim-list-event"
                          data-id="3ac770e"
                          data-element_type="widget"
                          data-widget_type="thim-list-event.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="list-event-layout-3">
                              <h3
                                style={{
                                  fontFamily: "Roboto",
                                  color: "white",
                                  textDecoration: "underline",
                                }}
                              >
                                Upcoming Events
                              </h3>

                              <div className="thim-list-event layout-3">
                                {" "}
                                {happeningData.map((event) => (
                                  <div className="event-wrapper">
                                    <h5 className="title">
                                      <Link to="/EventAll">
                                        <Typography
                                          sx={{
                                            color: "white",
                                            fontSize: 25,
                                            fontFamily: "Roboto",
                                          }}
                                        >
                                          {event.event_name}
                                        </Typography>

                                        <p style={{ fontSize: 18 }}>
                                          Date: {event.event_date}
                                        </p>
                                      </Link>
                                    </h5>{" "}
                                  </div>
                                ))}
                                <Link
                                  Link
                                  to="/EventAll"
                                  className="view-all"
                                  style={{ fontSize: 18, fontFamily: "Roboto" }}
                                >
                                  SEE ALL EVENTS
                                </Link>
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <Box
                      className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-6eab438"
                      data-id="6eab438"
                      data-element_type="column"
                      sx={{
                        marginTop: ["0", "100px"], // Set the margin to 0 on mobile view
                      }}
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-d712d3d elementor-widget elementor-widget-thim-video"
                          data-id="d712d3d"
                          data-element_type="widget"
                          data-widget_type="thim-video.default"
                        >
                          <div className="elementor-widget-container">
                            <div
                              className="thim-video-popup"
                              style={{
                                background:
                                  "url(./wp-content/uploads/2023/05/ceromomy.jpg)",
                              }}
                            >
                              <div className="video-info">
                                <h4 className="video-title">
                                  Inauguration Ceremony
                                </h4>
                                <div className="video-description">
                                  REPORT OF THE INAUGURATION CEREMONY OF “D.A.V
                                  Sanford Public School: The Finishing School”
                                </div>{" "}
                              </div>
                              {/* <div
                                className="video-content mfp-hide"
                                id="popup-id2722"
                              >
                                <div className="video">
                                  <iframe
                                    title="YouTube Video"
                                    width="900"
                                    height="506"
                                    src="https://www.youtube.com/embed/sN5sBqlczxk"
                                    frameBorder="0"
                                    allowFullScreen
                                  ></iframe>
                                </div>
                              </div> */}
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </Box>
                  </div>
                </section>

                <section
                  className="elementor-section elementor-top-section elementor-element elementor-element-31af1fa1 elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="31af1fa1"
                  data-element_type="section"
                  data-settings='{"stretch_section":"section-stretched"}'
                >
                  <div className="elementor-container elementor-column-gap-no">
                    <div
                      className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3734db01"
                      data-id="3734db01"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-45f24fcd thim-ekits-heading-text-center elementor-widget thim-ekits-heading elementor-widget-thim-heading"
                          data-id="45f24fcd"
                          data-element_type="widget"
                          data-widget_type="thim-heading.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="sc_heading text-center">
                              <h2 className="title">Why Choose Us?</h2>
                              <div className="sub-heading">
                                <p
                                  style={{ fontSize: 18, fontFamily: "Roboto" }}
                                >
                                  A choice that makes the difference.
                                </p>
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-2e0c356 elementor-widget elementor-widget-thim-ekits-image-accordion"
                          data-id="2e0c356"
                          data-element_type="widget"
                          data-widget_type="thim-ekits-image-accordion.default"
                        >
                          <Box className="elementor-widget-container">
                            <div className="thim-ekits-image-accordion ">
                              <div
                                className="thim-ekits-image-accordion__item overlay-active"
                                style={{
                                  backgroundImage: `url('../wp-content/uploads/2023/05/experimental-learning.jpg')`,
                                }}
                              >
                                <div className="overlay">
                                  <div className="overlay-inner">
                                    <Typography
                                      variant="h3"
                                      className="title"
                                      fontFamily={"Roboto"}
                                    >
                                      EXPERIENTIAL LEARNING
                                    </Typography>
                                    <Typography className="desc" fontSize={18}>
                                      Experiential learning, also known as
                                      ‘Learning by Doing,’ is a method of
                                      engaging students in the learning process.
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="thim-ekits-image-accordion__item"
                                style={{
                                  backgroundImage: `url('../wp-content/uploads/2023/05/physic-1-1024x682.jpg')`,
                                }}
                              >
                                <div className="overlay">
                                  <div className="overlay-inner">
                                    <Typography
                                      variant="h3"
                                      className="title"
                                      fontFamily={"Roboto"}
                                    >
                                      PROJECT BASED LEARNING
                                    </Typography>
                                    <Typography className="desc" fontSize={18}>
                                      The school offers Project-Based Learning
                                      to students in order to instill and
                                      develop deep content knowledge.
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="thim-ekits-image-accordion__item"
                                style={{
                                  backgroundImage: `url('../wp-content/uploads/2023/05/smart.jpg')`,
                                }}
                              >
                                <div className="overlay">
                                  <div className="overlay-inner">
                                    <Typography
                                      variant="h3"
                                      className="title"
                                      fontFamily={"Roboto"}
                                    >
                                      MULTIPLE INTELLIGENCES
                                    </Typography>
                                    <Typography className="desc" fontSize={18}>
                                      The Multiple Intelligences approach to
                                      learning helps students understand what
                                      they learn better.
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Box>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  className="elementor-section elementor-top-section elementor-element elementor-element-ffe9626 elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="ffe9626"
                  data-element_type="section"
                  data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
                >
                  <div className="elementor-container elementor-column-gap-no">
                    <div
                      className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e7c4aed"
                      data-id="e7c4aed"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-dc110fc thim-ekits-heading-text-center elementor-widget thim-ekits-heading elementor-widget-thim-heading"
                          data-id="dc110fc"
                          data-element_type="widget"
                          data-widget_type="thim-heading.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="sc_heading text-center">
                              <h2
                                className="title"
                                style={{ fontFamily: "Roboto" }}
                              >
                                Our Facilities
                              </h2>
                              <div className="sub-heading">
                                <p style={{ fontSize: 18 }}>
                                  All the school infrastructure is designed and
                                  created to support overall development of our
                                  students. Students are encouraged to make full
                                  use of these facilities to develop a learning
                                  attitude
                                </p>
                              </div>
                            </div>{" "}
                          </div>
                        </div>

                        <Grid container spacing={2}>
                          {Fdata &&
                            Fdata.map((item, index) => (
                              <Grid item xs={12} sm={6} md={4} key={index}>
                                <Card>
                                  <Box
                                    sx={{
                                      position: "relative",
                                      height: "600px",
                                      width: "400px",
                                      "&:hover .overlay": {
                                        opacity: 1,
                                      },
                                    }}
                                    className="card-image"
                                  >
                                    <img
                                      src={item.imageUrl}
                                      alt={item.title}
                                      className="image"
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                    <div className="overlay">
                                      <div className="overlay-inner">
                                        <CardContent className="card-content">
                                          <Typography
                                            variant="h4"
                                            component="div"
                                            sx={{
                                              color: "white",
                                              fontFamily: "Roboto",
                                            }}
                                          >
                                            {item.title}
                                          </Typography>
                                          {expanded ||
                                          item.description.length <= 200 ? (
                                            <Typography
                                              fontSize={15}
                                              color="text.secondary"
                                              sx={{
                                                color: "white",
                                                marginRight: 5,
                                              }}
                                            >
                                              {item.description}
                                            </Typography>
                                          ) : (
                                            <>
                                              <Typography
                                                fontSize={15}
                                                color="text.secondary"
                                                sx={{
                                                  color: "white",
                                                }}
                                              >
                                                {item.description.slice(0, 200)}
                                              </Typography>
                                              <Button
                                                onClick={toggleExpanded}
                                                color="primary"
                                              >
                                                Read More
                                              </Button>
                                            </>
                                          )}
                                          {expanded &&
                                            item.description.length > 200 && (
                                              <Button
                                                onClick={toggleExpanded}
                                                color="primary"
                                              >
                                                Read Less
                                              </Button>
                                            )}
                                        </CardContent>
                                      </div>
                                    </div>
                                  </Box>
                                </Card>
                              </Grid>
                            ))}
                        </Grid>

                        <section
                          className="elementor-section elementor-inner-section elementor-element elementor-element-85a6922 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                          data-id="85a6922"
                          data-element_type="section"
                        >
                          <div className="elementor-container elementor-column-gap-custom">
                            <div
                              className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-b4d1a6f"
                              data-id="b4d1a6f"
                              data-element_type="column"
                            >
                              <div className="elementor-widget-wrap"></div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  className="elementor-section elementor-top-section elementor-element elementor-element-3140bc3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="3140bc3"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-default">
                    <div
                      className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-a409e9f thim-sidebar-content"
                      data-id="a409e9f"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <section
                          className="elementor-section elementor-inner-section elementor-element elementor-element-bd03a0a elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                          data-id="bd03a0a"
                          data-element_type="section"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div className="elementor-container elementor-column-gap-no">
                            <div
                              className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-5b2d3d9"
                              data-id="5b2d3d9"
                              data-element_type="column"
                            >
                              <div className="elementor-widget-wrap elementor-element-populated">
                                <div
                                  className="elementor-element elementor-element-604dc4c thim-ekits-heading-text-left thim-border-bottom-gray elementor-widget thim-ekits-heading elementor-widget-thim-heading"
                                  data-id="604dc4c"
                                  data-element_type="widget"
                                  data-widget_type="thim-heading.default"
                                >
                                  <div className="elementor-widget-container">
                                    <div className="sc_heading text-left">
                                      <h3
                                        className="title"
                                        color="white"
                                        style={{
                                          fontFamily: "Roboto",
                                          color: "white",
                                        }}
                                      >
                                        Facilities at Sanford
                                      </h3>
                                    </div>{" "}
                                  </div>
                                </div>
                                <div
                                  className="elementor-element elementor-element-215524e thim-border-bottom-gray elementor-widget-tablet__width-inherit iconbox-text-left elementor-widget elementor-widget-thim-icon-box"
                                  data-id="215524e"
                                  data-element_type="widget"
                                  data-widget_type="thim-icon-box.default"
                                >
                                  <div className="elementor-widget-container">
                                    <div className="thim-widget-icon-box">
                                      <div className="wrapper-box-icon text-left ">
                                        <div className="smicon-box iconbox-left">
                                          <div className="boxes-icon">
                                            <span className="inner-icon">
                                              <span className="icon">
                                                <i
                                                  aria-hidden="true"
                                                  className="fas fa-bus"
                                                />
                                              </span>
                                            </span>
                                          </div>

                                          <div className="content-inner">
                                            <div className="sc-heading">
                                              <h3
                                                className="heading__primary"
                                                style={{ fontFamily: "Roboto" }}
                                              >
                                                Transport Facility
                                              </h3>
                                            </div>
                                            <div className="desc-icon-box">
                                              <div
                                                className="desc-content"
                                                style={{ fontSize: 18 }}
                                              >
                                                Our entire staff is thoroughly
                                                trained in both driving and
                                                first aid. Every bus has a
                                                tracking app, speed limiters,
                                                and CCTV RFID that would let
                                                parents monitor their children's
                                                current location as they travel
                                                to and from school.
                                              </div>

                                              <Link
                                                to="/transport"
                                                className="smicon-read sc-btn"
                                                style={{ color: "white" }}
                                              >
                                                VIEW MORE{" "}
                                                <i className="fa fa-chevron-right" />
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>{" "}
                                  </div>
                                </div>
                                <div
                                  className="elementor-element elementor-element-995a844 thim-border-bottom-gray iconbox-text-left elementor-widget elementor-widget-thim-icon-box"
                                  data-id="995a844"
                                  data-element_type="widget"
                                  data-widget_type="thim-icon-box.default"
                                >
                                  <div className="elementor-widget-container">
                                    <div className="thim-widget-icon-box">
                                      <div className="wrapper-box-icon text-left ">
                                        <div className="smicon-box iconbox-left">
                                          <div className="boxes-icon">
                                            <span className="inner-icon">
                                              <span className="icon">
                                                <i
                                                  aria-hidden="true"
                                                  className="tk tk-art"
                                                />
                                              </span>
                                            </span>
                                          </div>
                                          <div className="content-inner">
                                            <div className="sc-heading">
                                              <h3
                                                className="heading__primary"
                                                style={{ fontFamily: "Roboto" }}
                                              >
                                                Facilities in art{" "}
                                              </h3>
                                            </div>
                                            <div
                                              className="desc-icon-box"
                                              style={{ fontSize: 18 }}
                                            >
                                              <div className="desc-content">
                                                Our facility has two performance
                                                spaces, one for music and one
                                                for handicrafts. Our teachers at
                                                Cambridge English School are
                                                incredibly passionate about
                                                educating pupils in their
                                                artistic disciplines.
                                              </div>

                                              <Link
                                                to="/art"
                                                className="smicon-read sc-btn"
                                              >
                                                VIEW MORE{" "}
                                                <i className="fa fa-chevron-right" />
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>{" "}
                                  </div>
                                </div>
                                <div
                                  className="elementor-element elementor-element-995a844 thim-border-bottom-gray iconbox-text-left elementor-widget elementor-widget-thim-icon-box"
                                  data-id="995a844"
                                  data-element_type="widget"
                                  data-widget_type="thim-icon-box.default"
                                >
                                  <div className="elementor-widget-container">
                                    <div className="thim-widget-icon-box">
                                      <div className="wrapper-box-icon text-left ">
                                        <div className="smicon-box iconbox-left">
                                          <div className="boxes-icon">
                                            <span className="inner-icon">
                                              <span className="icon">
                                                <SportsHandballIcon
                                                  sx={{
                                                    height: 50,
                                                    width: 60,
                                                  }}
                                                />
                                              </span>
                                            </span>
                                          </div>
                                          <div className="content-inner">
                                            <div className="sc-heading">
                                              <h3
                                                className="heading__primary"
                                                style={{ fontFamily: "Roboto" }}
                                              >
                                                Facilities in Sports
                                              </h3>
                                            </div>
                                            <div className="desc-icon-box">
                                              <div
                                                className="desc-content"
                                                style={{ fontSize: 18 }}
                                              >
                                                ‘A healthy mind dwells in
                                                healthy body.', and only a
                                                healthy person can put in his
                                                best for marching ahead on path
                                                of success. Sports play a very
                                                vital role in maintaining &
                                                nurturing good health. We
                                                provide spacious grounds for
                                                children to play outdoor games
                                                like Cricket, Football,
                                                BasketBall, Volley Ball,
                                                mountaineering, archery etc.
                                              </div>

                                              <Link
                                                to="/Sport"
                                                className="smicon-read sc-btn"
                                              >
                                                VIEW MORE{" "}
                                                <i className="fa fa-chevron-right" />
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                    <div
                      className="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-895c605"
                      data-id="895c605"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <Box mt={4}>
                          <Typography
                            gutterBottom
                            sx={{ fontWeight: 800, fontSize: 30 }}
                          >
                            Latest News
                          </Typography>
                          <Grid container spacing={18}>
                            {Ndata.map((newsItem) => (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                key={newsItem.id}
                              >
                                <Paper
                                  elevation={3}
                                  style={{
                                    minHeight: "400px",
                                    width: "250px",
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <img
                                    loading="lazy"
                                    src={newsItem.imageUrl}
                                    alt=""
                                    style={{ width: "250px", height: "200px" }}
                                  />
                                  <Box
                                    p={2}
                                    style={{ flex: 1, overflow: "hidden" }}
                                  >
                                    <Typography
                                      variant="subtitle2"
                                      color="textSecondary"
                                    >
                                      {newsItem.date}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: 18,
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      <Link
                                        to={newsItem.link}
                                        className="article-title"
                                      >
                                        {newsItem.title}
                                      </Link>
                                    </Typography>
                                    <div className="description">
                                      <Typography
                                        sx={{
                                          fontFamily: "Roboto",
                                          fontSize: 18,
                                        }}
                                        color="textSecondary"
                                      >
                                        {newsItem.description.length > 100
                                          ? expandedNews.includes(newsItem.id)
                                            ? newsItem.description
                                            : `${newsItem.description.slice(
                                                0,
                                                100
                                              )}...`
                                          : newsItem.description}
                                      </Typography>
                                    </div>
                                  </Box>
                                  {newsItem.description.length > 100 && (
                                    <div
                                      style={{
                                        padding: "0 16px",
                                        marginBottom: "16px",
                                      }}
                                    >
                                      <button
                                        className="read-more-button"
                                        onClick={() =>
                                          toggleDescription(newsItem.id)
                                        }
                                      >
                                        {expandedNews.includes(newsItem.id)
                                          ? "Read Less"
                                          : "Read More"}{" "}
                                        <i className="fa fa-long-arrow-right" />
                                      </button>
                                    </div>
                                  )}
                                </Paper>
                              </Grid>
                            ))}
                          </Grid>
                        </Box>

                        <div
                          className="elementor-element elementor-element-d239b94 elementor-widget elementor-widget-image"
                          data-id="d239b94"
                          data-element_type="widget"
                          data-widget_type="image.default"
                          style={{ marginTop: 30 }}
                        >
                          <div className="elementor-widget-container">
                            <img
                              decoding="async"
                              loading="lazy"
                              width={640}
                              height={640}
                              src="../wp-content/uploads/2023/05/preview-school-admission-poster-social-media-post-template-free-1645382970-copy.jpg"
                              className="attachment-large size-large wp-image-11677"
                              alt=""
                            />{" "}
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-60c16a2 elementor-widget thim-widget-button"
                          data-id="60c16a2"
                          data-element_type="widget"
                          data-widget_type="thim-button.default"
                        >
                          <div className="elementor-widget-container">
                            <Link
                              to="/admision"
                              className="widget-button normal"
                              target="_blank"
                              style={{
                                color: "black",
                                transition: "color 0.3s",
                                backgroundColor: "white",
                              }}
                              onMouseEnter={(e) =>
                                (e.target.style.backgroundColor = "green")
                              }
                              onMouseLeave={(e) =>
                                (e.target.style.backgroundColor = "white")
                              }
                            >
                              Admission now
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <CampusLife />
              </div>
              <App />
              <Footer />
              {/* #colophon */}
            </div>
            {/*end main-content*/}
          </div>
          {/* end content-pusher*/}
        </div>
        {/* end wrapper-container */}
      </>
    </div>
  );
}

export default HomePage;
