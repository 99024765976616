import React from "react";
import Header from "../../header/header";
import { Link } from "react-router-dom";
import Footer from "../../footer/footer";

function index() {
  return (
    <div>
      <>
        <Header />
        <div id="wrapper-container" className="wrapper-container">
          <div className="content-pusher">
            <div id="main-content">
              <section className="content-area">
                <div className="top_heading_out">
                  <div
                    className="top_site_main"
                    style={{
                      color: "#ffffff",
                      backgroundImage: `url('../../wp-content/uploads/2023/05/trans.jpg')`,
                    }}
                  >
                    <span
                      className="overlay-top-header"
                      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
                    />{" "}
                    <div className="page-title-wrapper">
                      <div className="banner-wrapper container">
                        <h1 className="page-title">Student Corner</h1>{" "}
                      </div>
                    </div>
                  </div>{" "}
                  <div className="breadcrumbs-wrapper">
                    <div className="container">
                      <ul className="breadcrumbs" id="breadcrumbs">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>Student Corner</li>
                      </ul>{" "}
                    </div>
                  </div>
                </div>
                <div className="container sidebar-right site-content">
                  <div className="row">
                    <main id="main" className="site-main col-sm-9 alignleft">
                      {" "}
                      <div id="blog-archive" className="blog-content">
                        <div className="row">
                          <article
                            id="post-11202"
                            className="col-sm-12 post-11202 post type-post status-publish format-standard has-post-thumbnail hentry category-student-corner"
                          >
                            <div className="content-inner">
                              <div className="post-formats-wrapper">
                                <a
                                  className="post-image"
                                  href="../../ways-to-improve-the-social-skills-of-your-children-during-the-school-holidays/index.html"
                                >
                                  <img
                                    width={970}
                                    height={560}
                                    src="../../wp-content/uploads/2023/05/holiday.jpg"
                                    className="attachment-full size-full wp-post-image"
                                    alt=""
                                    decoding="async"
                                    fetchpriority="high"
                                    srcSet="https://davsanford.com/wp-content/uploads/2023/05/holiday.jpg 970w, https://davsanford.com/wp-content/uploads/2023/05/holiday-300x173.jpg 300w, https://davsanford.com/wp-content/uploads/2023/05/holiday-768x443.jpg 768w, https://davsanford.com/wp-content/uploads/2023/05/holiday-600x346.jpg 600w"
                                    sizes="(max-width: 970px) 100vw, 970px"
                                  />
                                </a>
                              </div>{" "}
                              <div className="entry-content">
                                <header className="entry-header">
                                  <div className="date-meta">
                                    14<i> May</i>{" "}
                                  </div>
                                  <div className="entry-contain">
                                    <h2 className="entry-title">
                                      <a
                                        href="../../ways-to-improve-the-social-skills-of-your-children-during-the-school-holidays/index.html"
                                        rel="bookmark"
                                      >
                                        Ways To Improve the Social Skills Of
                                        Your Children During The School Holidays
                                      </a>
                                    </h2>{" "}
                                    <ul className="entry-meta">
                                      <li className="author">
                                        <span>Posted by</span>
                                        <span className="vcard author author_name">
                                          <a href="../../author/sitemanager/index.html">
                                            sitemanager
                                          </a>
                                        </span>{" "}
                                      </li>
                                      <li className="entry-category">
                                        <span>Categories</span>{" "}
                                        <a href="index.html" rel="category tag">
                                          Student Corner
                                        </a>{" "}
                                      </li>
                                      <li className="comment-total">
                                        <span>Comments</span>
                                        <a href="../../ways-to-improve-the-social-skills-of-your-children-during-the-school-holidays/index.html#respond">
                                          0 comment
                                        </a>{" "}
                                      </li>
                                    </ul>
                                  </div>
                                </header>
                                <div className="entry-summary">
                                  <p>
                                    As parents, you want your children to
                                    succeed and thrive in life. However, often
                                    focus is given to academic achievements and
                                    the importance of social skills is
                                    neglected. Social skills are essential for
                                    children’s development and future success.
                                    These skills …{" "}
                                  </p>
                                </div>
                                <div className="readmore">
                                  <a href="../../ways-to-improve-the-social-skills-of-your-children-during-the-school-holidays/index.html">
                                    Read More
                                  </a>
                                </div>
                              </div>
                            </div>
                          </article>
                          {/* #post-## */}
                          <article
                            id="post-3696"
                            className="col-sm-12 post-3696 post type-post status-publish format-standard has-post-thumbnail hentry category-student-corner tag-wordpress"
                          >
                            <div className="content-inner">
                              <div className="post-formats-wrapper">
                                <a
                                  className="post-image"
                                  href="../../why-you-should-read-every-day/index.html"
                                >
                                  <img
                                    width={970}
                                    height={560}
                                    src="../../wp-content/uploads/2023/05/5-step.jpg"
                                    className="attachment-full size-full wp-post-image"
                                    alt=""
                                    decoding="async"
                                    srcSet="https://davsanford.com/wp-content/uploads/2023/05/5-step.jpg 970w, https://davsanford.com/wp-content/uploads/2023/05/5-step-300x173.jpg 300w, https://davsanford.com/wp-content/uploads/2023/05/5-step-768x443.jpg 768w, https://davsanford.com/wp-content/uploads/2023/05/5-step-600x346.jpg 600w"
                                    sizes="(max-width: 970px) 100vw, 970px"
                                  />
                                </a>
                              </div>{" "}
                              <div className="entry-content">
                                <header className="entry-header">
                                  <div className="date-meta">
                                    14<i> May</i>{" "}
                                  </div>
                                  <div className="entry-contain">
                                    <h2 className="entry-title">
                                      <a
                                        href="../../why-you-should-read-every-day/index.html"
                                        rel="bookmark"
                                      >
                                        5 Steps Essential To Be Taken For The
                                        Holistic Growth of Children in 2023
                                      </a>
                                    </h2>{" "}
                                    <ul className="entry-meta">
                                      <li className="author">
                                        <span>Posted by</span>
                                        <span className="vcard author author_name">
                                          <a href="../../author/sitemanager/index.html">
                                            sitemanager
                                          </a>
                                        </span>{" "}
                                      </li>
                                      <li className="entry-category">
                                        <span>Categories</span>{" "}
                                        <a href="index.html" rel="category tag">
                                          Student Corner
                                        </a>{" "}
                                      </li>
                                      <li className="comment-total">
                                        <span>Comments</span>
                                        <a href="../../why-you-should-read-every-day/index.html#respond">
                                          0 comment
                                        </a>{" "}
                                      </li>
                                    </ul>
                                  </div>
                                </header>
                                <div className="entry-summary">
                                  <p>
                                    Holistic growth refers to a child’s
                                    development in all aspects of their being,
                                    including physical, emotional, social,
                                    cognitive, and spiritual confines. It’s a
                                    comprehensive approach to child development
                                    that aims to foster the child’s overall
                                    well-being rather than fastening solely …{" "}
                                  </p>
                                </div>
                                <div className="readmore">
                                  <a href="../../why-you-should-read-every-day/index.html">
                                    Read More
                                  </a>
                                </div>
                              </div>
                            </div>
                          </article>
                          {/* #post-## */}
                        </div>
                      </div>
                    </main>
                    <div
                      id="sidebar"
                      className="widget-area col-sm-3 sticky-sidebar"
                      role="complementary"
                    >
                      <aside
                        id="nav_menu-16"
                        className="widget widget_nav_menu"
                      >
                        <h4 className="widget-title">Important Link</h4>
                        <div className="menu-sidebar-about-container">
                          <ul id="menu-sidebar-about" className="menu">
                            <li
                              id="menu-item-11492"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11492"
                            >
                              <a href="../../about-d-a-v-sanford/index.html">
                                About D.A.V Sanford
                              </a>
                            </li>
                            <li
                              id="menu-item-11488"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11488"
                            >
                              <a href="../../principals-message/index.html">
                                Principal’s Message
                              </a>
                            </li>
                            <li
                              id="menu-item-11491"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11491"
                            >
                              <a href="../../vision-mission/index.html">
                                Vision &amp; Mission
                              </a>
                            </li>
                            <li
                              id="menu-item-11489"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11489"
                            >
                              <a href="../../smart-class/index.html">
                                Smart Class
                              </a>
                            </li>
                            <li
                              id="menu-item-11484"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11484"
                            >
                              <a href="../../art-craft/index.html">
                                Art &amp; Craft
                              </a>
                            </li>
                            <li
                              id="menu-item-11485"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11485"
                            >
                              <a href="../../guideliness/index.html">
                                Guidelines
                              </a>
                            </li>
                            <li
                              id="menu-item-11486"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11486"
                            >
                              <a href="../../laboratory/index.html">
                                Laboratory
                              </a>
                            </li>
                            <li
                              id="menu-item-11487"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11487"
                            >
                              <a href="../../library/index.html">Library</a>
                            </li>
                            <li
                              id="menu-item-11490"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11490"
                            >
                              <a href="../../transportation/index.html">
                                Transportation
                              </a>
                            </li>
                          </ul>
                        </div>
                      </aside>
                      <aside
                        id="list-post-2"
                        className="widget widget_list-post"
                      >
                        <div className="thim-widget-list-post template-list-post">
                          <h3 className="widget-title">Latest Posts</h3>
                          <div className="sc-list-post thim-list-posts sidebar">
                            <div className="item-post has_thumb post-11988 post type-post status-publish format-standard has-post-thumbnail hentry category-school-life">
                              <div className="article-image image">
                                <a href="../../why-choose-cbse-board-for-your-child/index.html">
                                  <img
                                    width={150}
                                    height={150}
                                    src="../../wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-150x150.jpeg"
                                    className="attachment-thumbnail size-thumbnail wp-post-image"
                                    alt=""
                                    decoding="async"
                                    loading="lazy"
                                    srcSet="https://davsanford.com/wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-150x150.jpeg 150w, https://davsanford.com/wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-300x300.jpeg 300w, https://davsanford.com/wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-100x100.jpeg 100w, https://davsanford.com/wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-350x350.jpeg 350w"
                                    sizes="(max-width: 150px) 100vw, 150px"
                                  />
                                </a>
                              </div>
                              <div className="article-title-wrapper">
                                <h5 className="title">
                                  <a href="#" className="article-title">
                                    Why choose CBSE Board for your child?
                                  </a>
                                </h5>
                                <div className="article-date">
                                  <span className="day">07</span>
                                  <span className="month">Jun</span>
                                  <span className="year">2023</span>
                                </div>
                              </div>
                            </div>
                            <div className="item-post has_thumb post-11202 post type-post status-publish format-standard has-post-thumbnail hentry category-student-corner">
                              <div className="article-image image">
                                <a href="../../ways-to-improve-the-social-skills-of-your-children-during-the-school-holidays/index.html">
                                  <img
                                    width={150}
                                    height={150}
                                    src="../../wp-content/uploads/2023/05/holiday-150x150.jpg"
                                    className="attachment-thumbnail size-thumbnail wp-post-image"
                                    alt=""
                                    decoding="async"
                                    loading="lazy"
                                    srcSet="https://davsanford.com/wp-content/uploads/2023/05/holiday-150x150.jpg 150w, https://davsanford.com/wp-content/uploads/2023/05/holiday-300x300.jpg 300w, https://davsanford.com/wp-content/uploads/2023/05/holiday-100x100.jpg 100w, https://davsanford.com/wp-content/uploads/2023/05/holiday-350x350.jpg 350w, https://davsanford.com/wp-content/uploads/2023/05/holiday-420x420.jpg 420w"
                                    sizes="(max-width: 150px) 100vw, 150px"
                                  />
                                </a>
                              </div>
                              <div className="article-title-wrapper">
                                <h5 className="title">
                                  <a
                                    href="../../ways-to-improve-the-social-skills-of-your-children-during-the-school-holidays/index.html"
                                    className="article-title"
                                  >
                                    Ways To Improve the Social Skills Of Your
                                    Children During The School Holidays
                                  </a>
                                </h5>
                                <div className="article-date">
                                  <span className="day">14</span>
                                  <span className="month">May</span>
                                  <span className="year">2023</span>
                                </div>
                              </div>
                            </div>
                            <div className="item-post has_thumb post-3696 post type-post status-publish format-standard has-post-thumbnail hentry category-student-corner tag-wordpress">
                              <div className="article-image image">
                                <a href="../../why-you-should-read-every-day/index.html">
                                  <img
                                    width={150}
                                    height={150}
                                    src="../../wp-content/uploads/2023/05/5-step-150x150.jpg"
                                    className="attachment-thumbnail size-thumbnail wp-post-image"
                                    alt=""
                                    decoding="async"
                                    loading="lazy"
                                    srcSet="https://davsanford.com/wp-content/uploads/2023/05/5-step-150x150.jpg 150w, https://davsanford.com/wp-content/uploads/2023/05/5-step-300x300.jpg 300w, https://davsanford.com/wp-content/uploads/2023/05/5-step-100x100.jpg 100w, https://davsanford.com/wp-content/uploads/2023/05/5-step-350x350.jpg 350w, https://davsanford.com/wp-content/uploads/2023/05/5-step-420x420.jpg 420w"
                                    sizes="(max-width: 150px) 100vw, 150px"
                                  />
                                </a>
                              </div>
                              <div className="article-title-wrapper">
                                <h5 className="title">
                                  <a
                                    href="../../why-you-should-read-every-day/index.html"
                                    className="article-title"
                                  >
                                    5 Steps Essential To Be Taken For The
                                    Holistic Growth of Children in 2023
                                  </a>
                                </h5>
                                <div className="article-date">
                                  <span className="day">14</span>
                                  <span className="month">May</span>
                                  <span className="year">2023</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </aside>
                    </div>
                    {/* #secondary */}
                  </div>
                </div>
              </section>
              <Footer />

              {/* #colophon */}
            </div>
            {/*end main-content*/}
          </div>
          {/* end content-pusher*/}
        </div>
        {/* end wrapper-container */}
      </>
    </div>
  );
}

export default index;
