import React from "react";
import Footer from "../footer/footer";
import Header from "../header/header";
import { Link } from "react-router-dom";
import Demo from "../Demo";
import CareerForm from "./CareerForm";
import { Typography } from "@mui/material";

function index() {
  return (
    <div>
      <>
        <Header />
        <div id="wrapper-container" className="wrapper-container">
          <div className="content-pusher">
            <div id="main-content">
              <section className="content-area">
                <div className="top_heading_out">
                  <div
                    className="top_site_main"
                    style={{
                      color: "#ffffff",
                      backgroundImage: `url (../wp-content/uploads/2022/04/topheading-blog.jpeg)`,
                    }}
                  >
                    <span
                      className="overlay-top-header"
                      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
                    />{" "}
                    <div className="page-title-wrapper">
                      <div className="banner-wrapper container">
                        <h1 className="page-title">Careers</h1>{" "}
                      </div>
                    </div>
                  </div>{" "}
                  <div className="breadcrumbs-wrapper">
                    <div className="container">
                      <ul className="breadcrumbs" id="breadcrumbs">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>Careers</li>
                      </ul>{" "}
                    </div>
                  </div>
                </div>
                <div className="container site-content">
                  <div className="row">
                    <main id="main" className="site-main col-sm-12 full-width">
                      <article
                        id="post-11266"
                        className="post-11266 page type-page status-publish hentry"
                      >
                        <div className="entry-content">
                          <div
                            data-elementor-type="wp-page"
                            data-elementor-id={11266}
                            className="elementor elementor-11266"
                          >
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-dcb3789 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="dcb3789"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-509cbf4"
                                  data-id="509cbf4"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-553883b elementor-widget elementor-widget-heading"
                                      data-id="553883b"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <Typography
                                          variant="h2"
                                          fontFamily={"Roboto"}
                                          fontWeight={600}
                                        >
                                          Career
                                        </Typography>
                                      </div>
                                    </div>
                                    <section
                                      className="elementor-section elementor-inner-section elementor-element elementor-element-f0b9ee4 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                      data-id="f0b9ee4"
                                      data-element_type="section"
                                    >
                                      <div className="elementor-container elementor-column-gap-default">
                                        <div
                                          className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-aa48820"
                                          data-id="aa48820"
                                          data-element_type="column"
                                        >
                                          <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                              className="elementor-element elementor-element-8d2898c elementor-widget elementor-widget-text-editor"
                                              data-id="8d2898c"
                                              data-element_type="widget"
                                              data-widget_type="text-editor.default"
                                            >
                                              <div className="elementor-widget-container">
                                                <p
                                                  style={{
                                                    textAlign: "center",
                                                    fontSize: 15,
                                                  }}
                                                >
                                                  Find out what it’s really like
                                                  to teach and work with us. In
                                                  this section you’ll find
                                                  everything you need to know
                                                  about your new career with
                                                  D.A.V Sanford Public School.
                                                </p>{" "}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-416f80d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="416f80d"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-e3a6132"
                                  data-id="e3a6132"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-0e51a31 elementor-widget elementor-widget-heading"
                                      data-id="0e51a31"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h2 className="elementor-heading-title elementor-size-default">
                                          Vacancy For Faculties and Non-
                                          Faculties
                                        </h2>{" "}
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-87d463a elementor-widget elementor-widget-image"
                                      data-id="87d463a"
                                      data-element_type="widget"
                                      data-widget_type="image.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <img
                                          decoding="async"
                                          fetchpriority="high"
                                          width={860}
                                          height={430}
                                          src="../wp-content/uploads/2023/05/rajasthan-school-teacher-unesco-report-teaching-vacancy-feautred-image.jpg"
                                          className="attachment-1536x1536 size-1536x1536 wp-image-11769"
                                          alt=""
                                          srcSet="https://davsanford.com/wp-content/uploads/2023/05/rajasthan-school-teacher-unesco-report-teaching-vacancy-feautred-image.jpg 860w, https://davsanford.com/wp-content/uploads/2023/05/rajasthan-school-teacher-unesco-report-teaching-vacancy-feautred-image-300x150.jpg 300w, https://davsanford.com/wp-content/uploads/2023/05/rajasthan-school-teacher-unesco-report-teaching-vacancy-feautred-image-768x384.jpg 768w, https://davsanford.com/wp-content/uploads/2023/05/rajasthan-school-teacher-unesco-report-teaching-vacancy-feautred-image-600x300.jpg 600w"
                                          sizes="(max-width: 860px) 100vw, 860px"
                                        />{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-153a797"
                                  data-id="153a797"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-ecf94e8 elementor-widget elementor-widget-text-editor"
                                      data-id="ecf94e8"
                                      data-element_type="widget"
                                      data-widget_type="text-editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <p style={{ fontSize: 15 }}>
                                          <b>Eligibility:</b>Trained and
                                          experienced candidates with teaching
                                          experience, good academic record,
                                          computer proficiency, excellent
                                          communication skills and knowledge of
                                          co-curricular activities will be
                                          preferred.
                                        </p>
                                        <p style={{ fontSize: 15 }}>
                                          <b>Qualifications:</b> Candidate must
                                          have a brilliant academic record with
                                          a Master’s degree + B.Ed. for PGT/ TGT
                                          Posts and Bachelor’s degree + B.Ed.
                                          for PRT &amp;amp; B.P.Ed. for PET. For
                                          School Facilitator / Coordinator:
                                          Graduate with basic knowledge of
                                          computers, Hardware Technician: Senior
                                          Secondary/ Graduate preferably in
                                          computers with a Diploma/Certificate
                                          in networking/hardware, Front Office
                                          Executive: Graduate with basic
                                          knowledge of computers, Transport
                                          In-charge: Ex-Serviceman with rank
                                          J.C.O. / Hawaldar.
                                        </p>
                                        <p style={{ fontSize: 15 }}>
                                          <b>Experience:</b>Minimum 1 year’s
                                          experience for PRT/TGT posts &amp; 3
                                          years experience for PGT posts.
                                          Candidates can apply for the post by
                                          filling out the form (available on the
                                          website) or mailing it to{" "}
                                          <strong>
                                            davsanford777@gmail.com
                                          </strong>{" "}
                                          Candidates may also submit their
                                          Resumes at the school gate.
                                        </p>{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-02465f8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="02465f8"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d616468"
                                  data-id="d616468"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-08b049a elementor-button-info elementor-align-center elementor-widget elementor-widget-button"
                                      data-id="08b049a"
                                      data-element_type="widget"
                                      data-widget_type="button.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-button-wrapper"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-0e78b1c elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="0e78b1c"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-0b3205b"
                                  data-id="0b3205b"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-ad65099 elementor-widget elementor-widget-heading"
                                      data-id="ad65099"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h2 className="elementor-heading-title elementor-size-default">
                                          Teaching &amp; Non-Teaching Staff
                                        </h2>{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <Demo />
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-b25e15b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="b25e15b"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d65496f"
                                  data-id="d65496f"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-c64c894 elementor-widget elementor-widget-text-editor"
                                      data-id="c64c894"
                                      data-element_type="widget"
                                      data-widget_type="text-editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h6
                                          style={{
                                            textAlign: "center",
                                            fontSize: 15,
                                          }}
                                        >
                                          <em>
                                            Applications are invited from
                                            trained &amp; experienced candidates
                                            with good academic records and
                                            excellent communication skills for
                                            the following posts
                                          </em>
                                        </h6>{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-6769861 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id={6769861}
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3d7763e"
                                  data-id="3d7763e"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-e74a537 elementor-widget elementor-widget-image"
                                      data-id="e74a537"
                                      data-element_type="widget"
                                      data-widget_type="image.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <img
                                          decoding="async"
                                          width={640}
                                          height={204}
                                          src="../wp-content/uploads/2023/05/Untitled-143-copy-1024x327.jpg"
                                          className="attachment-large size-large wp-image-11765"
                                          alt=""
                                          srcSet="https://davsanford.com/wp-content/uploads/2023/05/Untitled-143-copy-1024x327.jpg 1024w, https://davsanford.com/wp-content/uploads/2023/05/Untitled-143-copy-300x96.jpg 300w, https://davsanford.com/wp-content/uploads/2023/05/Untitled-143-copy-768x245.jpg 768w, https://davsanford.com/wp-content/uploads/2023/05/Untitled-143-copy-600x192.jpg 600w, https://davsanford.com/wp-content/uploads/2023/05/Untitled-143-copy.jpg 1200w"
                                          sizes="(max-width: 640px) 100vw, 640px"
                                        />{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                        {/* .entry-content */}
                      </article>
                      {/* #post-## */}
                    </main>
                  </div>
                </div>
              </section>
              <CareerForm />
              <Footer />

              {/* #colophon */}
            </div>
            {/*end main-content*/}
          </div>
          {/* end content-pusher*/}
          <a href="#" id="back-to-top">
            <i className="fa fa-chevron-up" aria-hidden="true" />
          </a>
        </div>
        {/* end wrapper-container */}

        <div className="gallery-slider-content" />
        {/* Call Now Button 1.4.1 (https://callnowbutton.com) [renderer:modern]*/}
      </>
    </div>
  );
}

export default index;
