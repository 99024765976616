import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  styled,
  Modal,
  IconButton,
  Paper,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";

const useStyles = styled((theme) => ({
  // ... (your styles)
}));

export default function Gallery() {
  const classes = useStyles();
  const [selectedImage, setSelectedImage] = useState(null);
  const [images, setImage] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://lyci0i0cnf.execute-api.ap-south-1.amazonaws.com/sanford/sanford_gallery_all_data"
      );
      setImage(response.data["body-json"]["body"]);
      console.log("dataofsandfordGallery", response.data["body-json"]["body"]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Display two images at a time
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    autoplay: true,
    autoplaySpeed: 1000, // Autoplay speed in milliseconds
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2, // Change the number of images displayed on smaller screens
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1, // Display one image at a time on even smaller screens
        },
      },
    ],
  };

  const openImageModal = (image) => {
    setSelectedImage(image);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };

  return (
    <Container className={classes.container} maxWidth="lg">
      <Box
        sx={{ display: "flex", textAlign: "center", justifyContent: "center" }}
      >
        <Typography
          sx={{
            color: "green",
            fontFamily: "Roboto",
            fontSize: 25,
          }}
        >
          GALLERY
        </Typography>
      </Box>

      <Box className={classes.slider}>
        <Slider {...settings}>
          {images.map((image, index) => (
            <div
              key={index}
              onClick={() => openImageModal(image)}
              style={{ height: "100%" }}
            >
              <div style={{ padding: "10px", textAlign: "center" }}>
                {/* Display image inside a card */}
                <Paper
                  className={classes.imageCard}
                  sx={{ height: 300, width: 300 }}
                >
                  <img
                    src={image}
                    alt={`Image ${index}`}
                    // style={{ width: "100%", height: "auto" }}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Paper>
              </div>
            </div>
          ))}
        </Slider>
      </Box>

      {/* Image Modal */}
      <Modal open={!!selectedImage} onClose={closeImageModal}>
        <Paper className={classes.modalContent}>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {selectedImage && (
              <>
                <img
                  className={classes.sliderImage}
                  src={selectedImage}
                  alt="Selected Image"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
                <IconButton
                  className={classes.closeButton}
                  onClick={closeImageModal}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    backgroundColor: "yellow",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </>
            )}
          </div>
        </Paper>
      </Modal>
    </Container>
  );
}
