import React from "react";
import Footer from "../footer/footer";
import Header from "../header/header";
import { Link } from "react-router-dom";
import Art from "./images/art_room.jpg";

function index() {
  return (
    <div>
      <>
        <Header />
        <div id="wrapper-container" className="wrapper-container">
          <div className="content-pusher">
            <div id="main-content">
              <section className="content-area">
                <div className="top_heading_out">
                  <div
                    className="top_site_main"
                    style={{
                      color: "#ffffff",
                      backgroundImage: `url('../../wp-content/uploads/2023/05/trans.jpg')`,
                    }}
                  >
                    <span
                      className="overlay-top-header"
                      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
                    />{" "}
                    <div className="page-title-wrapper">
                      <div className="banner-wrapper container">
                        <h1 className="page-title">Art &amp; Craft</h1>{" "}
                      </div>
                    </div>
                  </div>{" "}
                  <div className="breadcrumbs-wrapper">
                    <div className="container">
                      <ul className="breadcrumbs" id="breadcrumbs">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>Art &amp; Craft</li>
                      </ul>{" "}
                    </div>
                  </div>
                </div>
                <div className="container site-content">
                  <div className="row">
                    <main id="main" className="site-main col-sm-12 full-width">
                      <article
                        id="post-11311"
                        className="post-11311 page type-page status-publish hentry"
                      >
                        <div className="entry-content">
                          <div
                            data-elementor-type="wp-page"
                            data-elementor-id={11311}
                            className="elementor elementor-11311"
                          >
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-f0b9dc1 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="f0b9dc1"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-3b1723a"
                                  data-id="3b1723a"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-c901734 elementor-widget elementor-widget-heading"
                                      data-id="c901734"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h2 className="elementor-heading-title elementor-size-default">
                                          Art and Craft
                                        </h2>{" "}
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-d5b2bea elementor-widget elementor-widget-text-editor"
                                      data-id="d5b2bea"
                                      data-element_type="widget"
                                      data-widget_type="text-editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <p>
                                          The Splashes of Color Reverberate with
                                          the Diversity &amp; Vividity of Human
                                          Emotion in the Art Room at the Junior
                                          Level. Individual styles and
                                          techniques get honed and displayed at
                                          the Middle and Senior School Level.
                                        </p>{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-3069c00"
                                  data-id="3069c00"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-0f29445 elementor-widget elementor-widget-image"
                                      data-id="0f29445"
                                      data-element_type="widget"
                                      data-widget_type="image.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <img
                                          decoding="async"
                                          fetchpriority="high"
                                          width={640}
                                          height={376}
                                          src={Art}
                                          className="attachment-large size-large wp-image-11466"
                                          alt=""
                                          sizes="(max-width: 640px) 100vw, 640px"
                                        />{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-91415c3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="91415c3"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1ca1cc8"
                                  data-id="1ca1cc8"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-7804638 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                                      data-id={7804638}
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                        {/* .entry-content */}
                      </article>
                      {/* #post-## */}
                    </main>
                  </div>
                </div>
              </section>
              <Footer />
              {/* #colophon */}
            </div>
            {/*end main-content*/}
          </div>
          {/* end content-pusher*/}
          <a href="#" id="back-to-top">
            <i className="fa fa-chevron-up" aria-hidden="true" />
          </a>
        </div>
        {/* end wrapper-container */}
      </>
    </div>
  );
}

export default index;
