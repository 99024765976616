import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom"; // Change Link to NavLink
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Hidden from "@mui/material/Hidden";
import { Box, styled } from "@mui/material";
import Logo from "../../wp-content/uploads/2023/05/logo-sunford.jpg";
import { hover } from "@testing-library/user-event/dist/hover";

const useStyles = styled((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  flexContainer: {
    display: "flex",
  },
  listItem: {
    marginRight: theme.spacing(2),
    color: "white", // Set the text color to white
  },
  listItemText: {
    textDecoration: "none", // Remove default underline
  },
}));

const menuStyles = {
  display: "flex",
  marginLeft: 25,
};

function Header() {
  const classes = useStyles();
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const menuItems = [
    { text: "Home", link: "/" },
    { text: "AboutUs", link: "/about" },
    { text: "Academics", link: "/acadmic" },
    { text: "Facility", link: "/index" },
    { text: "Admission", link: "/admision" },
    { text: "Enquiry", link: "/Enq" },
    { text: "Careers", link: "/Car" },
    { text: "Gallery", link: "/gallery" },
    { text: "Contact", link: "/contact" },
  ];

  const list = (
    <div className={classes.list}>
      <List>
        {menuItems.map((item, index) => (
          <ListItem
            button
            key={index}
            component={NavLink}
            to={item.link}
            className={classes.listItem}
          >
            <ListItemText
              primary={item.text}
              className={classes.listItemText}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
  const phoneNumber = "8757327770"; // Replace with your phone number

  const handleCallClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <Box className={classes.root}>
      <AppBar position="fixed" style={{ backgroundColor: "#253d5f" }}>
        <Toolbar>
          <Box sx={{ height: "auto", maxWidth: "100%", width: 70 }}>
            <img src={Logo} alt="" />
          </Box>

          <Hidden mdUp>
            <IconButton
              edge="end"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={toggleMenu}
              sx={{
                marginLeft: "auto",
              }}
            >
              <MenuIcon />
            </IconButton>
            <Drawer anchor="left" open={showMenu} onClose={toggleMenu}>
              {list}
            </Drawer>
          </Hidden>
          <Hidden smDown>
            <Box sx={menuStyles}>
              {menuItems.map((item, index) => (
                <ListItem
                  key={index}
                  component={NavLink}
                  to={item.link}
                  className={classes.listItemText}
                  sx={{
                    color: "white",
                    fontSize: 18,
                    fontFamily: "Roboto",
                    "&:hover": {
                      color: "yellow",
                    },
                  }}
                >
                  {item.text}
                </ListItem>
              ))}
            </Box>
            <Link>
              {" "}
              <Typography
                sx={{
                  color: "white",
                  fontSize: 18,
                  fontFamily: "Roboto",
                  "&:hover": {
                    color: "yellow",
                  },
                }}
                onClick={handleCallClick}
              >
                {" "}
                Mobile: (+91)-8757327770
              </Typography>
            </Link>
          </Hidden>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Header;
