import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Container,
  Card,
  Grid,
  CardContent,
  Typography,
  Button,
  Tab,
  Tabs,
  AppBar,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const AllNoticefile = () => {
  // State to store data fetched from the API
  const [prospectus, setProspectus] = useState({});
  const [notice, setNotices] = useState([]);
  const [syllabusList, setSyllabusList] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    // Fetch data for prospectus, notice, and syllabus
    fetchData1();
    fetchData2();
    fetchSyllabus();
  }, []);

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("md"));

  // Fetch data for prospectus
  const fetchData1 = async () => {
    try {
      const response = await axios.get(
        "https://lyci0i0cnf.execute-api.ap-south-1.amazonaws.com/sanford/sanford_prospectus_all_data"
      );
      setProspectus(response.data["body-json"].body.Item);
    } catch (error) {
      console.error("Error fetching prospectus data:", error);
    }
  };

  // Fetch data for notice
  const fetchData2 = async () => {
    try {
      const response = await axios.get(
        "https://lyci0i0cnf.execute-api.ap-south-1.amazonaws.com/sanford/sanford_notice_all_data"
      );
      setNotices(response.data["body-json"].body.Items);
      console.log(response.data["body-json"]);
    } catch (error) {
      console.error("Error fetching notice data:", error);
    }
  };

  // Fetch data for syllabus
  const fetchSyllabus = async () => {
    try {
      const response = await axios.get(
        "https://lyci0i0cnf.execute-api.ap-south-1.amazonaws.com/sanford/sanford_syallabus_all_data"
      );
      setSyllabusList(response.data["body-json"]["body"]["Items"]);
      console.log("syllabus", response.data["body-json"]);
    } catch (error) {
      console.error("Error fetching syllabus data:", error);
    }
  };

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="left"
      mt={4}
      width={isMobileView ? "100%" : "80%"}
      mx="auto"
      marginBottom={isMobileView ? 4 : 0}
    >
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "green",
          fontFamily: "Roboto",
          fontSize: 25,
          marginBottom: 5,
        }}
      >
        ONLINE RESOURCES
      </Typography>

      <Box>
        <div
          style={{
            overflowY: "auto",
            maxHeight: "300px",
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            textColor="black"
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{
              style: {
                backgroundColor: "yellow",
                color: "white",
              },
            }}
          >
            <Tab
              label="Prospectus"
              sx={{
                color: "white",
                fontSize: isMobileView ? "12px" : "20px",
                padding: "10px",
                margin: "0 10px",
                height: 10,
                fontWeight: 80,
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                backgroundColor: "transparent", // Remove red background color
              }}
            />
            <Tab
              label="Syllabus"
              sx={{
                color: "white",
                fontSize: isMobileView ? "12px" : "20px",
                padding: "10px",
                margin: "0 10px",
                height: 10,
                fontWeight: 80,
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                backgroundColor: "transparent", // Remove red background color
              }}
            />
            <Tab
              label="Notice"
              sx={{
                color: "white",
                fontSize: isMobileView ? "12px" : "20px",
                padding: "10px",
                margin: "0 10px",
                height: 10,
                fontWeight: 80,
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                backgroundColor: "transparent", // Remove red background color
              }}
            />
          </Tabs>
          {selectedTab === 0 && (
            <Grid spacing={4}>
              {/* Prospectus */}
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "green",
                    fontFamily: "Roboto",
                    fontSize: 18,
                    marginTop: 5,
                  }}
                >
                  Prospectus Downloads
                </Typography>
                <Typography variant="subtitle1" gutterBottom fontSize={15}>
                  {prospectus.title}
                </Typography>

                <Button
                  variant="contained"
                  color="primary"
                  href={prospectus.pdfUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download
                </Button>
              </Grid>
            </Grid>
          )}
          {selectedTab === 1 && (
            <Grid spacing={4}>
              {/* Syllabus */}
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "green",
                    fontFamily: "Roboto",
                    fontSize: 18,
                    marginTop: 5,
                  }}
                >
                  Syllabus Downloads
                </Typography>
                {syllabusList.map((syllabusItem, index) => (
                  <div key={index}>
                    <Typography variant="subtitle1" gutterBottom fontSize={15}>
                      {syllabusItem.title}
                    </Typography>

                    <Typography fontSize={15}>
                      Class: {syllabusItem.standard}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      href={syllabusItem.pdfUlr}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download
                    </Button>
                  </div>
                ))}
              </Grid>
            </Grid>
          )}
          {selectedTab === 2 && (
            <Grid spacing={4}>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "green",
                    fontFamily: "Roboto",
                    fontSize: 18,
                    marginTop: 5,
                  }}
                >
                  Notice Downloads
                </Typography>
                {notice.map((noticeItem, index) => (
                  <div key={index}>
                    <Typography variant="subtitle1" gutterBottom fontSize={15}>
                      {noticeItem.title}
                    </Typography>

                    <Button
                      variant="contained"
                      color="primary"
                      href={noticeItem.pdfUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download
                    </Button>
                  </div>
                ))}
              </Grid>
            </Grid>
          )}
        </div>
      </Box>
    </Box>
  );
};

export default AllNoticefile;
