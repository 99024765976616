import React from "react";
import Footer from "../footer/footer";
import Header from "../header/header";
import { Link } from "react-router-dom";

function index() {
  return (
    <div>
      <div>
        <Header />
        <div id="wrapper-container" className="wrapper-container">
          <div className="content-pusher">
            <div id="main-content">
              <section className="content-area">
                <div className="top_heading_out">
                  <div
                    className="top_site_main"
                    style={{
                      backgroundImage: `url('../../wp-content/uploads/2023/05/trans.jpg')`,
                    }}
                  >
                    <span
                      className="overlay-top-header"
                      style={{ "background-color": "rgba(0,0,0,0.5)" }}
                    />{" "}
                    <div className="page-title-wrapper">
                      <div className="banner-wrapper container">
                        <h1 className="page-title">Vision &amp; Mission</h1>{" "}
                      </div>
                    </div>
                  </div>{" "}
                  <div className="breadcrumbs-wrapper">
                    <div className="container">
                      <ul className="breadcrumbs" id="breadcrumbs">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>Vision &amp; Mission</li>
                      </ul>{" "}
                    </div>
                  </div>
                </div>
                <div className="container site-content">
                  <div className="row">
                    <main id="main" className="site-main col-sm-12 full-width">
                      <article
                        id="post-11282"
                        className="post-11282 page type-page status-publish hentry"
                      >
                        <div className="entry-content">
                          <div
                            data-elementor-type="wp-page"
                            data-elementor-id={11282}
                            className="elementor elementor-11282"
                          >
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-8dc6ce8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="8dc6ce8"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-06adca5"
                                  data-id="06adca5"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-8b6d33b elementor-widget thim-ekits-heading elementor-widget-thim-heading"
                                      data-id="8b6d33b"
                                      data-element_type="widget"
                                      data-widget_type="thim-heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="sc_heading ">
                                          <h3 className="title">Our Vision</h3>
                                          <span className="line" />
                                        </div>{" "}
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-38d4afb elementor-widget elementor-widget-text-editor"
                                      data-id="38d4afb"
                                      data-element_type="widget"
                                      data-widget_type="text-editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <p>
                                          To emerge as a school which not only
                                          imbibes new ideas and knowledge among
                                          talented young minds but also
                                          sensitizes them towards social
                                          responsibilities they have as young
                                          citizens of India.
                                        </p>
                                        <h4>
                                          The school will strive to achieve this
                                          vision by:
                                        </h4>
                                        <ul>
                                          <li>
                                            <strong>
                                              Developing a team of dedicated and
                                              competent staff in
                                              excellence-oriented learning
                                              environment.
                                            </strong>
                                          </li>
                                          <li>
                                            <strong>
                                              <i className="fa fa-angle-double-right" />{" "}
                                              Mutually beneficial and
                                              Constructive interaction with
                                              other stakeholders
                                            </strong>
                                          </li>
                                          <li>
                                            <strong>
                                              <i className="fa fa-angle-double-right" />{" "}
                                              Strengthening community services
                                              by developing young human
                                              resource.
                                            </strong>
                                          </li>
                                          <li>
                                            <strong>
                                              <i className="fa fa-angle-double-right" />{" "}
                                              Inculcating the qualities of
                                              leadership, skills and values by
                                              infusing inquisitiveness and
                                              time-honoured virtues in the
                                              students.
                                            </strong>
                                          </li>
                                        </ul>{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-d2ae14e"
                                  data-id="d2ae14e"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-3a36233 elementor-widget elementor-widget-thim-single-images"
                                      data-id="3a36233"
                                      data-element_type="widget"
                                      data-widget_type="thim-single-images.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="single-image template-base">
                                          <img
                                            width={230}
                                            height={198}
                                            src="../wp-content/uploads/2023/05/vision-img.jpg"
                                            className="attachment-large size-large"
                                            alt
                                            decoding="async"
                                          />
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-e673622 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="e673622"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-cb11258"
                                  data-id="cb11258"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-213950c elementor-widget elementor-widget-thim-single-images"
                                      data-id="213950c"
                                      data-element_type="widget"
                                      data-widget_type="thim-single-images.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="single-image template-base">
                                          <img
                                            fetchpriority="high"
                                            width={600}
                                            height={400}
                                            src="../wp-content/uploads/2023/05/Why-School-education-crucial-for-child-development.jpg"
                                            className="attachment-full size-full"
                                            alt
                                            decoding="async"
                                            srcSet="https://davsanford.com/wp-content/uploads/2023/05/Why-School-education-crucial-for-child-development.jpg 600w, https://davsanford.com/wp-content/uploads/2023/05/Why-School-education-crucial-for-child-development-300x200.jpg 300w"
                                            sizes="(max-width: 600px) 100vw, 600px"
                                          />
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-679861e"
                                  data-id="679861e"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-1ca5bb2 elementor-widget thim-ekits-heading elementor-widget-thim-heading"
                                      data-id="1ca5bb2"
                                      data-element_type="widget"
                                      data-widget_type="thim-heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="sc_heading ">
                                          <h3 className="title">Our Mission</h3>
                                          <span className="line" />
                                        </div>{" "}
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-9e6f801 elementor-widget elementor-widget-text-editor"
                                      data-id="9e6f801"
                                      data-element_type="widget"
                                      data-widget_type="text-editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <p>
                                          Our mission is to nurture the enormous
                                          talent of the students by providing
                                          them inspiring ambience in which
                                          students feel highly motivated to
                                          strive in a diligent and dedicated
                                          manner. They will be equipped with the
                                          skills and strengths that they need to
                                          cope with the challenges that life has
                                          in store for them.
                                        </p>
                                        <h4>We are committed to:</h4>
                                        <ul>
                                          <li>
                                            Offering best quality education to
                                            students
                                          </li>
                                          <li>
                                            <i className="fa fa-angle-double-right" />{" "}
                                            Producing intellectually empowered
                                            individuals
                                          </li>
                                          <li>
                                            <i className="fa fa-angle-double-right" />{" "}
                                            Ensuring optimum utilization of
                                            available resources
                                          </li>
                                          <li>
                                            <i className="fa fa-angle-double-right" />{" "}
                                            Providing transparent and efficient
                                            administration
                                          </li>
                                          <li>
                                            <i className="fa fa-angle-double-right" />{" "}
                                            Using modern and appropriate
                                            technologies for the benefits of
                                            students
                                          </li>
                                        </ul>{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-1af8eb2 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="1af8eb2"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8ddc284"
                                  data-id="8ddc284"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-198d440 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                                      data-id="198d440"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                        {/* .entry-content */}
                      </article>
                      {/* #post-## */}
                    </main>
                  </div>
                </div>
              </section>
              <Footer />
              {/* #colophon */}
            </div>
            {/*end main-content*/}
          </div>
          {/* end content-pusher*/}
        </div>
        {/* end wrapper-container */}
      </div>
    </div>
  );
}

export default index;
