import React from "react";
import Header from "../../../header/header";
import Footer from "../../../footer/footer";

function academicsDetail() {
  return (
    <div>
      {" "}
      <div>
        <Header />

        <div id="wrapper-container" className="wrapper-container">
          <div className="content-pusher">
            <div id="main-content">
              <section className="content-area">
                <div className="top_heading_out">
                  <div
                    className="top_site_main"
                    style={{
                      color: "#ffffff",
                      backgroundImage: `url('../../wp-content/uploads/2023/05/trans.jpg')`,
                    }}
                  >
                    <span
                      className="overlay-top-header"
                      style={{ "background-color": "rgba(0,0,0,0.5)" }}
                    />{" "}
                    <div className="page-title-wrapper">
                      <div className="banner-wrapper container">
                        <h1 className="page-title">404 Page</h1>{" "}
                      </div>
                    </div>
                  </div>{" "}
                </div>
                <div className="container site-content">
                  <div className="row">
                    <main id="main" className="site-main col-sm-12 full-width">
                      {" "}
                      <section className="error-404 not-found">
                        <div className="page-404-content">
                          <div className="404-left">
                            <img
                              src="../../../../wp-content/uploads/2023/05/swimming.jpg"
                              alt="404-page"
                            />{" "}
                          </div>
                          <div className="404-right text-left">
                            <h2>
                              404 <span className="thim-color">Error!</span>
                            </h2>
                            <p>
                              Sorry, we can't find the page you are looking for.
                              Please go to{" "}
                              <a
                                href="https://davsanford.com"
                                className="thim-color"
                              >
                                Home.
                              </a>
                            </p>
                          </div>
                        </div>
                        {/* .page-content */}
                      </section>
                    </main>
                  </div>
                </div>
              </section>
              <Footer />
              {/* #colophon */}
            </div>
            {/*end main-content*/}
          </div>
          {/* end content-pusher*/}
        </div>
        {/* end wrapper-container */}
      </div>{" "}
    </div>
  );
}

export default academicsDetail;
