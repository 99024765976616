import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  CssBaseline,
  Container,
  Paper,
  Grid,
  Typography,
  styled,
  Link,
  Box,
} from "@mui/material";

import studentImage from "../../wp-content/AppImage/teacher.jpg"; // Replace with your teacher app image
import teacherImage from "../../wp-content/AppImage/student.jpg"; // Replace with your student app image
import Play from "../../wp-content/AppImage/download-icon-png-4376.png";
import Teacher from "./Student/Teacher.apk";
import Student from "./Student/student.apk";
const useStyles = styled((theme) => ({
  appContainer: {
    background: "white",
    padding: "2rem",
    minHeight: "100vh",
  },
  appSection: {
    padding: "2rem",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  appImage: {
    maxWidth: "100%",
    height: "150px",
    marginTop: "1rem",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem",
  },
  image: {
    width: "50px", // Set your desired width here
    height: "50px", // Set your desired height here
    margin: "5px",
  },
}));

function App() {
  const classes = useStyles();
  const [teacherDownloadLink, setTeacherDownloadLink] = useState("");
  const [studentDownloadLink, setStudentDownloadLink] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://lyci0i0cnf.execute-api.ap-south-1.amazonaws.com/sanford/sanford_app_all_data"
        );

        const { teacherAppLink, studentAppLink } =
          response.data["body-json"]["body"]["Item"];

        setTeacherDownloadLink(teacherAppLink);
        setStudentDownloadLink(studentAppLink);
      } catch (error) {
        console.error("Error fetching download links:", error);
      }
    };

    fetchData();
  }, []);

  const handleTeacherAppDownload = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = Teacher;
    downloadLink.download = "TeacherApp.apk";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleStudentAppDownload = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = Student;
    downloadLink.download = "StudentApp.apk";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Container
      sx={{ background: "white", padding: "2rem", minHeight: "100vh" }}
    >
      <CssBaseline />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper
            sx={{
              padding: "2rem",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              margin: 10,
            }}
          >
            <Typography variant="h4" fontFamily={"Roboto"}>
              Download Teacher App
            </Typography>
            <Typography sx={{ fontSize: 17 }}>
              Download the app designed for teachers.
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Link
                onClick={handleTeacherAppDownload}
                style={{ marginRight: "10px" }}
              >
                <img src={Play} alt="Download" height={100} width={100} />
              </Link>
            </Box>
            <img
              src={teacherImage}
              alt="Teacher App"
              sx={{ maxWidth: "100%", height: "150px", marginTop: "1rem" }}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper
            sx={{
              padding: "2rem",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              margin: 10,
            }}
          >
            <Typography variant="h4" fontFamily={"Roboto"}>
              Download Student App
            </Typography>
            <Typography sx={{ fontSize: 17 }}>
              Download the app designed for students.
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Link
                onClick={handleStudentAppDownload}
                style={{ marginRight: "10px" }}
              >
                <img src={Play} alt="Download" height={100} width={100} />
              </Link>
            </Box>
            <img
              src={studentImage}
              alt="Student App"
              sx={{ maxWidth: "100%", height: "150px", marginTop: "1rem" }}
            />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default App;
