import React from "react";
import Footer from "../../footer/footer";
import Header from "../../header/header";
import { Link } from "react-router-dom";

function index() {
  return (
    <div>
      <>
        <Header />
        <div id="wrapper-container" className="wrapper-container">
          <div className="content-pusher">
            <div id="main-content">
              <section className="content-area">
                <div className="top_heading_out">
                  <div
                    className="top_site_main"
                    style={{
                      color: "#ffffff",
                      backgroundImage: `url('../../../wp-content/uploads/2023/05/trans.jpg')`,
                    }}
                  >
                    <span
                      className="overlay-top-header"
                      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
                    />{" "}
                    <div className="page-title-wrapper">
                      <div className="banner-wrapper container">
                        <h1 className="page-title">School life</h1>{" "}
                      </div>
                    </div>
                  </div>{" "}
                  <div className="breadcrumbs-wrapper">
                    <div className="container">
                      <ul className="breadcrumbs" id="breadcrumbs">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>School life</li>
                      </ul>{" "}
                    </div>
                  </div>
                </div>
                <div className="container sidebar-right site-content">
                  <div className="row">
                    <main id="main" className="site-main col-sm-9 alignleft">
                      {" "}
                      <div id="blog-archive" className="blog-content">
                        <div className="row">
                          <article
                            id="post-11988"
                            className="col-sm-12 post-11988 post type-post status-publish format-standard has-post-thumbnail hentry category-school-life"
                          >
                            <div className="content-inner">
                              <div className="post-formats-wrapper">
                                <a
                                  className="post-image"
                                  href="../../why-choose-cbse-board-for-your-child/index.html"
                                >
                                  <img
                                    width={1024}
                                    height={683}
                                    src="../../wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1.jpg"
                                    className="attachment-full size-full wp-post-image"
                                    alt=""
                                    decoding="async"
                                    fetchpriority="high"
                                    srcSet="https://davsanford.com/wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1.jpeg 1024w, https://davsanford.com/wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-300x200.jpeg 300w, https://davsanford.com/wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-768x512.jpeg 768w, https://davsanford.com/wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-600x400.jpeg 600w"
                                    sizes="(max-width: 1024px) 100vw, 1024px"
                                  />
                                </a>
                              </div>{" "}
                              <div className="entry-content">
                                <header className="entry-header">
                                  <div className="date-meta">
                                    07<i> June</i>{" "}
                                  </div>
                                  <div className="entry-contain">
                                    <h2 className="entry-title">
                                      <a href="#" rel="bookmark">
                                        Why choose CBSE Board for your child?
                                      </a>
                                    </h2>{" "}
                                    <ul className="entry-meta">
                                      <li className="author">
                                        <span>Posted by</span>
                                        <span className="vcard author author_name">
                                          <a href="../../author/sitemanager/index.html">
                                            sitemanager
                                          </a>
                                        </span>{" "}
                                      </li>
                                      <li className="entry-category">
                                        <span>Categories</span>{" "}
                                        <a href="index.html" rel="category tag">
                                          School life
                                        </a>{" "}
                                      </li>
                                      <li className="comment-total">
                                        <span>Comments</span>
                                        <a href="../../why-choose-cbse-board-for-your-child/index.html#respond">
                                          0 comment
                                        </a>{" "}
                                      </li>
                                    </ul>
                                  </div>
                                </header>
                                <div className="entry-summary">
                                  <p>
                                    The world is evolving. Nowadays, compared to
                                    earlier times, there is too much competition
                                    all around us. In such a scenario choosing
                                    the finest school and the best education
                                    board for our children is a major concern
                                    for parents. Schools …{" "}
                                  </p>
                                </div>
                                <div className="readmore">
                                  <a href="../../why-choose-cbse-board-for-your-child/index.html">
                                    Read More
                                  </a>
                                </div>
                              </div>
                            </div>
                          </article>
                          {/* #post-## */}
                        </div>
                      </div>
                    </main>
                    <div
                      id="sidebar"
                      className="widget-area col-sm-3 sticky-sidebar"
                      role="complementary"
                    >
                      <aside
                        id="nav_menu-16"
                        className="widget widget_nav_menu"
                      >
                        <h4 className="widget-title">Important Link</h4>
                        <div className="menu-sidebar-about-container">
                          <ul id="menu-sidebar-about" className="menu">
                            <li
                              id="menu-item-11492"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11492"
                            >
                              <a href="../../about-d-a-v-sanford/index.html">
                                About D.A.V Sanford
                              </a>
                            </li>
                            <li
                              id="menu-item-11488"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11488"
                            >
                              <a href="../../principals-message/index.html">
                                Principal’s Message
                              </a>
                            </li>
                            <li
                              id="menu-item-11491"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11491"
                            >
                              <a href="../../vision-mission/index.html">
                                Vision &amp; Mission
                              </a>
                            </li>
                            <li
                              id="menu-item-11489"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11489"
                            >
                              <a href="../../smart-class/index.html">
                                Smart Class
                              </a>
                            </li>
                            <li
                              id="menu-item-11484"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11484"
                            >
                              <a href="../../art-craft/index.html">
                                Art &amp; Craft
                              </a>
                            </li>
                            <li
                              id="menu-item-11485"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11485"
                            >
                              <a href="../../guideliness/index.html">
                                Guidelines
                              </a>
                            </li>
                            <li
                              id="menu-item-11486"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11486"
                            >
                              <a href="../../laboratory/index.html">
                                Laboratory
                              </a>
                            </li>
                            <li
                              id="menu-item-11487"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11487"
                            >
                              <a href="../../library/index.html">Library</a>
                            </li>
                            <li
                              id="menu-item-11490"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11490"
                            >
                              <a href="../../transportation/index.html">
                                Transportation
                              </a>
                            </li>
                          </ul>
                        </div>
                      </aside>
                      <aside
                        id="list-post-2"
                        className="widget widget_list-post"
                      >
                        <div className="thim-widget-list-post template-list-post">
                          <h3 className="widget-title">Latest Posts</h3>
                          <div className="sc-list-post thim-list-posts sidebar">
                            <div className="item-post has_thumb post-3696 post type-post status-publish format-standard has-post-thumbnail hentry category-student-corner tag-wordpress">
                              <div className="article-image image">
                                <a href="../../why-you-should-read-every-day/index.html">
                                  <img
                                    width={150}
                                    height={150}
                                    src="../../wp-content/uploads/2023/05/5-step-150x150.jpg"
                                    className="attachment-thumbnail size-thumbnail wp-post-image"
                                    alt=""
                                    decoding="async"
                                    loading="lazy"
                                    srcSet="https://davsanford.com/wp-content/uploads/2023/05/5-step-150x150.jpg 150w, https://davsanford.com/wp-content/uploads/2023/05/5-step-300x300.jpg 300w, https://davsanford.com/wp-content/uploads/2023/05/5-step-100x100.jpg 100w, https://davsanford.com/wp-content/uploads/2023/05/5-step-350x350.jpg 350w, https://davsanford.com/wp-content/uploads/2023/05/5-step-420x420.jpg 420w"
                                    sizes="(max-width: 150px) 100vw, 150px"
                                  />
                                </a>
                              </div>
                              <div className="article-title-wrapper">
                                <h5 className="title">
                                  <a
                                    href="../../why-you-should-read-every-day/index.html"
                                    className="article-title"
                                  >
                                    5 Steps Essential To Be Taken For The
                                    Holistic Growth of Children in 2023
                                  </a>
                                </h5>
                                <div className="article-date">
                                  <span className="day">14</span>
                                  <span className="month">May</span>
                                  <span className="year">2023</span>
                                </div>
                              </div>
                            </div>
                            <div className="item-post has_thumb post-11202 post type-post status-publish format-standard has-post-thumbnail hentry category-student-corner">
                              <div className="article-image image">
                                <a href="../../ways-to-improve-the-social-skills-of-your-children-during-the-school-holidays/index.html">
                                  <img
                                    width={150}
                                    height={150}
                                    src="../../wp-content/uploads/2023/05/holiday-150x150.jpg"
                                    className="attachment-thumbnail size-thumbnail wp-post-image"
                                    alt=""
                                    decoding="async"
                                    loading="lazy"
                                    srcSet="https://davsanford.com/wp-content/uploads/2023/05/holiday-150x150.jpg 150w, https://davsanford.com/wp-content/uploads/2023/05/holiday-300x300.jpg 300w, https://davsanford.com/wp-content/uploads/2023/05/holiday-100x100.jpg 100w, https://davsanford.com/wp-content/uploads/2023/05/holiday-350x350.jpg 350w, https://davsanford.com/wp-content/uploads/2023/05/holiday-420x420.jpg 420w"
                                    sizes="(max-width: 150px) 100vw, 150px"
                                  />
                                </a>
                              </div>
                              <div className="article-title-wrapper">
                                <h5 className="title">
                                  <a
                                    href="../../ways-to-improve-the-social-skills-of-your-children-during-the-school-holidays/index.html"
                                    className="article-title"
                                  >
                                    Ways To Improve the Social Skills Of Your
                                    Children During The School Holidays
                                  </a>
                                </h5>
                                <div className="article-date">
                                  <span className="day">14</span>
                                  <span className="month">May</span>
                                  <span className="year">2023</span>
                                </div>
                              </div>
                            </div>
                            <div className="item-post has_thumb post-11988 post type-post status-publish format-standard has-post-thumbnail hentry category-school-life">
                              <div className="article-image image">
                                <a href="../../why-choose-cbse-board-for-your-child/index.html">
                                  <img
                                    width={150}
                                    height={150}
                                    src="../../wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-150x150.jpeg"
                                    className="attachment-thumbnail size-thumbnail wp-post-image"
                                    alt=""
                                    decoding="async"
                                    loading="lazy"
                                    srcSet="https://davsanford.com/wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-150x150.jpeg 150w, https://davsanford.com/wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-300x300.jpeg 300w, https://davsanford.com/wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-100x100.jpeg 100w, https://davsanford.com/wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-350x350.jpeg 350w"
                                    sizes="(max-width: 150px) 100vw, 150px"
                                  />
                                </a>
                              </div>
                              <div className="article-title-wrapper">
                                <h5 className="title">
                                  <a
                                    href="../../why-choose-cbse-board-for-your-child/index.html"
                                    className="article-title"
                                  >
                                    Why choose CBSE Board for your child?
                                  </a>
                                </h5>
                                <div className="article-date">
                                  <span className="day">07</span>
                                  <span className="month">Jun</span>
                                  <span className="year">2023</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </aside>
                    </div>
                    {/* #secondary */}
                  </div>
                </div>
              </section>
              <Footer />
              {/* #colophon */}
            </div>
            {/*end main-content*/}
          </div>
          {/* end content-pusher*/}
          <a href="#" id="back-to-top">
            <i className="fa fa-chevron-up" aria-hidden="true" />
          </a>
        </div>
        {/* end wrapper-container */}
        <div id="thim-popup-login">
          <div className="popup-login-wrapper has-shortcode">
            <div className="thim-login-container">
              [miniorange_social_login shape="longbuttonwithtext"
              theme="default" space="4" width="240" height="40"]
              <div className="thim-popup-inner">
                <div className="thim-login">
                  <h4 className="title">Login with your site account</h4>
                  <form
                    name="loginpopopform"
                    action="https://davsanford.com/wp-login.php"
                    method="post"
                    noValidate=""
                  >
                    <p className="login-username">
                      <input
                        type="text"
                        name="log"
                        placeholder="Username or email"
                        className="input required"
                        size={20}
                      />
                    </p>
                    <p className="login-password">
                      <input
                        type="password"
                        name="pwd"
                        placeholder="Password"
                        className="input required"
                        defaultValue=""
                        size={20}
                      />
                    </p>
                    <a
                      className="lost-pass-link"
                      href="../../account/indexc2b6.html?action=lostpassword"
                      title="Lost Password"
                    >
                      Lost your password?
                    </a>
                    <p className="forgetmenot login-remember">
                      <label htmlFor="popupRememberme">
                        <input
                          name="rememberme"
                          type="checkbox"
                          defaultValue="forever"
                          id="popupRememberme"
                        />{" "}
                        Remember Me{" "}
                      </label>
                    </p>
                    <p className="submit login-submit">
                      <input
                        type="submit"
                        name="wp-submit"
                        className="button button-primary button-large"
                        defaultValue="Login"
                      />
                      <input
                        type="hidden"
                        name="redirect_to"
                        defaultValue="https://davsanford.com/category/school-life/"
                      />
                      <input type="hidden" name="testcookie" defaultValue={1} />
                      <input
                        type="hidden"
                        name="nonce"
                        defaultValue="bfd4b35eb0"
                      />
                      <input type="hidden" name="eduma_login_user" />
                    </p>
                  </form>
                  <p className="link-bottom">
                    Not a member yet?{" "}
                    <a
                      className="register"
                      href="../../account/index0ddc.html?action=register"
                    >
                      Register now
                    </a>
                  </p>{" "}
                </div>
                <div className="thim-register">
                  <h4 className="title">Register a new account</h4>
                  <form
                    className="auto_login"
                    name="registerformpopup"
                    action="https://davsanford.com/wp-login.php?action=register"
                    method="post"
                    noValidate="novalidate"
                  >
                    <input
                      type="hidden"
                      id="register_security"
                      name="register_security"
                      defaultValue="874cc93a21"
                    />
                    <input
                      type="hidden"
                      name="_wp_http_referer"
                      defaultValue="/category/school-life/"
                    />
                    <p>
                      <input
                        placeholder="Username"
                        type="text"
                        name="user_login"
                        className="input required"
                      />
                    </p>
                    <p>
                      <input
                        placeholder="Email"
                        type="email"
                        name="user_email"
                        className="input required"
                      />
                    </p>
                    <p>
                      <input
                        placeholder="Password"
                        type="password"
                        name="password"
                        className="input required"
                      />
                    </p>
                    <p>
                      <input
                        placeholder="Repeat Password"
                        type="password"
                        name="repeat_password"
                        className="input required"
                      />
                    </p>
                    <p>
                      <input
                        type="checkbox"
                        className="required"
                        name="term"
                        id="termFormFieldPopup"
                      />
                      <label htmlFor="termFormField">
                        I accept the{" "}
                        <a href="#" target="_blank" rel="dofollow">
                          Terms of Service
                        </a>
                      </label>
                    </p>
                    <input
                      type="hidden"
                      name="redirect_to"
                      defaultValue="https://davsanford.com/category/school-life/"
                    />
                    <input
                      type="hidden"
                      name="modify_user_notification"
                      defaultValue={1}
                    />
                    <input type="hidden" name="eduma_register_user" />
                    <p className="submit">
                      <input
                        type="submit"
                        name="wp-submit"
                        className="button button-primary button-large"
                        defaultValue="Sign up"
                      />
                    </p>
                  </form>
                  <p className="link-bottom">
                    Are you a member?{" "}
                    <a className="login" href="../../account/index.html">
                      Login now
                    </a>
                  </p>{" "}
                  <div className="popup-message" />
                </div>
              </div>
              <span className="close-popup">
                <i className="fa fa-times" aria-hidden="true" />
              </span>
              <div className="cssload-container">
                <div className="cssload-loading">
                  <i />
                  <i />
                  <i />
                  <i />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="gallery-slider-content" />
        {/* Call Now Button 1.4.1 (https://callnowbutton.com) [renderer:modern]*/}
        <a
          aria-label="Call Now Button"
          href="tel:8757327770"
          id="callnowbutton"
          className="call-now-button  cnb-zoom-100  cnb-zindex-10  cnb-single cnb-right cnb-displaymode cnb-displaymode-always"
          style={{
            backgroundImage: "url(data:image/svg+xml",
            backgroundColor: "#dd1313",
          }}
        >
          <span>Call Now Button</span>
        </a>
        <div
          id="qlwapp"
          className="qlwapp qlwapp-free qlwapp-bubble qlwapp-bottom-right qlwapp-all qlwapp-rounded"
        >
          <div className="qlwapp-container">
            <a
              className="qlwapp-toggle"
              data-action="open"
              data-phone={918757327770}
              data-message="I want to inquiry for admission."
              role="button"
              tabIndex={0}
              target="_blank"
            >
              <i className="qlwapp-icon qlwapp-whatsapp-icon" />
              <i className="qlwapp-close" data-action="close">
                ×
              </i>
            </a>
          </div>
        </div>
        <style
          id="elementor-post-8994"
          dangerouslySetInnerHTML={{
            __html:
              ".elementor-kit-8994{--e-global-color-primary:#DF4653;--e-global-color-secondary:#000000;--e-global-color-text:#7A7A7A;--e-global-color-accent:#898B89;--e-global-color-613c3264:#FFFFFF;--e-global-color-7d202ddd:#000;--e-global-color-2deca54e:#FFF;--e-global-color-40e5576:#D38F08;}.elementor-section.elementor-section-boxed > .elementor-container{max-width:1170px;}.e-con{--container-max-width:1170px;}{}h1.entry-title{display:var(--page-title-display);}@media(max-width:1024px){.elementor-section.elementor-section-boxed > .elementor-container{max-width:1024px;}.e-con{--container-max-width:1024px;}}@media(max-width:767px){.elementor-section.elementor-section-boxed > .elementor-container{max-width:767px;}.e-con{--container-max-width:767px;}}",
          }}
        />
        <link
          rel="stylesheet"
          id="fluentform-elementor-widget-css"
          href="../../wp-content/plugins/fluentform/assets/css/fluent-forms-elementor-widget58aa.css?ver=5.0.7"
          type="text/css"
          media="all"
        />
        <link
          rel="stylesheet"
          id="thim-font-icon-css"
          href="../../wp-content/themes/eduma/assets/css/thim-iconsf801.css?ver=6.3"
          type="text/css"
          media="all"
        />
        <link
          rel="stylesheet"
          id="flaticon-css"
          href="../../wp-content/themes/eduma/assets/css/flaticonf801.css?ver=6.3"
          type="text/css"
          media="all"
        />
        <link
          rel="stylesheet"
          id="font-pe-icon-7-css"
          href="../../wp-content/themes/eduma/assets/css/font-pe-icon-7f801.css?ver=6.3"
          type="text/css"
          media="all"
        />
        <link
          rel="stylesheet"
          id="ionicons-css"
          href="../../wp-content/themes/eduma/assets/css/ionicons.minf801.css?ver=6.3"
          type="text/css"
          media="all"
        />
        <link
          rel="stylesheet"
          id="elementor-icons-css"
          href="../../wp-content/plugins/elementor/assets/lib/eicons/css/elementor-icons.mind618.css?ver=5.21.0"
          type="text/css"
          media="all"
        />
        <link
          rel="stylesheet"
          id="swiper-css"
          href="../../wp-content/plugins/elementor/assets/lib/swiper/css/swiper.min48f5.css?ver=5.3.6"
          type="text/css"
          media="all"
        />
        <link
          rel="stylesheet"
          id="google-fonts-1-css"
          href="https://fonts.googleapis.com/css?family=Roboto+Slab%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic&display=swap&ver=6.3"
          type="text/css"
          media="all"
        />
        <link
          rel="stylesheet"
          id="rs-plugin-settings-css"
          href="../../wp-content/plugins/revslider/public/assets/css/rs6db9d.css?ver=6.6.7"
          type="text/css"
          media="all"
        />
        <style
          id="rs-plugin-settings-inline-css"
          type="text/css"
          dangerouslySetInnerHTML={{ __html: "\n#rs-demo-id {}\n" }}
        />
      </>
    </div>
  );
}

export default index;
