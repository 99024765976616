import React from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import { Link } from "react-router-dom";

function index() {
  return (
    <div>
      <div>
        <Header />
        <div id="wrapper-container" className="wrapper-container">
          <div className="content-pusher">
            <div id="main-content">
              <section className="content-area">
                <div className="top_heading_out">
                  <div
                    className="top_site_main"
                    style={{
                      color: "#ffffff",
                      backgroundImage: `url('../../../wp-content/uploads/2023/05/trans.jpg')`,
                    }}
                  >
                    <span
                      className="overlay-top-header"
                      style={{ "background-color": "rgba(0,0,0,0.5)" }}
                    />{" "}
                    <div className="page-title-wrapper">
                      <div className="banner-wrapper container">
                        <h2 className="page-title">Student Corner</h2>{" "}
                      </div>
                    </div>
                  </div>{" "}
                  <div className="breadcrumbs-wrapper">
                    <div className="container">
                      <ul className="breadcrumbs" id="breadcrumbs">
                        <Link to="/">Home</Link>
                        {/* <li>
                          <a href="../blog/index.html">Blog</a>
                        </li> */}
                        <Link to="/Latest">Blog</Link>
                        <li>
                          {/* <a href="../category/student-corner/index.html">
                            Student Corner
                          </a> */}
                          <Link to="/stdcorn">Student Corner</Link>
                        </li>
                        <li>
                          Ways To Improve the Social Skills Of Your Children
                          During The School Holidays
                        </li>
                      </ul>{" "}
                    </div>
                  </div>
                </div>
                <div className="container sidebar-right site-content">
                  <div className="row">
                    <main id="main" className="site-main col-sm-9 alignleft">
                      <div className="page-content">
                        <article
                          id="post-11202"
                          className="post-11202 post type-post status-publish format-standard has-post-thumbnail hentry category-student-corner"
                        >
                          <div className="page-content-inner">
                            <div className="post-formats-wrapper">
                              <img
                                width={970}
                                height={560}
                                src="../wp-content/uploads/2023/05/holiday.jpg"
                                className="attachment-full size-full wp-post-image"
                                alt
                                decoding="async"
                                fetchpriority="high"
                                srcSet="https://davsanford.com/wp-content/uploads/2023/05/holiday.jpg 970w, https://davsanford.com/wp-content/uploads/2023/05/holiday-300x173.jpg 300w, https://davsanford.com/wp-content/uploads/2023/05/holiday-768x443.jpg 768w, https://davsanford.com/wp-content/uploads/2023/05/holiday-600x346.jpg 600w"
                                sizes="(max-width: 970px) 100vw, 970px"
                              />
                            </div>{" "}
                            <header className="entry-header">
                              <h1 className="entry-title">
                                Ways To Improve the Social Skills Of Your
                                Children During The School Holidays
                              </h1>{" "}
                              <ul className="entry-meta">
                                <li className="author">
                                  <span>Posted by</span>
                                  <span className="vcard author author_name">
                                    <a href="../author/sitemanager/index.html">
                                      sitemanager
                                    </a>
                                  </span>{" "}
                                </li>
                                <li className="entry-category">
                                  <span>Categories</span>{" "}
                                  <a
                                    href="../category/student-corner/index.html"
                                    rel="category tag"
                                  >
                                    Student Corner
                                  </a>{" "}
                                </li>
                                <li className="entry-date">
                                  <span>Date</span>
                                  <span className="value"> May 14, 2023</span>
                                </li>
                                <li className="comment-total">
                                  <span>Comments</span>
                                  <a href="index.html#respond">
                                    0 comment
                                  </a>{" "}
                                </li>
                              </ul>
                            </header>
                            {/* .entry-header */}
                            <div className="entry-content">
                              <p>
                                As parents, you want your children to
                                <br />
                                succeed and thrive in life. However, often focus
                                is given to academic
                                <br />
                                achievements and the importance of social skills
                                is neglected.
                              </p>
                              <p>
                                Social
                                <br />
                                skills are essential for children’s development
                                and future success.
                                <br />
                                These skills allow children to build
                                relationships, communicate
                                <br />
                                effectively, and navigate social situations.
                                During school holidays,
                                <br />
                                parents have the opportunity to help their
                                children improve their social
                                <br />
                                skills by engaging them in various activities
                                and providing guidance
                                <br />
                                and support; the best international schools in
                                Hazaribagh help parents
                                <br />
                                with this.
                              </p>
                              <p>
                                Here are a few
                                <br />
                                ways that the best international schools in
                                Hazaribagh suggest parents
                                <br />
                                can help their children improve their social
                                skills during school
                                <br />
                                holidays.
                              </p>
                              <p>
                                <a href="https://www.harvestinternationalschool.in/">
                                  <strong>
                                    The Best International Schools In Hazaribagh
                                  </strong>
                                </a>{" "}
                                Help You Understanding Your Child’s Social Needs
                              </p>
                              <p>
                                Before
                                <br />
                                we explore ways to improve your child’s social
                                skills, it’s important
                                <br />
                                to understand their social development
                                milestones. Social development
                                <br />
                                milestones vary from child to child, but
                                generally, children develop
                                <br />
                                social skills in a predictable sequence. Infants
                                begin by responding to
                                <br />
                                social stimuli such as voices and faces, while
                                toddlers start to
                                <br />
                                communicate and play with others. Preschoolers
                                learn to share, take
                                <br />
                                turns, and negotiate with their peers, and as
                                they grow older, they
                                <br />
                                develop more complex social skills such as
                                empathy, conflict resolution,
                                <br />
                                and leadership.
                              </p>
                              <p>
                                The Best
                                <br />
                                International Schools In Bangalore say that if
                                you notice that your
                                <br />
                                child is struggling with socializing, it may be
                                a sign that they need
                                <br />
                                extra support. Signs that your child may need
                                support in socializing are
                                <br />
                                easy to spot; they include difficulty making
                                friends, avoiding social
                                <br />
                                situations, and struggling to communicate
                                effectively. Several factors
                                <br />
                                can affect your child’s social skills, such as
                                temperament, family
                                <br />
                                dynamics, and school environment. This is
                                something that the best
                                <br />
                                international schools in Hazaribagh keep an eye
                                on.
                              </p>
                              <p>
                                The Best International Schools in Hazaribagh
                                Recommend Activities to Improve Social Skills
                                During School Holidays
                              </p>
                              <p>
                                <strong>
                                  Here are some activities that parents can try:
                                </strong>
                              </p>
                              <ul>
                                <li
                                  style={{ "font-weight": "400" }}
                                  aria-level={1}
                                >
                                  <strong>Playdates with peers –</strong>
                                  <br />
                                  Having playdates with their friends or
                                  classmates can help them develop
                                  <br />
                                  social skills, such as sharing, taking turns,
                                  and communication. This
                                  <br />
                                  will give them a chance to socialize in a safe
                                  and familiar environment.
                                </li>
                                <li
                                  style={{ "font-weight": "400" }}
                                  aria-level={1}
                                >
                                  <strong>
                                    Group activities and team sports –
                                  </strong>
                                  <br />
                                  Signing up for group activities such as dance
                                  classes, music lessons,
                                  <br />
                                  or team sports will help your child learn
                                  teamwork, cooperation, and
                                  <br />
                                  leadership. These activities will not only
                                  improve their social skills
                                  <br />
                                  but also promote physical fitness and overall
                                  well-being.
                                </li>
                              </ul>
                              <ul>
                                <li
                                  style={{ "font-weight": "400" }}
                                  aria-level={1}
                                >
                                  <strong>
                                    Volunteering and community service –
                                  </strong>
                                  <br />
                                  Encourage your child to volunteer for
                                  community service to develop
                                  <br />
                                  their empathy, kindness, and compassion. This
                                  will not only expose them
                                  <br />
                                  to new experiences but also help them develop
                                  a sense of responsibility
                                  <br />
                                  towards others.
                                </li>
                              </ul>
                              <ul>
                                <li
                                  style={{ "font-weight": "400" }}
                                  aria-level={1}
                                >
                                  <strong>
                                    Role-playing and imaginative play –
                                  </strong>
                                  <br />
                                  Use role-playing and imaginative play to help
                                  your child develop their
                                  <br />
                                  social skills. Pretend to be different
                                  characters and act out social
                                  <br />
                                  scenarios to help them learn how to respond in
                                  various situations. This
                                  <br />
                                  will help your child develop skills such as
                                  problem-solving, creativity,
                                  <br />
                                  and emotional regulation.
                                </li>
                              </ul>
                              <p>
                                Strategies Recommended By The Best International
                                Schools in Hazaribagh for Encouraging Social
                                Interaction
                              </p>
                              <p>
                                In
                                <br />
                                addition to engaging their child in social
                                activities, parents can also
                                <br />
                                facilitate positive interactions and encourage
                                socialization by using
                                <br />
                                the following strategies:
                              </p>
                              <ul>
                                <li
                                  style={{ "font-weight": "400" }}
                                  aria-level={1}
                                >
                                  <strong>
                                    Modelling social skills and behaviour –
                                  </strong>
                                  <br />
                                  Children learn by example, observing their
                                  parents’ behaviour. Ensure
                                  <br />
                                  that you model positive social skills and
                                  behaviour that can help your
                                  <br />
                                  child learn how to communicate effectively,
                                  show empathy, and resolve
                                  <br />
                                  conflicts.
                                </li>
                              </ul>
                              <ul>
                                <li
                                  style={{ "font-weight": "400" }}
                                  aria-level={1}
                                >
                                  <strong>
                                    Facilitating positive interactions –
                                  </strong>
                                  <br />
                                  Encourage your child to interact positively
                                  with others, such as using
                                  <br />
                                  polite language, showing interest in others,
                                  and practising active
                                  <br />
                                  listening, which can help them develop social
                                  skills. Provide your child
                                  <br />
                                  opportunities to socialize and guide them
                                  through social situations.
                                </li>
                              </ul>
                              <ul>
                                <li
                                  style={{ "font-weight": "400" }}
                                  aria-level={1}
                                >
                                  <strong>
                                    Encouraging communication and active
                                    listening –
                                  </strong>
                                  <br />
                                  Teaching your child how to communicate
                                  effectively and listen actively
                                  <br />
                                  can improve their social skills, help them
                                  build stronger relationships
                                  <br />
                                  and make it easier for them to express their
                                  thoughts and feelings.
                                </li>
                                <li
                                  style={{ "font-weight": "400" }}
                                  aria-level={1}
                                >
                                  <strong>
                                    Building empathy and understanding –
                                  </strong>
                                  <br />
                                  Help your child develop empathy and
                                  understanding towards others by
                                  <br />
                                  teaching them to consider other people’s
                                  feelings and perspectives. This
                                  <br />
                                  can help them develop social skills such as
                                  kindness, compassion, and
                                  <br />
                                  respect.
                                </li>
                              </ul>
                              <p>
                                How The Best International Schools in Hazaribagh
                                Help You Support Your Child’s Social Development
                                at Home
                              </p>
                              <p>
                                Parents play a critical role in supporting their
                                child’s social development. Here are some ways
                                you can help your child at home:
                              </p>
                              <ul>
                                <li
                                  style={{ "font-weight": "400" }}
                                  aria-level={1}
                                >
                                  <strong>
                                    Encouraging open communication –
                                  </strong>
                                  <br />
                                  Encourage your child to talk about their
                                  feelings and experiences and
                                  <br />
                                  listen attentively without judgment. Creating
                                  a safe and open
                                  <br />
                                  environment where your child feels comfortable
                                  expressing their thoughts
                                  <br />
                                  and feelings can help them develop
                                  communication and social skills.
                                  <br />
                                  This will help them build trust and confidence
                                  in their ability to
                                  <br />
                                  communicate effectively.
                                </li>
                              </ul>
                              <ul>
                                <li
                                  style={{ "font-weight": "400" }}
                                  aria-level={1}
                                >
                                  <strong>
                                    Providing opportunities for socialization –
                                  </strong>
                                  <br />
                                  Creating opportunities for your child to
                                  socialize with family,
                                  <br />
                                  friends, and peers by organizing family
                                  outings, hosting playdates, and
                                  <br />
                                  attending community events. It can help them
                                  develop social skills and
                                  <br />
                                  build relationships.
                                </li>
                              </ul>
                              <ul>
                                <li
                                  style={{ "font-weight": "400" }}
                                  aria-level={1}
                                >
                                  <strong>
                                    Building social skills through family
                                    activities –
                                  </strong>
                                  <br />
                                  Incorporating activities into your family
                                  routine that promote
                                  <br />
                                  socialization and teamwork, such as playing
                                  board games, cooking
                                  <br />
                                  together, and doing chores, can help your
                                  child develop social skills,
                                  <br />
                                  such as teamwork and communication.
                                </li>
                                <li
                                  style={{ "font-weight": "400" }}
                                  aria-level={1}
                                >
                                  <strong>
                                    Providing support and guidance –
                                  </strong>
                                  <br />
                                  Providing emotional support and guidance, such
                                  as helping them practice
                                  <br />
                                  conflict resolution skills or role-playing
                                  social scenarios, can help
                                  <br />
                                  your child develop social skills, build
                                  self-esteem, and navigate social
                                  <br />
                                  situations.
                                </li>
                              </ul>
                              <p>
                                How The Best International Schools in Hazaribagh
                                Help You Over Come Challenges and Offer
                                Solutions in Promoting Social Skills
                              </p>
                              <p>
                                Parents
                                <br />
                                may face challenges when promoting their child’s
                                social skills, such as
                                <br />
                                shyness, anxiety, bullying, and exclusion. Here
                                are some solutions to
                                <br />
                                overcome these challenges:
                              </p>
                              <ul>
                                <li
                                  style={{ "font-weight": "400" }}
                                  aria-level={1}
                                >
                                  <strong>
                                    Overcoming shyness and anxiety:
                                  </strong>
                                  <br />
                                  If your child is shy or anxious, encourage
                                  them to practice socializing
                                  <br />
                                  gradually by starting with small, low-pressure
                                  situations, such as
                                  <br />
                                  playing with a familiar friend.
                                </li>
                              </ul>
                              <ul>
                                <li
                                  style={{ "font-weight": "400" }}
                                  aria-level={1}
                                >
                                  <strong>
                                    Addressing bullying and exclusion:
                                  </strong>
                                  <br />
                                  Teach your child how to recognize and respond
                                  to bullying and exclusion
                                  <br />
                                  by discussing appropriate responses and
                                  encouraging them to report
                                  <br />
                                  incidents to a trusted adult.
                                </li>
                                <li
                                  style={{ "font-weight": "400" }}
                                  aria-level={1}
                                >
                                  <strong>
                                    Working with special needs children:
                                  </strong>
                                  <br />
                                  Children with special needs may require extra
                                  support and
                                  <br />
                                  accommodations to develop social skills. Work
                                  with their teachers and
                                  <br />
                                  therapists to identify appropriate strategies
                                  and activities.
                                </li>
                              </ul>
                              <p>
                                In
                                <br />
                                conclusion, social skills are essential for a
                                child’s development and
                                <br />
                                future success. By providing opportunities for
                                socialization, modelling
                                <br />
                                positive social skills and behaviour, and using
                                strategies to encourage
                                <br />
                                positive social interaction, parents can support
                                their child’s social
                                <br />
                                development during school holidays.
                              </p>
                            </div>
                            <div className="entry-tag-share">
                              <div className="row">
                                <div className="col-sm-6"></div>
                                <div className="col-sm-6">
                                  <ul className="thim-social-share">
                                    <li className="heading">Share:</li>
                                    <li>
                                      <div className="facebook-social">
                                        <a
                                          target="_blank"
                                          className="facebook"
                                          href="https://www.facebook.com/sharer.php?u=https%3A%2F%2Fdavsanford.com%2Fways-to-improve-the-social-skills-of-your-children-during-the-school-holidays%2F"
                                          title="Facebook"
                                        >
                                          <i className="fa fa-facebook" />
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="twitter-social">
                                        <a
                                          target="_blank"
                                          className="twitter"
                                          href="https://twitter.com/share?url=https%3A%2F%2Fdavsanford.com%2Fways-to-improve-the-social-skills-of-your-children-during-the-school-holidays%2F&text=Ways%20To%20Improve%20the%20Social%20Skills%20Of%20Your%20Children%20During%20The%20School%20Holidays"
                                          title="Twitter"
                                        >
                                          <i className="fa fa-twitter" />
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="pinterest-social">
                                        <a
                                          target="_blank"
                                          className="pinterest"
                                          href="../../pinterest.com/pin/create/button/index38c9.html?url=https%3A%2F%2Fdavsanford.com%2Fways-to-improve-the-social-skills-of-your-children-during-the-school-holidays%2F&description=As%20parents%2C%20you%20want%20your%20children%20to%20succeed%20and%20thrive%20in%20life.%20However%2C%20often%20focus%20is%20given%20to%20academic%20achievements%20and%20the%20importance%20of%20social%20skills%20is%20neglected.%20Social%20skills%20are%20essential%20for%20children%E2%80%99s%20development%20and%20future%20success.%20These%20skills%20%26hellip%3B%20&media=https%3A%2F%2Fdavsanford.com%2Fwp-content%2Fuploads%2F2023%2F05%2Fholiday.jpg"
                                          onclick="window.open(this.href); return false;"
                                          title="Pinterest"
                                        >
                                          <i className="fa fa-pinterest-p" />
                                        </a>
                                      </div>
                                    </li>
                                  </ul>{" "}
                                </div>
                              </div>
                            </div>
                            <div className="thim-about-author">
                              <div className="author-wrapper">
                                <div className="author-avatar">
                                  <a href="../author/sitemanager/index.html">
                                    <img
                                      alt="User Avatar"
                                      src="https://secure.gravatar.com/avatar/63fa2f7d8bdbd85ae29ad55a9c770266?s=96&d=mm&r=g"
                                      height={110}
                                      width={110}
                                    />{" "}
                                  </a>
                                </div>
                                <div className="author-bio">
                                  <div className="author-top">
                                    <a
                                      className="name"
                                      href="../author/sitemanager/index.html"
                                    >
                                      sitemanager{" "}
                                    </a>
                                  </div>
                                  <div className="author-description"></div>
                                </div>
                              </div>
                            </div>
                            <div className="entry-navigation-post">
                              <div className="prev-post">
                                <p className="heading">Previous post</p>
                                <h5 className="title">
                                  <a href="../why-you-should-read-every-day/index.html">
                                    5 Steps Essential To Be Taken For The
                                    Holistic Growth of Children in 2023
                                  </a>
                                </h5>
                                <div className="date">May 14, 2023 </div>
                              </div>
                              <div className="next-post">
                                <p className="heading">Next post</p>
                                <h5 className="title">
                                  <a href="../why-choose-cbse-board-for-your-child/index.html">
                                    Why choose CBSE Board for your child?
                                  </a>
                                </h5>
                                <div className="date">June 7, 2023 </div>
                              </div>
                            </div>
                            <section className="related-archive">
                              <h3 className="single-title">
                                You may also like
                              </h3>
                              <div className="archived-posts">
                                <div
                                  className="thim-carousel-wrapper"
                                  data-visible={3}
                                  data-itemtablet={2}
                                  data-itemmobile={1}
                                  data-pagination={1}
                                >
                                  {" "}
                                  <div className="post-3696 post type-post status-publish format-standard has-post-thumbnail hentry category-student-corner tag-wordpress">
                                    <div className="category-posts clear">
                                      <a
                                        href="../why-you-should-read-every-day/index.html"
                                        title="5 Steps Essential To Be Taken For The Holistic Growth of Children in 2023"
                                      >
                                        <img
                                          src="../wp-content/uploads/2023/05/5-step-300x200.jpg"
                                          alt="5 step"
                                          title="5 step"
                                          width={300}
                                          height={200}
                                        />
                                      </a>
                                      <div className="rel-post-text">
                                        <h5 className="title-related ">
                                          <a
                                            href="../why-you-should-read-every-day/index.html"
                                            rel="bookmark"
                                          >
                                            5 Steps Essential To Be Taken For
                                            The Holistic Growth of Children in
                                            2023
                                          </a>
                                        </h5>{" "}
                                        <div className="date">
                                          14 May, 2023{" "}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>{" "}
                            </section>
                            {/*.related*/}
                          </div>
                        </article>
                        <div id="comments" className="comments-area">
                          <div className="comment-respond-area">
                            <div id="respond" className="comment-respond">
                              <h3
                                id="reply-title"
                                className="comment-reply-title"
                              >
                                Leave A Reply{" "}
                                <small>
                                  <a
                                    rel="nofollow"
                                    id="cancel-comment-reply-link"
                                    href="index.html#respond"
                                    style={{ display: "none" }}
                                  >
                                    Cancel reply
                                  </a>
                                </small>
                              </h3>
                              <form
                                action="https://davsanford.com/wp-comments-post.php"
                                method="post"
                                id="commentform"
                                className="comment-form"
                                noValidate
                              >
                                <p className="comment-notes">
                                  <span id="email-notes">
                                    Your email address will not be published.
                                  </span>{" "}
                                  <span className="required-field-message">
                                    Required fields are marked{" "}
                                    <span className="required">*</span>
                                  </span>
                                </p>
                                <p className="comment-form-comment">
                                  <textarea
                                    placeholder="Comment *"
                                    id="comment"
                                    name="comment"
                                    cols={45}
                                    rows={8}
                                    aria-required="true"
                                    defaultValue={""}
                                  />
                                </p>
                                <p className="comment-form-author">
                                  <input
                                    placeholder="Name *"
                                    id="author"
                                    name="author"
                                    type="text"
                                    defaultValue
                                    size={30}
                                    aria-required="true"
                                  />
                                </p>
                                <p className="comment-form-email">
                                  <input
                                    placeholder="Email *"
                                    id="email"
                                    name="email"
                                    type="text"
                                    defaultValue
                                    size={30}
                                    aria-required="true"
                                  />
                                </p>
                                <p className="comment-form-url">
                                  <input
                                    placeholder="Website *"
                                    id="url"
                                    name="url"
                                    type="text"
                                    defaultValue
                                    size={30}
                                    aria-required="true"
                                  />
                                </p>
                                <p className="form-submit">
                                  <input
                                    name="submit"
                                    type="submit"
                                    id="submit"
                                    className="submit"
                                    defaultValue="Post Comment"
                                  />{" "}
                                  <input
                                    type="hidden"
                                    name="comment_post_ID"
                                    defaultValue={11202}
                                    id="comment_post_ID"
                                  />
                                  <input
                                    type="hidden"
                                    name="comment_parent"
                                    id="comment_parent"
                                    defaultValue={0}
                                  />
                                </p>
                              </form>{" "}
                            </div>
                            {/* #respond */}
                          </div>
                          <div className="clear" />
                        </div>
                        {/* #comments */}
                      </div>
                    </main>
                    <div
                      id="sidebar"
                      className="widget-area col-sm-3 sticky-sidebar"
                      role="complementary"
                    >
                      <aside
                        id="nav_menu-16"
                        className="widget widget_nav_menu"
                      >
                        <h4 className="widget-title">Important Link</h4>
                        <div className="menu-sidebar-about-container">
                          <ul id="menu-sidebar-about" className="menu">
                            <li
                              id="menu-item-11492"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11492"
                            >
                              <a href="../about-d-a-v-sanford/index.html">
                                About D.A.V Sanford
                              </a>
                            </li>
                            <li
                              id="menu-item-11488"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11488"
                            >
                              <a href="../principals-message/index.html">
                                Principal’s Message
                              </a>
                            </li>
                            <li
                              id="menu-item-11491"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11491"
                            >
                              <a href="../vision-mission/index.html">
                                Vision &amp; Mission
                              </a>
                            </li>
                            <li
                              id="menu-item-11489"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11489"
                            >
                              <a href="../smart-class/index.html">
                                Smart Class
                              </a>
                            </li>
                            <li
                              id="menu-item-11484"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11484"
                            >
                              <a href="../art-craft/index.html">
                                Art &amp; Craft
                              </a>
                            </li>
                            <li
                              id="menu-item-11485"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11485"
                            >
                              <a href="../guideliness/index.html">Guidelines</a>
                            </li>
                            <li
                              id="menu-item-11486"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11486"
                            >
                              <a href="../laboratory/index.html">Laboratory</a>
                            </li>
                            <li
                              id="menu-item-11487"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11487"
                            >
                              <a href="../library/index.html">Library</a>
                            </li>
                            <li
                              id="menu-item-11490"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11490"
                            >
                              <a href="../transportation/index.html">
                                Transportation
                              </a>
                            </li>
                          </ul>
                        </div>
                      </aside>
                      <aside
                        id="list-post-2"
                        className="widget widget_list-post"
                      >
                        <div className="thim-widget-list-post template-list-post">
                          <h3 className="widget-title">Latest Posts</h3>
                          <div className="sc-list-post thim-list-posts sidebar">
                            <div className="item-post has_thumb post-3696 post type-post status-publish format-standard has-post-thumbnail hentry category-student-corner tag-wordpress">
                              <div className="article-image image">
                                <a href="../why-you-should-read-every-day/index.html">
                                  <img
                                    width={150}
                                    height={150}
                                    src="../wp-content/uploads/2023/05/5-step-150x150.jpg"
                                    className="attachment-thumbnail size-thumbnail wp-post-image"
                                    alt
                                    decoding="async"
                                    loading="lazy"
                                    srcSet="https://davsanford.com/wp-content/uploads/2023/05/5-step-150x150.jpg 150w, https://davsanford.com/wp-content/uploads/2023/05/5-step-300x300.jpg 300w, https://davsanford.com/wp-content/uploads/2023/05/5-step-100x100.jpg 100w, https://davsanford.com/wp-content/uploads/2023/05/5-step-350x350.jpg 350w, https://davsanford.com/wp-content/uploads/2023/05/5-step-420x420.jpg 420w"
                                    sizes="(max-width: 150px) 100vw, 150px"
                                  />
                                </a>
                              </div>
                              <div className="article-title-wrapper">
                                <h5 className="title">
                                  <a
                                    href="../why-you-should-read-every-day/index.html"
                                    className="article-title"
                                  >
                                    5 Steps Essential To Be Taken For The
                                    Holistic Growth of Children in 2023
                                  </a>
                                </h5>
                                <div className="article-date">
                                  <span className="day">14</span>
                                  <span className="month">May</span>
                                  <span className="year">2023</span>
                                </div>
                              </div>
                            </div>
                            <div className="item-post has_thumb post-11988 post type-post status-publish format-standard has-post-thumbnail hentry category-school-life">
                              <div className="article-image image">
                                <a href="../why-choose-cbse-board-for-your-child/index.html">
                                  <img
                                    width={150}
                                    height={150}
                                    src="../wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-150x150.jpeg"
                                    className="attachment-thumbnail size-thumbnail wp-post-image"
                                    alt
                                    decoding="async"
                                    loading="lazy"
                                    srcSet="https://davsanford.com/wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-150x150.jpeg 150w, https://davsanford.com/wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-300x300.jpeg 300w, https://davsanford.com/wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-100x100.jpeg 100w, https://davsanford.com/wp-content/uploads/2023/06/WhatsApp-Image-2023-04-11-at-16.43.12-1-1024x683-1-350x350.jpeg 350w"
                                    sizes="(max-width: 150px) 100vw, 150px"
                                  />
                                </a>
                              </div>
                              <div className="article-title-wrapper">
                                <h5 className="title">
                                  <a href="#" className="article-title">
                                    Why choose CBSE Board for your child?
                                  </a>
                                </h5>
                                <div className="article-date">
                                  <span className="day">07</span>
                                  <span className="month">Jun</span>
                                  <span className="year">2023</span>
                                </div>
                              </div>
                            </div>
                            <div className="item-post has_thumb post-11202 post type-post status-publish format-standard has-post-thumbnail hentry category-student-corner">
                              <div className="article-image image">
                                <a href="index.html">
                                  <img
                                    width={150}
                                    height={150}
                                    src="../wp-content/uploads/2023/05/holiday-150x150.jpg"
                                    className="attachment-thumbnail size-thumbnail wp-post-image"
                                    alt
                                    decoding="async"
                                    loading="lazy"
                                    srcSet="https://davsanford.com/wp-content/uploads/2023/05/holiday-150x150.jpg 150w, https://davsanford.com/wp-content/uploads/2023/05/holiday-300x300.jpg 300w, https://davsanford.com/wp-content/uploads/2023/05/holiday-100x100.jpg 100w, https://davsanford.com/wp-content/uploads/2023/05/holiday-350x350.jpg 350w, https://davsanford.com/wp-content/uploads/2023/05/holiday-420x420.jpg 420w"
                                    sizes="(max-width: 150px) 100vw, 150px"
                                  />
                                </a>
                              </div>
                              <div className="article-title-wrapper">
                                <h5 className="title">
                                  <a
                                    href="index.html"
                                    className="article-title"
                                  >
                                    Ways To Improve the Social Skills Of Your
                                    Children During The School Holidays
                                  </a>
                                </h5>
                                <div className="article-date">
                                  <span className="day">14</span>
                                  <span className="month">May</span>
                                  <span className="year">2023</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </aside>
                    </div>
                    {/* #secondary */}
                  </div>
                </div>
              </section>
              <Footer />
              {/* #colophon */}
            </div>
            {/*end main-content*/}
          </div>
          {/* end content-pusher*/}
        </div>
        {/* end wrapper-container */}
      </div>
    </div>
  );
}

export default index;
