import Footer from "../footer/footer";
import Header from "../header/header";
import Fee from "../fee-structure/index";
import { Link } from "react-router-dom";
import Demo from "../Demo";

import axios from "axios";

import React, { useState, useEffect } from "react";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Typography,
  Box,
  Button,
  Card,
  Container,
  CardContent,
} from "@mui/material";

import {
  TextField,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { styled } from "@mui/material";

import "react-toastify/dist/ReactToastify.css";

const CustomBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  backgroundColor: "white",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
}));

function Index() {
  const [admissionData, setAdmissionData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://lyci0i0cnf.execute-api.ap-south-1.amazonaws.com/sanford/sanford_admission_form_all_data"
        );
        setAdmissionData(response.data["body-json"].body.Item);
        console.log(response.data["body-json"].body.Item);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // const initialFormData = {
  //   name: "",
  //   phone: "",
  //   email: "",
  //   gender: [],
  //   academic_year: "",
  //   standard: "",
  //   current_school: "",
  // };

  // const [formData, setFormData] = useState(initialFormData);
  const [responseData, setResponseData] = useState(null);

  // const handleChange = (e) => {
  //   const { name, value, type, checked } = e.target;
  //   if (type === "checkbox") {
  //     const updatedGender = checked

  //       : formData.gender.filter((gender) => gender !== value);
  //     setFormData({ ...formData, [name]: updatedGender });
  //   } else {
  //     setFormData({ ...formData, [name]: value });
  //   }
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const response = await axios.post(
  //       "https://lyci0i0cnf.execute-api.ap-south-1.amazonaws.com/sanford/sanford_admission_enquiry",
  //       formData
  //     ); // Replace with your API endpoint
  //     setResponseData(response.data);
  //     console.log(response.data);
  //     toast.success("Admission inquiry submitted successfully!", {
  //       position: "bottom-right",
  //       autoClose: 3000,
  //     });

  //     setFormData(initialFormData);
  //   } catch (error) {
  //     console.error("API call error:", error);
  //   }
  // };

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    gender: "", // Change to string to store selected gender
    academic_year: "",
    standard: "",
    current_school: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://lyci0i0cnf.execute-api.ap-south-1.amazonaws.com/sanford/sanford_admission_enquiry",
        formData
      ); // Replace with your API endpoint
      setResponseData(response.data);
      console.log(response.data);
      toast.success("Admission inquiry submitted successfully!", {
        position: "bottom-right",
        autoClose: 3000,
      });
    } catch (error) {
      console.error("API call error:", error);
    }

    // Clear form fields after submission
    setFormData({
      name: "",
      phone: "",
      email: "",
      gender: "",
      academic_year: "",
      standard: "",
      current_school: "",
    });
  };
  return (
    <div>
      <>
        <Header />
        <div id="wrapper-container" className="wrapper-container">
          <div className="content-pusher">
            <div id="main-content">
              <div
                data-elementor-type="wp-page"
                data-elementor-id={10477}
                className="elementor elementor-10477"
              >
                <Demo />
                <section
                  className="elementor-section elementor-top-section elementor-element elementor-element-7a9082c1 elementor-section-height-min-height elementor-section-items-bottom elementor-section-stretched elementor-section-boxed elementor-section-height-default"
                  data-id="7a9082c1"
                  data-element_type="section"
                  data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
                >
                  <div className="elementor-background-overlay" />
                  <div className="elementor-container elementor-column-gap-custom">
                    <div
                      className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2f5b861b"
                      data-id="2f5b861b"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-79738deb elementor-widget thim-ekits-heading elementor-widget-thim-heading"
                          data-id="79738deb"
                          data-element_type="widget"
                          data-widget_type="thim-heading.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="sc_heading ">
                              <h3 className="title">
                                Apply To D.A.V Sanford Public School
                              </h3>
                              <div className="sub-heading">
                                <p>
                                  Educating all students to achieve today and
                                  tomorrow in a global community and economy
                                </p>
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  className="elementor-section elementor-top-section elementor-element elementor-element-a6067bc elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="a6067bc"
                  data-element_type="section"
                  style={{ marginTop: 50 }}
                >
                  <div className="elementor-container elementor-column-gap-custom">
                    <div
                      className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-66f4c577"
                      data-id="66f4c577"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-2c11aec3 elementor-widget elementor-widget-thim-ekits-breadcrumb"
                          data-id="2c11aec3"
                          data-element_type="widget"
                          data-widget_type="thim-ekits-breadcrumb.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="thim-ekit-breadcrumb">
                              <Link to="/">Home</Link>
                              <span className="thim-ekit-breadcrumb__delimiter">
                                <i
                                  aria-hidden="true"
                                  className="fas fa-chevron-right"
                                />
                              </span>
                              Online Admission
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  className="elementor-section elementor-top-section elementor-element elementor-element-fe51036 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="fe51036"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-default">
                    <div
                      className="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-5528ebf"
                      data-id="5528ebf"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-418e36f elementor-widget elementor-widget-heading"
                          data-id="418e36f"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div className="elementor-widget-container">
                            <h2 className="elementor-heading-title elementor-size-default">
                              {" "}
                              Admission Enquiry
                            </h2>{" "}
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-c64f34c elementor-widget elementor-widget-text-editor"
                          data-id="c64f34c"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          {/* <div className="elementor-widget-container">
                            <div className="fluentform fluentform_wrapper_6">
                              <div>
                                <form onSubmit={handleSubmit}>
                                  <div className="ff-el-group">
                                    <div className="ff-el-input--label ff-el-is-required asterisk-right">
                                      <label
                                        htmlFor="ff_6_names_first_name_"
                                        aria-label="Student Name"
                                      >
                                        Student Name
                                      </label>
                                    </div>
                                    <div className="ff-el-input--content">
                                      <input
                                        type="text"
                                        name="name"
                                        id="ff_6_names_first_name_"
                                        className="ff-el-form-control"
                                        placeholder="First Name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        style={{ width: "100%" }} // Increase input size
                                        required
                                      />
                                    </div>
                                  </div>

                                  <div className="ff-el-group">
                                    <div className="ff-el-input--label ff-el-is-required asterisk-right">
                                      <label
                                        htmlFor="ff_6_phone"
                                        aria-label="Phone"
                                      >
                                        Phone
                                      </label>
                                    </div>
                                    <div className="ff-el-input--content">
                                      <input
                                        type="text"
                                        name="phone"
                                        id="ff_6_phone"
                                        className="ff-el-form-control"
                                        placeholder="Enter phone Number"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        style={{ width: "100%" }} // Increase input size
                                        required
                                      />
                                    </div>
                                  </div>

                                  <div className="ff-el-group">
                                    <div className="ff-el-input--label ff-el-is-required asterisk-right">
                                      <label
                                        htmlFor="ff_6_email"
                                        aria-label="Email"
                                      >
                                        Email
                                      </label>
                                    </div>
                                    <div className="ff-el-input--content">
                                      <input
                                        type="email"
                                        name="email"
                                        id="ff_6_email"
                                        className="ff-el-form-control"
                                        placeholder="Email Address"
                                        value={formData.email}
                                        onChange={handleChange}
                                        style={{ width: "100%" }} // Increase input size
                                        required
                                      />
                                    </div>
                                  </div>

                                  <div className="ff-el-group ff-el-form-left">
                                    <div className="ff-el-input--label ff-el-is-required asterisk-right">
                                      <label aria-label="Gender">Gender</label>
                                    </div>
                                    <div className="ff-el-input--content">
                                      <div className="ff-el-form-check ff-el-form-check-">
                                        <label
                                          className="ff-el-form-check-label"
                                          htmlFor="checkbox_035625fe970ec9d54aff54e50f6c6406"
                                        >
                                          <input
                                            type="checkbox"
                                            name="gender"
                                            data-name="gender"
                                            className="ff-el-form-check-input ff-el-form-check-checkbox"
                                            value="Male"
                                            id="checkbox_035625fe970ec9d54aff54e50f6c6406"
                                            checked={formData.gender.includes(
                                              "Male"
                                            )}
                                            onChange={handleChange}
                                          />{" "}
                                          <span>Male</span>
                                        </label>
                                      </div>
                                      <div className="ff-el-form-check ff-el-form-check-">
                                        <label
                                          className="ff-el-form-check-label"
                                          htmlFor="checkbox_14cc93e3088f49b64c5b63b921800f63"
                                        >
                                          <input
                                            type="checkbox"
                                            name="gender"
                                            data-name="gender"
                                            className="ff-el-form-check-input ff-el-form-check-checkbox"
                                            value="Female"
                                            id="checkbox_14cc93e3088f49b64c5b63b921800f63"
                                            checked={formData.gender.includes(
                                              "Female"
                                            )}
                                            onChange={handleChange}
                                          />{" "}
                                          <span>Female</span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    data-name="ff_cn_id_1"
                                    className="ff-t-container ff-column-container ff_columns_total_2"
                                  >
                                    <div
                                      className="ff-t-cell ff-t-column-1"
                                      style={{ flexBasis: "50%" }}
                                    >
                                      <div className="ff-el-group">
                                        <div className="ff-el-input--label ff-el-is-required asterisk-right">
                                          <label
                                            htmlFor="ff_6_dropdown"
                                            aria-label="Academic Year"
                                          >
                                            Academic Year
                                          </label>
                                        </div>
                                        <div className="ff-el-input--content">
                                          <select
                                            name="academic_year"
                                            id="ff_6_dropdown"
                                            className="ff-el-form-control"
                                            data-name="academic_year"
                                            data-calc_value={0}
                                            value={formData.academic_year}
                                            onChange={handleChange}
                                            style={{ width: "100%" }} // Increase input size
                                            required
                                          >
                                            <option value="">– Select –</option>
                                            <option value={2022}>2022</option>
                                            <option value={2021}>2021</option>
                                            <option value={2020}>2020</option>
                                            <option value={2019}>2019</option>
                                            <option value={2018}>2018</option>
                                            <option value={2017}>2017</option>
                                            <option value={2016}>2016</option>
                                            <option value={2015}>2015</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="ff-t-cell ff-t-column-2"
                                      style={{ flexBasis: "50%" }}
                                    >
                                      <div className="ff-el-group">
                                        <div className="ff-el-input--label ff-el-is-required asterisk-right">
                                          <label
                                            htmlFor="ff_6_dropdown_1"
                                            aria-label="Grade"
                                          >
                                            Grade
                                          </label>
                                        </div>
                                        <div className="ff-el-input--content">
                                          <select
                                            name="standard"
                                            id="ff_6_dropdown_1"
                                            className="ff-el-form-control"
                                            data-name="standard"
                                            data-calc_value={0}
                                            value={formData.standard}
                                            onChange={handleChange}
                                            style={{ width: "100%" }} // Increase input size
                                            required
                                          >
                                            <option value="">– Select –</option>
                                            <option value="I">I</option>
                                            <option value="II">II</option>
                                            <option value="III">III</option>
                                            <option value="IV">IV</option>
                                            <option value="V">V</option>
                                            <option value="VI">VI</option>
                                            <option value="VII">VII</option>
                                            <option value="VIII">VIII</option>
                                            <option value="IX">IX</option>
                                            <option value="X">X</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="ff-el-group">
                                    <div className="ff-el-input--label ff-el-is-required asterisk-right">
                                      <label
                                        htmlFor="ff_6_input_text"
                                        aria-label="School Currently studying in (with the curriculum)"
                                      >
                                        School Currently studying in (with the
                                        curriculum)
                                      </label>
                                    </div>
                                    <div className="ff-el-input--content">
                                      <input
                                        type="text"
                                        name="current_school"
                                        className="ff-el-form-control"
                                        placeholder="School Currently studying"
                                        value={formData.current_school}
                                        onChange={handleChange}
                                        style={{ width: "100%" }} // Increase input size
                                        required
                                      />
                                    </div>
                                  </div>

                                  <div className="ff-el-group ff-text-left ff_submit_btn_wrapper">
                                    <button
                                      type="submit"
                                      className="ff-btn ff-btn-submit ff-btn-md ff_btn_style"
                                    >
                                      Submit Form
                                    </button>
                                  </div>
                                </form>
                                <ToastContainer />
                              </div>
                              <div
                                id="fluentform_6_errors"
                                className="ff-errors-in-stack ff_form_instance_6_1 ff-form-loading_errors ff_form_instance_6_1_errors"
                              />
                            </div>
                          </div> */}
                          <Box>
                            <CustomBox>
                              <CustomBox sx={{ flex: "1" }}>
                                <Grid
                                  container
                                  direction="column"
                                  sx={{ height: "100%" }}
                                >
                                  <Grid item xs={12} md={4}>
                                    <Box sx={{ p: 2 }}>
                                      <form onSubmit={handleSubmit}>
                                        <Grid direction="column">
                                          <Grid
                                            item
                                            xs={12}
                                            md={8}
                                            fontSize={15}
                                          >
                                            <div className="ff-el-input--label ff-el-is-required asterisk-right">
                                              <label
                                                htmlFor="ff_6_names_first_name_"
                                                aria-label="Student Name"
                                              >
                                                Student Name
                                              </label>
                                            </div>
                                            <TextField
                                              fullWidth
                                              name="name"
                                              value={formData.name}
                                              onChange={handleChange}
                                              margin="normal"
                                              required
                                              style={{ width: "100%" }}
                                              InputProps={{
                                                style: { fontSize: 15 },
                                              }}
                                              InputLabelProps={{
                                                style: { fontSize: 15 },
                                              }}
                                            />
                                            <div className="ff-el-input--label ff-el-is-required asterisk-right">
                                              <label
                                                htmlFor="ff_6_phone"
                                                aria-label="Phone"
                                              >
                                                Phone
                                              </label>
                                            </div>
                                            <TextField
                                              fullWidth
                                              name="phone"
                                              value={formData.phone}
                                              onChange={handleChange}
                                              margin="normal"
                                              required
                                              style={{ width: "100%" }}
                                              InputProps={{
                                                style: { fontSize: 15 },
                                              }}
                                              InputLabelProps={{
                                                style: { fontSize: 15 },
                                              }}
                                            />
                                            <div className="ff-el-input--label ff-el-is-required asterisk-right">
                                              <label
                                                htmlFor="ff_6_email"
                                                aria-label="Email"
                                              >
                                                Email
                                              </label>
                                            </div>
                                            <TextField
                                              fullWidth
                                              name="email"
                                              value={formData.email}
                                              onChange={handleChange}
                                              margin="normal"
                                              required
                                              style={{ width: "100%" }}
                                              InputProps={{
                                                style: { fontSize: 15 },
                                              }}
                                              InputLabelProps={{
                                                style: { fontSize: 15 },
                                              }}
                                            />
                                            <div className="ff-el-input--label ff-el-is-required asterisk-right">
                                              <label aria-label="Gender">
                                                Gender
                                              </label>
                                            </div>
                                            <RadioGroup
                                              aria-label="Gender"
                                              name="gender"
                                              value={formData.gender}
                                              onChange={handleChange}
                                              style={{ flexDirection: "row" }}
                                            >
                                              <FormControlLabel
                                                value="Male"
                                                control={<Radio />}
                                                label="Male"
                                              />
                                              <FormControlLabel
                                                value="Female"
                                                control={<Radio />}
                                                label="Female"
                                              />
                                            </RadioGroup>
                                            <div className="ff-el-input--label ff-el-is-required asterisk-right">
                                              <label
                                                htmlFor="ff_6_dropdown"
                                                aria-label="Academic Year"
                                              >
                                                Academic Year
                                              </label>
                                            </div>
                                            <Select
                                              fullWidth
                                              name="academic_year"
                                              value={formData.academic_year}
                                              onChange={handleChange}
                                              margin="normal"
                                              required
                                              style={{ width: "100%" }}
                                              InputProps={{
                                                style: { fontSize: 15 },
                                              }}
                                              InputLabelProps={{
                                                style: { fontSize: 15 },
                                              }}
                                            >
                                              <MenuItem value="">
                                                – Select –
                                              </MenuItem>
                                              <MenuItem value={2022}>
                                                2022
                                              </MenuItem>
                                              <MenuItem value={2021}>
                                                2021
                                              </MenuItem>
                                              <MenuItem value={2020}>
                                                2020
                                              </MenuItem>
                                              <MenuItem value={2019}>
                                                2019
                                              </MenuItem>
                                              <MenuItem value={2018}>
                                                2018
                                              </MenuItem>
                                              <MenuItem value={2017}>
                                                2017
                                              </MenuItem>
                                              <MenuItem value={2016}>
                                                2016
                                              </MenuItem>
                                              <MenuItem value={2015}>
                                                2015
                                              </MenuItem>
                                            </Select>

                                            <div className="ff-el-input--label ff-el-is-required asterisk-right">
                                              <label
                                                htmlFor="ff_6_dropdown_1"
                                                aria-label="Grade"
                                              >
                                                Grade
                                              </label>
                                            </div>
                                            <Select
                                              fullWidth
                                              name="standard"
                                              value={formData.standard}
                                              onChange={handleChange}
                                              margin="normal"
                                              required
                                              style={{ width: "100%" }}
                                              InputProps={{
                                                style: { fontSize: 15 },
                                              }}
                                              InputLabelProps={{
                                                style: { fontSize: 15 },
                                              }}
                                            >
                                              <MenuItem value="">
                                                – Select –
                                              </MenuItem>
                                              <MenuItem value="I">I</MenuItem>
                                              <MenuItem value="II">II</MenuItem>
                                              <MenuItem value="III">
                                                III
                                              </MenuItem>
                                              <MenuItem value="IV">IV</MenuItem>
                                              <MenuItem value="V">V</MenuItem>
                                              <MenuItem value="VI">VI</MenuItem>
                                              <MenuItem value="VII">
                                                VII
                                              </MenuItem>
                                              <MenuItem value="VIII">
                                                VIII
                                              </MenuItem>
                                              <MenuItem value="IX">IX</MenuItem>
                                              <MenuItem value="X">X</MenuItem>
                                            </Select>
                                            <div className="ff-el-input--label ff-el-is-required asterisk-right">
                                              <label
                                                htmlFor="ff_6_input_text"
                                                aria-label="School Currently studying in (with the curriculum)"
                                              >
                                                School Currently studying in
                                                (with the curriculum)
                                              </label>
                                            </div>
                                            <TextField
                                              fullWidth
                                              name="current_school"
                                              value={formData.current_school}
                                              onChange={handleChange}
                                              margin="normal"
                                              required
                                              style={{ width: "100%" }}
                                              InputProps={{
                                                style: { fontSize: 15 },
                                              }}
                                              InputLabelProps={{
                                                style: { fontSize: 15 },
                                              }}
                                            />
                                            <Button
                                              variant="contained"
                                              type="submit"
                                            >
                                              Submit Form
                                            </Button>
                                          </Grid>
                                        </Grid>
                                      </form>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </CustomBox>
                            </CustomBox>
                            <ToastContainer
                              style={{
                                left: "50%",
                                right: "auto",
                                transform: "translateX(-50%)",
                              }}
                              position="bottom-center"
                              autoClose={3000}
                              hideProgressBar={true}
                            />
                          </Box>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-b9bd308"
                      data-id="b9bd308"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-2194094 elementor-widget thim-ekits-heading elementor-widget-thim-heading"
                          data-id={2194094}
                          data-element_type="widget"
                          data-widget_type="thim-heading.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="sc_heading ">
                              <h3 className="title">Admission Procedure</h3>
                              <span className="line" />
                            </div>{" "}
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-c19a4be elementor-widget elementor-widget-text-editor"
                          data-id="c19a4be"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div className="elementor-widget-container">
                            <ul>
                              <li>
                                <b>Step 1:</b> Parents fill the Enquiry Form on
                                our website
                              </li>
                              <li>
                                <b>Step 2:</b> Our school counsellor will
                                connect with the parent over call and share
                                information about the school. fee structure,
                                virtual counselling or phone counselling, campus
                                visit, etc.
                              </li>
                              <li>
                                <b>Step 3:</b> Meet with the Parent Counsellor
                              </li>
                              <li>
                                <b>Step 4:</b> Campus Tour
                              </li>
                              <li>
                                <b>Step 5:</b> Personal Interview with the
                                Principal
                              </li>
                              <li>
                                <b>Step 6:</b> Parents are then requested to
                                submit the Application Form for their child’s
                                school admission with all the necessary
                                documents.
                              </li>
                              <li>
                                <b>Step 7:</b> If the admission is for Grade 1
                                and above, the child has to undergo an online
                                Baseline Assessment Test.
                              </li>
                              <li>
                                <b>Step 8:</b> Parents can confirm the admission
                                by paying the Registration amount
                              </li>
                              <li>
                                <b>Step 9:</b> The child enrolment number is
                                generated and the admission process successfully
                                comes to an end.
                              </li>
                            </ul>{" "}
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-ca84534 elementor-invisible elementor-widget thim-widget-button"
                          data-id="ca84534"
                          data-element_type="widget"
                          data-settings='{"_animation":"fadeInRight"}'
                          data-widget_type="thim-button.default"
                        ></div>
                      </div>
                    </div>
                  </div>
                </section>

                <section
                  className="elementor-section elementor-top-section elementor-element elementor-element-9af5292 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="9af5292"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-default">
                    <div
                      className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-6691dd3"
                      data-id="6691dd3"
                      data-element_type="column"
                    >
                      <Box sx={{ display: "flex" }}>
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div
                            className="elementor-element elementor-element-64d38db elementor-widget elementor-widget-heading"
                            data-id="64d38db"
                            data-element_type="widget"
                            data-widget_type="heading.default"
                          >
                            <div className="elementor-widget-container">
                              <h2 className="elementor-heading-title elementor-size-default">
                                Why choose D.A.V Sanford Pulic School
                              </h2>{" "}
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-78c0db3 elementor-widget elementor-widget-text-editor"
                            data-id="78c0db3"
                            data-element_type="widget"
                            data-widget_type="text-editor.default"
                          >
                            <div className="elementor-widget-container">
                              <ul>
                                <li>We follow the values we teach</li>
                                <li>Focus on academics</li>
                                <li>Focus on Values</li>
                                <li>
                                  Learning enabled by technology and nature
                                </li>
                                <li>
                                  Groomed to be future leaders by visiting
                                  corporate trainers
                                </li>
                                <li>Learning inside &amp; outside classroom</li>
                                <li>
                                  We teach not just to earn a living, but to
                                  live
                                </li>
                              </ul>{" "}
                            </div>
                          </div>

                          <div className="right-section">
                            <Box sx={{ marginLeft: 4 }}>
                              {/* <Typography variant="h4" gutterBottom>
                              Admission Form Download
                            </Typography> */}
                              <div className="elementor-widget-container">
                                <h2 className="elementor-heading-title elementor-size-default">
                                  Admission Form Download
                                </h2>{" "}
                              </div>

                              <CardContent>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  href={admissionData.pdfUrl}
                                  target="_blank"
                                  style={{ marginBottom: "20px", fontSize: 20 }}
                                >
                                  Admission Form (PDF)
                                </Button>
                              </CardContent>
                            </Box>
                          </div>
                        </div>
                      </Box>
                    </div>

                    <div
                      className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-859d0d8"
                      data-id="859d0d8"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-cdd123e elementor-widget elementor-widget-video"
                          data-id="cdd123e"
                          data-element_type="widget"
                          data-settings='{"youtube_url":"https:\/\/www.youtube.com\/watch?v=sN5sBqlczxk","video_type":"youtube","controls":"yes"}'
                          data-widget_type="video.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-wrapper elementor-open-inline">
                              <div className="elementor-video" />{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <Fee />
                <section
                  className="elementor-section elementor-top-section elementor-element elementor-element-e5ec7ae elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="e5ec7ae"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-default">
                    <div
                      className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d144051"
                      data-id="d144051"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-d037bff elementor-widget thim-ekits-heading elementor-widget-thim-heading"
                          data-id="d037bff"
                          data-element_type="widget"
                          data-widget_type="thim-heading.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="sc_heading ">
                              <h3 className="title">About Us</h3>
                              <span className="line" />
                            </div>{" "}
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-747792a elementor-widget elementor-widget-text-editor"
                          data-id="747792a"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div className="elementor-widget-container">
                            <p>
                              D.A.V Sanford Public School is a co-educational
                              English medium Senior Secondary School which is
                              affiliated to Central Board of Secondary Education
                              (CBSE). Founded with the purpose to impart
                              intellectual and moral values to the students, the
                              school stands for its finest education platform.
                              The school provides wholesome and quality
                              education.
                            </p>
                            <p>
                              We are committed to provide our students enriched
                              experience to develop good habits, appropriate
                              attitudes and competitive skills that will help
                              them to have a stand in the world. We work with
                              the aim of providing an all-round development of
                              the child right from physical, social, emotional
                              to intellectual one.
                            </p>
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-031ed5b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="031ed5b"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div className="elementor-row">
                                  <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4df519e"
                                    data-id="4df519e"
                                    data-element_type="column"
                                  >
                                    <div className="elementor-column-wrap elementor-element-populated">
                                      <div className="elementor-widget-wrap">
                                        <div
                                          className="elementor-element elementor-element-684bdd5 elementor-widget elementor-widget-text-editor"
                                          data-id="684bdd5"
                                          data-element_type="widget"
                                          data-widget_type="text-editor.default"
                                        >
                                          <div className="elementor-widget-container">
                                            <div className="elementor-text-editor elementor-clearfix">
                                              <p>
                                                We focus on shared commitment to
                                                academic excellence,
                                                intellectual growth, ethical
                                                awareness and sportsmanship. The
                                                school upholds the Chairman’s
                                                commitment to excellence in all
                                                the fields. We aim to inculcate
                                                among students a love for
                                                learning and a burning desire to
                                                excel in every field. For that
                                                we aim to equip our students
                                                with intellectual and practical
                                                skills, necessary to meet the
                                                challenges in future. The values
                                                which are ingrained help to
                                                promote confidence, direction
                                                and critical thinking skills,
                                                leading to the development of
                                                well adjusted and integrated
                                                personalities. The school does
                                                not in any way, discriminate on
                                                the basis of race.
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-625712f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="625712f"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div className="elementor-row">
                                  <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f20ceba"
                                    data-id="f20ceba"
                                    data-element_type="column"
                                  >
                                    <div className="elementor-column-wrap elementor-element-populated">
                                      <div className="elementor-widget-wrap">
                                        <div
                                          className="elementor-element elementor-element-22f5377 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                                          data-id="22f5377"
                                          data-element_type="widget"
                                          data-widget_type="divider.default"
                                        >
                                          <div className="elementor-widget-container">
                                            <div className="elementor-divider">
                                              &nbsp;
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  className="elementor-section elementor-top-section elementor-element elementor-element-d07f903 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="d07f903"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-default">
                    <div
                      className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ab1ee62"
                      data-id="ab1ee62"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-d29ed7d elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                          data-id="d29ed7d"
                          data-element_type="widget"
                          data-widget_type="divider.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-divider">
                              <span className="elementor-divider-separator"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <Footer />
              {/* #colophon */}
            </div>
            {/*end main-content*/}
          </div>
          {/* end content-pusher*/}
        </div>
        {/* end wrapper-container */}

        <div className="gallery-slider-content" />
        {/* Call Now Button 1.4.1 (https://callnowbutton.com) [renderer:modern]*/}
      </>
    </div>
  );
}

export default Index;
