import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Container,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button,
} from "@mui/material";

function AcademicYearTable() {
  const [academicYearData, setAcademicYearData] = useState({});

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://lyci0i0cnf.execute-api.ap-south-1.amazonaws.com/sanford/sanford_class_fee_all_data"
        );
        if (response.status === 200) {
          setAcademicYearData(response.data["body-json"]["body"]);
          console.log(response.data["body-json"]["body"]);
        } else {
          console.error("Failed to fetch academic year data.");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Container>
      {academicYearData && Object.keys(academicYearData).length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: 25, fontFamily: "Roboto" }}>
                  Class
                </TableCell>
                <TableCell sx={{ fontSize: 25, fontFamily: "Roboto" }}>
                  Fee
                </TableCell>
                <TableCell sx={{ fontSize: 25, fontFamily: "Roboto" }}>
                  Academic Year
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(academicYearData).map((range) =>
                academicYearData[range].map((item, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ fontSize: 18 }}>{item.range}</TableCell>
                    <TableCell sx={{ fontSize: 18 }}>{item.fee}</TableCell>
                    <TableCell sx={{ fontSize: 18 }}>
                      {item.academic_year}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1">
          No academic year data available.
        </Typography>
      )}
    </Container>
  );
}

export default AcademicYearTable;
