import React from "react";
import Footer from "../footer/footer";
import Header from "../header/header";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import { Link } from "react-router-dom";
import AllNoticefile from "../AllNoticefile";
import Demo from "../Demo";

const Acadmic = () => {
  return (
    <div>
      <div id="wrapper-container" className="wrapper-container">
        <div className="content-pusher">
          <Header />
          <div id="main-content">
            <Container
              sx={{ padding: "2rem", minHeight: "100vh", marginTop: 10 }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Paper
                    sx={{
                      padding: "2rem",
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="h3" fontFamily={"Roboto"}>
                      Academics
                    </Typography>
                    <Typography fontSize={15}>
                      Notwithstanding the entire infrastructure available, a
                      school is ultimately only as good as its teachers. On this
                      point too, The D.A.V Sanford Public School scores highly.
                      Each faculty member has had an excellent academic career
                      coupled with professional training in teaching, besides
                      adequate work experience in some of the top schools of
                      India. The teachers stay on campus, which ensures their
                      accessibility even during late hours at the time of
                      examinations. As far as the academic curriculum goes,
                      SANFORD follows the CBSE syllabus for all its classes from
                      I to XII. All the three streams science, commerce, and
                      humanities are offered at SANFORD. Well-equipped
                      laboratories for different subjects are provided to ensure
                      that students get adequate exposure in their practical
                      classes. Special attention is also given to
                      weak/exceptional students. Besides these facilities, there
                      are provisions for professional coaching for various
                      competitive examinations like IIT, PMT, NDA, etc.
                    </Typography>
                    <Typography fontSize={15}>
                      To support English language skills amongst the young
                      children, the school prepares students for Cambridge ESOL
                      (YLE, KET, PET, and FCE) examinations which are the
                      world’s leading range of qualifications recognized by
                      thousands of employers, universities, and colleges. The
                      school is already an authorized center of the Cambridge
                      ESOL Examinations.
                    </Typography>
                    <Divider sx={{ marginY: "1rem" }} />
                    <Typography variant="h4" fontFamily={"Roboto"}>
                      D.A.V SANFORD PUBLIC SCHOOL
                    </Typography>
                    <Typography fontSize={15}>
                      We truly believe that education does not end at the
                      classroom doors; we take it beyond classrooms.
                    </Typography>
                    <img
                      src="./wp-content/uploads/2023/05/WhatsApp-Image-2023-05-13-at-4.38.33-PM.jpeg"
                      alt=""
                      sx={{
                        maxWidth: "100%",
                        height: "auto",
                        marginTop: "1rem",
                      }}
                    />
                  </Paper>
                </Grid>
                <Demo />
                <Grid item xs={12} sm={6}>
                  <Paper
                    sx={{
                      padding: "2rem",
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="h3" fontFamily={"Roboto"}>
                      Visit for more information and details
                    </Typography>
                    {/* Add your sidebar content here */}

                    <ul style={{ listStyleType: "none", padding: 0 }}>
                      <li>
                        <Link to="/Principal" target="_blank">
                          PRINCIPAL'S MESSAGE
                        </Link>
                      </li>
                      <li>
                        <Link to="/mission" target="_blank">
                          VISION & MISSION
                        </Link>
                      </li>
                      <li>
                        <Link to="/HorseRiding" target="_blank">
                          HORSE RIDING
                        </Link>
                      </li>
                      <li>
                        <Link to="/Swimming" target="_blank">
                          SWIMMING CLASS
                        </Link>
                      </li>
                      <li>
                        <Link to="/Smart" target="_blank">
                          SMART CLASS
                        </Link>
                      </li>
                      <li>
                        <Link to="/art" target="_blank">
                          ART & CRAFT
                        </Link>
                      </li>
                    </ul>
                  </Paper>
                  <AllNoticefile />
                </Grid>
              </Grid>
            </Container>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Acadmic;
