import React from "react";
import { Box, Typography, Container, Grid, Stack } from "@mui/material";
import { Facebook, Instagram, YouTube } from "@mui/icons-material";
import { Link } from "react-router-dom";
import P5 from "./images/p51NewLogo.png";
import HomeIcon from "@mui/icons-material/Home";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import LinkIcon from "@mui/icons-material/Link";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import MailIcon from "@mui/icons-material/Mail";

import LocationCityIcon from "@mui/icons-material/LocationCity";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Divider from "@mui/material";

function Footer() {
  return (
    <Box
      sx={{
        backgroundColor: "rgb(37,61,95)",

        paddingLeft: 2,
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <Box>
            <Box>
              <iframe
                loading="lazy"
                src="https://maps.google.com/maps?q=D.A.V%20sanford%20public%20school%2C%20Tati%20Jhariya%2C%20Jharkhand%20825313&t=m&z=12&output=embed&iwloc=near"
                title="D.A.V sanford public school, Tati Jhariya, Jharkhand 825313"
                aria-label="D.A.V sanford public school, Tati Jhariya, Jharkhand 825313"
              />
            </Box>
            <Typography
              variant="h4"
              sx={{ my: 1, color: "white", fontFamily: "Roboto" }}
            >
              D.A.V Sanford Public School
            </Typography>
            <Typography
              sx={{
                color: "white",
                mb: 2,
                mt: 2,
                fontSize: 17,
                fontFamily: "Poppins",
              }}
            >
              "D.A.V Sanford Public School we truly believe that education does
              not end at the classroom doors, we take it beyond classrooms."
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Typography
            variant="h4"
            sx={{ mb: 2, fontFamily: "Roboto", color: "White" }}
          >
            Site map
          </Typography>
          <ul
            style={{
              listStyle: "none",
              padding: 0,
              fontFamily: "Roboto",
              fontSize: 17,
            }}
          >
            {/* Set color to white for all link texts */}

            {/* ... other quick links ... */}
            <li>
              <Link to="/" sx={{ color: "white" }}>
                {" "}
                <Typography color={"white"} fontSize={17} fontFamily={"Roboto"}>
                  Home
                </Typography>
              </Link>
            </li>
            <li>
              <Link to="/about" target="_blank" sx={{ color: "white" }}>
                {" "}
                <Typography color={"white"} fontSize={17} fontFamily={"Roboto"}>
                  About Us
                </Typography>
              </Link>
            </li>
            <li>
              <Link to="/acadmic" target="_blank" sx={{ color: "white" }}>
                <Typography color={"white"} fontSize={17} fontFamily={"Roboto"}>
                  Academics
                </Typography>
              </Link>
            </li>
            <li>
              <Link to="/index" target="_blank" sx={{ color: "white" }}>
                <Typography color={"white"} fontSize={17} fontFamily={"Roboto"}>
                  Facility
                </Typography>
              </Link>
            </li>
            <li>
              <Link to="/admision" target="_blank" sx={{ color: "white" }}>
                <Typography color={"white"} fontSize={17} fontFamily={"Roboto"}>
                  Admission
                </Typography>
              </Link>
            </li>
            <li>
              <Link to="/Enq" target="_blank" sx={{ color: "white" }}>
                <Typography color={"white"} fontSize={17} fontFamily={"Roboto"}>
                  Enquiry
                </Typography>
              </Link>
            </li>
            <li>
              <Link to="/Car" target="_blank" sx={{ color: "white" }}>
                <Typography color={"white"} fontSize={17} fontFamily={"Roboto"}>
                  Careers
                </Typography>
              </Link>
            </li>
            <li>
              <Link to="/gallery" target="_blank" sx={{ color: "white" }}>
                <Typography
                  color={"white"}
                  fontSize={17}
                  fontFamily={"Poppins"}
                >
                  Gallery
                </Typography>
              </Link>
            </li>
            <li>
              <Link to="/contact" target="_blank" sx={{ color: "white" }}>
                <Typography
                  color={"white"}
                  fontSize={17}
                  fontFamily={"Poppins"}
                >
                  Contact
                </Typography>
              </Link>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Typography
            variant="h4"
            sx={{ mb: 2, fontFamily: "Roboto", color: "White" }}
          >
            Quick Links
          </Typography>
          <ul
            style={{
              listStyle: "none",
              padding: 0,
              fontFamily: "Roboto",
              fontSize: 17,
            }}
          >
            {/* Set color to white for all link texts */}

            {/* ... other quick links ... */}
            <li>
              <Link to="/mission" target="_blank" sx={{ color: "white" }}>
                {" "}
                <Typography color={"white"} fontSize={17} fontFamily={"Roboto"}>
                  Vision
                </Typography>
              </Link>
            </li>
            <li>
              <Link to="/mission" target="_blank" sx={{ color: "white" }}>
                {" "}
                <Typography color={"white"} fontSize={17} fontFamily={"Roboto"}>
                  Mission
                </Typography>
              </Link>
            </li>
            <li>
              <Link to="/Car" target="_blank" sx={{ color: "white" }}>
                <Typography color={"white"} fontSize={17} fontFamily={"Roboto"}>
                  Careers
                </Typography>
              </Link>
            </li>
            <li>
              <Link to="/event" target="_blank" sx={{ color: "white" }}>
                <Typography color={"white"} fontSize={17} fontFamily={"Roboto"}>
                  Events
                </Typography>
              </Link>
            </li>
            <li>
              <Link to="/Principal" target="_blank" sx={{ color: "white" }}>
                <Typography
                  color={"white"}
                  fontSize={17}
                  fontFamily={"Poppins"}
                >
                  Principal's Message
                </Typography>
              </Link>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography
            variant="h4"
            sx={{ mb: 2, color: "white", fontFamily: "Poppins" }}
          >
            Contact us
          </Typography>
          <ul
            style={{
              listStyle: "none",
              padding: 0,
              fontFamily: "Poppins",
              fontSize: 17,
            }}
          >
            {/* Set color to white for all link texts */}

            {/* ... other quick links ... */}
            <li>
              <Link to="#" sx={{ color: "black" }}>
                {" "}
                <Typography
                  color={"white"}
                  fontSize={17}
                  fontFamily={"Poppins"}
                >
                  <HomeIcon sx={{ fontSize: 30, color: "white" }} /> &nbsp;
                  TatiJhariya, Hazaribagh, Jharkhand 825313
                </Typography>
              </Link>
            </li>
            <li>
              <Link to="#" sx={{ color: "black" }}>
                {" "}
                <Typography
                  color={"white"}
                  fontSize={17}
                  fontFamily={"Poppins"}
                >
                  <EmailIcon sx={{ fontSize: 30 }} /> &nbsp;
                  davsanford777@gmail.com
                </Typography>
              </Link>
            </li>
            <li>
              <Link to="#" sx={{ color: "black" }}>
                <Typography
                  color={"white"}
                  fontSize={17}
                  fontFamily={"Poppins"}
                >
                  <CallIcon sx={{ color: "white", fontSize: 30 }} /> &nbsp;
                  (+91) - 8757-327-770
                </Typography>
              </Link>
            </li>

            <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
              <a
                href="https://www.facebook.com/davsanfordpublicschool"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: "block",
                  marginBottom: "10px",
                  color: "#3b5998",
                }}
              >
                <FacebookIcon sx={{ color: "white", fontSize: 40 }} />
              </a>

              <a
                href="https://www.instagram.com/davsanford777/"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: "block",
                  marginBottom: "10px",
                  color: "#3b5998",
                }}
              >
                <InstagramIcon sx={{ color: "lightBlue", fontSize: 40 }} />
              </a>
              <a
                href="https://www.youtube.com/@D.A.VSanford"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: "block",
                  marginBottom: "10px",
                  color: "#3b5998",
                }}
              >
                <YouTubeIcon sx={{ color: "red", fontSize: 40 }} />
              </a>
            </Stack>
          </ul>
        </Grid>

        {/* ... other columns ... */}
      </Grid>

      <hr style={{ border: "none", borderTop: "2px solid yellow" }} />

      <Box sx={{ mt: 3 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <Box>
              <Typography
                sx={{ color: "white", fontFamily: "Poppins", fontSize: 17 }}
              >
                Copyright 2023 D.A.V Sanford Public School All Rights Reserved
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography
              sx={{
                mb: 2,
                fontFamily: "Roboto",
                color: "White",
                fontSize: 17,
              }}
            >
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    marginLeft: "8px",
                  }}
                >
                  Hosted and designed by &nbsp;
                </span>
                <Box
                  sx={{
                    height: 40,
                    width: 40,
                    borderRadius: 10,
                  }}
                  component="img"
                  src={P5}
                  alt=""
                />
                <span
                  style={{
                    marginLeft: "8px",
                  }}
                >
                  <a
                    href="https://www.p5digital.in/index.html"
                    target="_blank"
                    style={{ fontFamily: "Poppins" }}
                  >
                    <Typography sx={{ fontSize: 16, color: "white" }}>
                      {" "}
                      P5 Digital Solutions
                    </Typography>
                  </a>
                </span>
              </span>
            </Typography>
          </Grid>

          {/* ... other columns ... */}
        </Grid>
      </Box>
    </Box>
  );
}

export default Footer;
