import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Button } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";

const HeroView = () => {
  const [image, setImage] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://lyci0i0cnf.execute-api.ap-south-1.amazonaws.com/sanford/sanford_poster_image"
      );
      setImage(response.data["body-json"]["body"]);
      console.log("dataofsandford", response.data["body-json"]["body"][0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const imageUrls = [
    "../wp-content/uploads/2023/05/gallery-5.jpg",
    "../wp-content/uploads/2023/05/DSC_6365-scaled.jpg",
    "../wp-content/uploads/2023/05/experimental-learning.jpg",
    // Add more image URLs as needed
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const carouselSettings = {
    autoPlay: true,
    autoPlaySpeed: 2000,
    infinite: true,
    transitionDuration: 500,
    removeArrowOnDeviceType: ["tablet", "mobile"],
  };

  return (
    <Carousel
      {...carouselSettings}
      animation="slide"
      indicators={false}
      responsive={responsive}
    >
      {image.map((imageUrl, index) => (
        <Box
          key={index}
          sx={{
            backgroundImage: `url(${imageUrl})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: "black",
            backgroundPosition: "center",
            backgroundSize: "cover",
            height: "100vh",
            width: "100%",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "40%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              backdropFilter: "blur(2px)",
              padding: "2rem",
            }}
          >
            <Typography
              variant={"h3"}
              color="white"
              align="center"
              boxShadow={"shadow-lg"}
            >
              Welcome to Our School
            </Typography>
            <Typography
              align="center"
              color="white"
              variant={"h6"}
              fontSize={20}
              sx={{ fontFamily: "serif" }}
              fontWeight="bold"
            >
              Discover the best in education.
            </Typography>
            <Button>
              {" "}
              <Link to="/admision">Apply Now</Link>
            </Button>
          </Box>
        </Box>
      ))}
    </Carousel>
  );
};

export default HeroView;
