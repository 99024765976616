import React, { useState } from "react";
import {
  Button,
  TextField,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  styled,
  Typography,
} from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DoubleArrowRoundedIcon from "@mui/icons-material/DoubleArrowRounded";
import HeroImg from "../../wp-content/uploads/2023/05/experimental-learning.jpg";

const CustomBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  backgroundColor: " #f3e5f5",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
}));

const ImgContainer = styled(Box)(({ theme }) => ({
  height: 500,
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const EnquireForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [enquiryFor, setEnquiryFor] = useState(""); // Default value is "teacher"

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      name: name,
      email: email,
      message: message,
      phone: phone,
      enquiryFor: enquiryFor, // Add enquiryFor field
    };

    try {
      const response = await axios.post(
        "https://lyci0i0cnf.execute-api.ap-south-1.amazonaws.com/sanford/sanford_enquiry",
        data
      );
      toast.success("Form submitted successfully!");
    } catch (error) {
      // Show error toast
      toast.error("Error submitting form. Please try again later.");
    }

    setName("");
    setEmail("");
    setNumber("");
    setMessage("");
    setEnquiryFor(""); // Reset enquiryFor to "teacher" after submission
  };

  return (
    <Box>
      <CustomBox>
        <CustomBox sx={{ flex: "1", marginTop: 5, padding: 5 }}>
          <Grid container direction="column" sx={{ height: "100%" }}>
            <Grid item xs={12} md={4}>
              <Box sx={{ p: 2 }}>
                <CustomBox className="flex  justify-center">
                  <DoubleArrowRoundedIcon
                    style={{
                      fontSize: "42px",
                      color: "orange",
                    }}
                  />
                  <Typography
                    variant="h4"
                    align="center"
                    mb={2}
                    color="Blue"
                    fontWeight={"bold"}
                  >
                    Enquire Now
                  </Typography>
                </CustomBox>
                <form onSubmit={handleSubmit}>
                  <Grid direction="column">
                    <Grid item xs={12} md={8}>
                      <Typography fontSize={15}>Name</Typography>
                      <TextField
                        fullWidth
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        InputProps={{
                          style: { fontSize: 15 }, // Font size of input
                        }}
                        margin="normal"
                        required
                      />
                      <Typography fontSize={15}> Email</Typography>
                      <TextField
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        InputProps={{
                          style: { fontSize: 15 }, // Font size of input
                        }}
                        margin="normal"
                        required
                        type="email"
                      />
                      <Typography fontSize={15}> Phone Number</Typography>
                      <TextField
                        fullWidth
                        value={phone}
                        onChange={(e) => setNumber(e.target.value)}
                        margin="normal"
                        InputProps={{
                          style: { fontSize: 15 }, // Font size of input
                        }}
                        required
                        type="number"
                      />
                      <FormControl fullWidth margin="normal" required>
                        <Typography fontSize={15}> Enquiry For</Typography>
                        <Select
                          value={enquiryFor}
                          onChange={(e) => setEnquiryFor(e.target.value)}
                          InputProps={{
                            style: { fontSize: 15 }, // Font size of input
                          }}
                        >
                          <MenuItem value="" sx={{ fontSize: 15 }}>
                            Select any one
                          </MenuItem>
                          <MenuItem value="Admission" sx={{ fontSize: 15 }}>
                            Admission
                          </MenuItem>
                          <MenuItem value="career" sx={{ fontSize: 15 }}>
                            Career
                          </MenuItem>
                        </Select>
                      </FormControl>
                      <Typography fontSize={15}> Message</Typography>
                      <TextField
                        fullWidth
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        InputProps={{
                          style: { fontSize: 15 }, // Font size of input
                        }}
                        margin="normal"
                        required
                        multiline
                        rows={4}
                      />
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{ fontSize: 15 }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
          </Grid>
        </CustomBox>
        <CustomBox>
          <ImgContainer>
            <img src={HeroImg} alt="heroImg" className="h-full" />
          </ImgContainer>
        </CustomBox>
      </CustomBox>
      <ToastContainer
        style={{ left: "50%", right: "auto", transform: "translateX(-50%)" }}
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={true}
      />
    </Box>
  );
};

export default EnquireForm;
